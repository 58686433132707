import { Component, OnDestroy, OnInit } from '@angular/core';
import { DatePipe } from '@angular/common';

import { AuthService, ConfigStateService } from '@abp/ng.core';

import { OAuthService } from 'angular-oauth2-oidc';

// Tagus Imports
import { ToggleService } from './toggle.service';
import { CustomizerSettingsService } from '../../tagus/customizer-settings/customizer-settings.service';

// For chat advise
import { ChatConfigService } from 'projects/chat/config/src/services/chat-config.service';
import { Subscription, filter } from 'rxjs';
import { NavigationEnd, Router } from '@angular/router';
import { WebNotificationSocketService } from '../../../services/web-notification-socker.service';
import { WebNotificationModel } from '../../../models/notifications/web-notification.model';
import { ToasterService } from '@abp/ng.theme.shared';
import { GetWebNotificationContentInput } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics';
import { WebNotificationContentsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { WebNotificationType } from '../../../models/notifications/web-notification-type.enum';
import { trigger, state, style, transition, animate } from '@angular/animations';
import type { NotesDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics/models';
import { NotesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { removeHtmlText } from '../../../utils/control-utils';

const now = new Date();
const today = new Date(now.getFullYear(), now.getMonth(), now.getDate()).valueOf();

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  animations: [
    trigger('fadeInAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [animate(200), style({ opacity: 1 })]),
      transition(':leave', [animate(300, style({ opacity: 0 }))]),
    ]),
  ],
})
export class HeaderComponent implements OnInit, OnDestroy {
  isToggled = false;
  currentDate: Date = new Date();
  formattedDate: any;
  currentUser: any;

  notifications: Array<WebNotificationModel> = [];
  notificationFilter = <GetWebNotificationContentInput>{};

  notes: Array<NotesDto> = [];

  private subscription = new Subscription();

  private readonly missionDetailPath = '/pilot-sourcing/missions/';
  private readonly chatPath = '/chat';
  private readonly maxChatLength = 20;

  private currentUserId = this.stateService.getDeep('currentUser.id');

  constructor(
    private toggleService: ToggleService,
    private datePipe: DatePipe,
    public themeService: CustomizerSettingsService,
    private config: ConfigStateService,
    private authService: AuthService,
    private oAuthService: OAuthService,
    private stateService: ConfigStateService,
    public chatConfigService: ChatConfigService,
    public router: Router,
    public webNotificationService: WebNotificationSocketService,
    private notificationService: WebNotificationContentsService,
    private toaster: ToasterService,
    public readonly notesService: NotesService,
    private notificationBroadcastService: NotificationBroadcastService,
  ) {
    this.toggleService.isToggled$.subscribe(isToggled => {
      this.isToggled = isToggled;
    });

    this.currentUser = this.config.getOne('currentUser');

    this.formattedDate = this.datePipe.transform(this.currentDate, 'dd MMMM yyyy');

    this.listenToMessages();
    this.listenToRouterEvents();
  }

  public ngOnInit(): void {
    this.subscription.add(
      this.notificationService.notificationDismissed$.subscribe(dismissed => {
        let indexToRemove = this.notifications.findIndex(
          x => x.arguments.webNotificationId == dismissed.id,
        );
        this.notifications.splice(indexToRemove, 1);
      }),
    );

    if (!this.isLogged()) return;

    this.notificationFilter.userId = this.config.getDeep('currentUser.userName');
    this.notificationFilter.excludeDismissed = true;

    this.loadNotificationData();
    this.loadNoteData();

    this.webNotificationService.startConnection();
    this.subscription.add(
      this.webNotificationService.notification$.subscribe((notification: WebNotificationModel) => {
        if (notification.arguments.type == WebNotificationType.ForNotificationList) {
          if (notification.arguments.typeToast == 'Error') {
            this.toaster.error(notification.message, notification.arguments.title, {
              action: this.generateNotificationAction(notification),
            } as any);
          } else {
            this.toaster.info(notification.message, notification.arguments.title, {
              action: this.generateNotificationAction(notification),
            } as any);
          }
          this.notifications.unshift(notification);
          this.notificationService.refreshNotifications$.next();
          return;
        }

        if (notification.arguments.type == WebNotificationType.ToastNotification) {
          if (notification.arguments.typeToast == 'Error') {
            this.toaster.error(notification.message, notification.arguments.title, {
              action: this.generateNotificationAction(notification),
            } as any);
          } else {
            this.toaster.info(notification.message, notification.arguments.title, {
              action: this.generateNotificationAction(notification),
            } as any);
          }
          return;
        }
      }),
    );

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (
          notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalNoteAddedToMission
        ) {
          this.loadNoteData();
        }
      }),
    );
  }

  public handleOnRemoveNotification(index: number, event: MouseEvent): void {
    this.notificationService
      .dismissWebNotification(
        this.notificationFilter.userId,
        this.notifications[index].arguments.webNotificationId,
      )
      .subscribe(_ => {
        this.notifications.splice(index, 1);
      });

    event.stopPropagation();
  }

  public handleOnOpenNotifications(): void {
    this.router.navigate(['notifications']);
  }

  /**
   * Generates a callback to be used by tapping a notification
   * @param notification WebNotificationModel
   * @returns () => Promise<Boolean>
   */
  private generateNotificationAction(notification: WebNotificationModel): () => Promise<Boolean> {
    if (!notification.arguments.missionId) {
      return null;
    }

    return () => this.router.navigate([this.generateMissionDetailPath(notification)]);
  }

  /**
   * Generates a valid redirect path for a notification
   * @param notification WebNotificationModel
   * @returns string
   */
  generateMissionDetailPath(notification: WebNotificationModel): string {
    return `${this.missionDetailPath}${notification.arguments.missionId}`;
  }

  generateMissionDetailPathNote(note: NotesDto): string {
    return `${this.missionDetailPath}${note.missionId}`;
  }

  private listenToMessages() {
    this.subscription.add(
      this.chatConfigService.message$
        .pipe(filter(() => this.router.url !== '/chat'))
        .subscribe(message => {
          const { senderName, senderSurname, senderUsername, text } = message;
          const sender = `${
            senderName ? senderName + (senderSurname ? ' ' + senderSurname : '') : senderUsername
          }`;
          this.chatConfigService.unreadMessagesCount++;

          const splittedText = text.split(/\s+/);
          const endOfLineString = splittedText.length > this.maxChatLength ? '...' : '';
          const clampedText = splittedText.slice(0, this.maxChatLength).join(' ') + endOfLineString;

          this.toaster.info(clampedText, `New Message from ${sender}`, {
            action: () => this.router.navigate([this.chatPath]),
            life: 6000,
          } as any);
        }),
    );
  }

  private listenToRouterEvents() {
    this.subscription.add(
      this.router.events
        .pipe(filter(event => event instanceof NavigationEnd && event.url === '/chat'))
        .subscribe(() => (this.chatConfigService.unreadMessagesCount = 0)),
    );
  }

  isLogged(): boolean {
    return this.authService.isAuthenticated;
  }

  async logout() {
    this.oAuthService.logOut();

    this.clearGlobalFilterSettings();
    window.localStorage.setItem('logout-event', Math.random().toString());
  }

  toggleTheme() {
    this.themeService.toggleTheme();
  }

  toggle() {
    this.toggleService.toggle();
  }

  toggleSidebarTheme() {
    this.themeService.toggleSidebarTheme();
  }

  toggleHideSidebarTheme() {
    this.themeService.toggleHideSidebarTheme();
  }

  toggleCardBorderTheme() {
    this.themeService.toggleCardBorderTheme();
  }

  toggleHeaderTheme() {
    this.themeService.toggleHeaderTheme();
  }

  toggleCardBorderRadiusTheme() {
    this.themeService.toggleCardBorderRadiusTheme();
  }

  toggleRTLEnabledTheme() {
    this.themeService.toggleRTLEnabledTheme();
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  private loadNotificationData(): void {
    this.notificationService.getUserNotifications(this.notificationFilter).subscribe(res => {
      this.notifications = res.items.map(
        x =>
          <WebNotificationModel>{
            message: x.message,
            arguments: {
              date: x.date,
              notificationId: x.notificationId,
              webNotificationId: x.id,
              missionId: x.missionId,
            },
          },
      );
    });
  }

  private loadNoteData(): void {
    this.notesService.getAllowed().subscribe(res => {
      this.notes = res.map(r => {
        if (r.body?.trim() == '-') r.body = r.subject;

        r.body = removeHtmlText(r.body);

        return r;
      });
    });
  }

  private clearGlobalFilterSettings() {
    localStorage.removeItem(`filterSettings_${this.currentUserId}`);
  }

  getDateInLocalTime(date: string | Date): Date {
    if (date instanceof Date) {
      return date;
    }

    let newDate: Date = new Date(date + 'Z');
    return newDate;
  }

  getDateFormat(date: string | Date): string {
    date = new Date(date);
    const messageDay = new Date(date.getFullYear(), date.getMonth(), date.getDate()).valueOf();

    if (messageDay === today) return 'shortTime';

    return 'short';
  }
}
