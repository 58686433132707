import {
  ABP,
  ConfigStateService,
  LIST_QUERY_DEBOUNCE_TIME,
  ListService,
  LocalizationService,
  PagedResultDto,
  PermissionService,
  TrackByService,
} from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { debounceTime, switchMap } from 'rxjs/operators';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, firstValueFrom, forkJoin, Observable, Subscription } from 'rxjs';
import {
  ActionOrderConfiguredDto,
  ConfigurationAttributeTypesDto,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetMissionStatusRolesInput,
  GetPriorityInput,
  GetStatusesInput,
  MissionStatusRoleDto,
  PrioritiesDto,
  StatusDto,
  MissionStatusRoleLookup,
  CategoriesDto,
  DepartamentsDto,
  SLADto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  ActionOrderService,
  CategoriesService,
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  DepartamentsService,
  MissionStatusRoleService,
  PrioritiesService,
  StatusService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';

import {
  AssignedDto,
  Control,
  GridViewAssignationDto,
  GridViewsCreateDto,
  GridViewsDto,
  MissionsDto,
  NavigateToMissionDetailsDto,
  ProjectsDto,
} from '../../../proxy/missions-service/basics/models';
import { MissionsService } from '../../../proxy/missions-service/controllers/basics/missions.service';
import {
  enumState,
  EnumStateOptions,
} from '../../../proxy/missions-service/shared/enum-state.enum';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { missionStatusOptions } from '../../../proxy/missions-service/shared/mission-status.enum';
import { ColumnStatus } from 'projects/flyguys/src/app/components/columns/components/column-displayer/column-displayer.component';
import { stateRules } from 'projects/flyguys/src/app/components/columns/rules/lookup-rules';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { OAuthService } from 'angular-oauth2-oidc';

import { enumMissionStatusMapping } from '../../../proxy/missions-service/basics/enum-mission-status-mapping.enum';
import { MatMenuTrigger } from '@angular/material/menu';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';

import { NotificationBroadcastService } from 'projects/flyguys/src/app/services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { ColumnColoringRules } from 'projects/flyguys/src/app/components/columns/components/column-stylizer/column-stylizer.component';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';

import { MissionsGridFilters } from './models/missions-grid-filters';
import { MissionsFilterColumns } from './models/missions-filter-columns';
import { PaginationSortingAndGlobalSearch } from '../../../../../../flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { StatusAndAssignedPermissions } from '../../../../../../flyguys/src/app/shared/grid-filters/models/status-and-assigned-permissions';
import { FilterConfig } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { FilterCondition } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-type.enum';
import { PilotSuccessDto } from '../../../proxy/missions-service/relationals';
import { MissionAssignmentsModalComponent } from '../../mission-assignments-modal/mission-assignments-modal.component';
import { MatDialog } from '@angular/material/dialog';
import { MissionsGridSettingsForLocalStorage } from './models/mission-grid-settings-for-local-storage';
import { format } from 'date-fns';
import { migratedRules } from 'projects/flyguys/src/app/components/columns/rules/lookup-rules';

import {
  assignedFilterEnum,
  assignedFilterDisplayNames,
} from '../../../proxy/missions-service/shared/assignedFilter.enum';
import { StatusMissionOrderService } from '../../../proxy/missions-service/controllers/basics/status-mission-order.service';
import { OrderFormContactModel } from '../../../../../../flyguys/src/app/components/orders/model/order-form-contact.model';
import { CopyMissionModalComponent } from './copy-mission-modal/copy-mission-modal.component';
import { CopyMissionForm } from './models/copy-mission-form';
import {
  TimeframeFilterEnum,
  TimeframeFilterEnumDisplayNames,
} from '../../../proxy/missions-service/shared/timeframes-filter.enum';
import { CustomViewModalComponent } from './custom-view-modal/custom-view-modal.component';
import { MissionsViewGridFilterModel } from './models/missions-view-grid-filter.model';
import { CustomViewDataModel } from './models/custom-view-data.model';
import { GridViewsService } from '../../../proxy/missions-service/controllers/basics';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SelectionType } from '@swimlane/ngx-datatable';
import { ShowSlaMilestonesComponent } from './show-sla-milestones/show-sla-milestones.component';
import { MissionStatus } from 'projects/flyguys/src/app/pilot-sourcing/models/mission-status.enum';
import { NgxDatatableColumnStatePersistance } from 'projects/flyguys/src/app/directives/ngx-datatable-column-state-persistance/ngx-datatable-column-state-persistance.directive';
import { SlaService } from '../../../proxy/missions-service/controllers/basics/sla.servicet';
import { RefreshOrderCreatedService } from 'projects/flyguys/src/app/components/orders/services/refresh-order-created.service';
import { AssignPilotForm } from './models/assign-pilot-form';
import { AssignPilotModalComponent } from './assign-pilot-modal/assign-pilot-modal.component';
import { LocationForm } from 'projects/flyguys/src/app/pilot-sourcing/components/order-request/models/location-form.model';
@Component({
  selector: 'lib-missions',
  providers: [
    ListService,
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: LIST_QUERY_DEBOUNCE_TIME, useValue: 600 },
  ],
  templateUrl: './missions.component.html',
  styleUrls: ['./missions.component.scss'],
})
export class MissionsComponent implements OnInit, OnDestroy {
  /**
   * DECLARE HERE VALID ACTIONS IN THE ORDER GRID.
   */
  private readonly ALLOWED_ORDER_GRID_ACTIONS = [
    'accept_cancellation',
    'accept_mission',
    'add_note',
    'assign_MC',
    'assign_qaqc',
    'assign_sales',
    'cancel_mission',
    'check_in',
    'check_in_mc',
    'complete_upload',
    'confirm_flight',
    'confirm_mission',
    'data_upload_complete',
    'edit_mission',
    'logistic_coordination',
    'mark_as_dead',
    'mark_mission_as_flown',
    'mc_resource',
    'missionCoordinator_awaitingFlight',
    'pause_mission',
    'processing_complete',
    'QA_QC_Complete',
    'rate_experience',
    'ready_for_sourcing',
    'reassign_mc',
    'reassign_pilot_success',
    'reassign_qaqc',
    'reject_mission',
    'request_data',
    'request_data_qaqc',
    'request_deliverables',
    'request_reassignment',
    'request_refly',
    'reschedule_mission',
    'reschedule_mission_success',
    'resource_pilot',
    'resubmit_mission',
    'resume_mission',
    'source_pilot',
    'submit_mc_pilot_rating',
    'submit_qaqc_rating',
    'submit_to_qaqc',
    'update_pilot_rating',
    'upload_data',
    'client_invoiced',
    'mc-review-to-logistic-coordination',
    'recalculate_sla',
    'airspace_requested',
    'airspace_denied',
    'airspace_approved',
    'faa_approve',
  ];

  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';

  // action code for the first secondary icon
  private readonly FIRST_SECONDARY_ACTION_CODE = 'add_note';
  private readonly ACTION_COLUMN_NAME = 'Actions';
  private readonly ASSIGNED_COLUMN_NAME = 'Team';
  private readonly DAYS_TO_DELIVERY = 'daysToDelivery';
  private readonly SELECT_COLUMN_NAME = 'Click here to Select/Deselect All';
  private readonly PILOT_SOURCING_RESULTS_COLUMN_PROP = 'pilotSourcingResults';

  // order managed as primary action
  private readonly PRIMARY_ACTION_ORDER = 1;

  private readonly PILOT_SOURCING_PATH = 'pilot-sourcing/missions/';

  currentUserId: string;

  subscription = new Subscription();

  data: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  projects: PagedResultDto<ProjectsDto> = {
    items: [],
    totalCount: 0,
  };

  priorities: PagedResultDto<SLADto> = {
    items: [],
    totalCount: 0,
  };

  lstAssigned: PagedResultDto<AssignedDto> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  isFiltersHidden = true;

  isModalBusy = false;
  isModalOpen = false;

  isModalOpenAssigned = false;

  isExportToExcelBusy = false;

  states = EnumStateOptions;

  public readonly missionStatus = missionStatusOptions;

  values = [];

  controls: Control<string>[] = [];

  migratedRules = migratedRules;

  @Input() export: Boolean = false;

  clientMaxResultCount = 10;
  valuesMaxResultCount = [];
  pageNumber = 0;
  action: any;
  MaxResultCount = 100;

  qaqcUsers: PagedResultDto<PilotSuccessDto> = {
    items: [],
    totalCount: 0,
  };

  showedColumns = {
    actions: true,
    name: true,
    summary: true,
    customer: true,
    priority: true,
    location: true,
    startData: true,
    state: true,
    project: true,
    additionalNotes: true,
    missionStatus: true,
    pilotSourcingMode: true,
    qaqcMode: true,
    invoiceMode: true,
    cost: true,
    portafolio: true,
    activeCaptureDate: true,
    pilotName: true,
    assignedTo: true,
    assigned: true,
    creationTime: true,
    jobId: true,
    deliverableDueDate: true,
    daysToDelivery: true,
    nextMilestoneDue: true,
    pilotSourcingResults: true,
  };

  stateRules = stateRules;
  prioritiesRules: ColumnColoringRules[] = [];
  reloadingNotes: boolean = false;

  currentToken: string;

  currentUserRoles: Array<string>;
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentStatuses: Array<StatusDto>;

  public readonly COPY_MISSION = 'copy_mission';
  public readonly ADD_PILOT = 'add_pilot';
  isAssignPilotBulk = false;

  users: any;
  roles: any;
  currentUser: any;

  emptyGuid: string = '00000000-0000-0000-0000-000000000000';

  // Default paging values
  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: 10,
  };

  // Temp UAT Meassure to handle the ... menu properly
  @ViewChild('actionsMenu') actionsMenu: MatMenuTrigger;

  @ViewChild(NgxDatatableColumnStatePersistance)
  columnPersistance: NgxDatatableColumnStatePersistance;

  lstAssignedFilter = Object.keys(assignedFilterEnum)
    .filter(key => typeof assignedFilterEnum[key] === 'number')
    .map(key => ({
      value: assignedFilterEnum[key],
      description: assignedFilterDisplayNames[assignedFilterEnum[key]],
    }));

  selectedAssignedFilter: number = 0;
  selectedActions: ActionOrderConfiguredDto[] = new Array();
  firstRow: MissionsDto;
  selectedMissions: MissionsDto[] = [];
  loadingCommonActions: boolean = false;
  changingPageMaxResoultCount: boolean = false;
  canExecuteBulkActions: boolean = false;
  selectAll: boolean = false;
  SelectionType = SelectionType;

  dataTimeframesFilter = Object.keys(TimeframeFilterEnum)
    .filter(key => typeof TimeframeFilterEnum[key] === 'number')
    .map(key => ({
      id: TimeframeFilterEnum[key],
      description: this.localizationService.instant(
        TimeframeFilterEnumDisplayNames[TimeframeFilterEnum[key]],
      ),
    }));

  @Output() onOpenMission = new EventEmitter<NavigateToMissionDetailsDto>();

  existingViews: Array<GridViewsDto>;
  viewSelected: GridViewsDto;
  viewConfigSelected: MissionsViewGridFilterModel;
  private readonly loadingDelay: number = 3000;
  isAdminUser: boolean;
  predefinedView: GridViewAssignationDto;

  categories: CategoriesDto[];
  departments: DepartamentsDto[];

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionsService,
    public readonly customerService: CustomersService,
    public readonly pilotService: PilotService,
    public readonly priorityService: PrioritiesService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    private oAuthService: OAuthService,
    private stateService: ConfigStateService,
    private readonly actionOrderService: ActionOrderService,
    private readonly statusService: StatusService,
    private cdRef: ChangeDetectorRef,
    private usersService: IdentityUserService,
    private spinner: NgxSpinnerService,
    private notificationBroadcastService: NotificationBroadcastService,
    private permissionService: PermissionService,
    private dialogService: MatDialog,
    private readonly missionStatusRoleService: MissionStatusRoleService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
    public readonly localizationService: LocalizationService,
    private categoryService: CategoriesService,
    private departamentService: DepartamentsService,
    public readonly gridViewsService: GridViewsService,
    private snackBar: MatSnackBar,
    private slaService: SlaService,
    private refreshOrderCreatedService: RefreshOrderCreatedService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  setAction(act: any) {
    this.action = act;
  }

  checkCommonActions() {
    this.loadingCommonActions = true;

    setTimeout(() => {
      if (this.selectedMissions.length > 1) {
        this.firstRow = this.selectedMissions[0];
        this.selectedActions = this.findCommonActions(this.selectedMissions);
      } else {
        this.firstRow = null;
        this.selectedActions = new Array();
      }

      setTimeout(() => {
        this.loadingCommonActions = false;
      }, 500);
    }, 500);
  }

  isMissionSelected(row: MissionsDto): boolean {
    return this.selectedMissions.some(mission => mission.id === row.id);
  }

  reloadNotes(action: string) {
    if (action === 'add_note') {
      this.reloadingNotes = true;
      this.cdRef.detectChanges();
      this.reloadingNotes = false;
      this.cdRef.detectChanges();
    }

    this.ifBulkActionWasExecutedUncheckMissions();
  }

  ifBulkActionWasExecutedUncheckMissions() {
    if (this.selectedMissions?.length > 0) {
      this.firstRow = null;
      this.selectedActions = new Array();
      this.selectedMissions = new Array();
      this.selectAll = false;
    }
  }

  ngOnInit() {
    this.spinner.show();
    this.canExecuteBulkActions = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.ExecuteBulkActions',
    );
    this.currentUser = this.stateService.getDeep('currentUser');

    this.currentToken = this.oAuthService.getAccessToken();
    this.currentUserId = this.stateService.getDeep('currentUser.id');

    const currentRoles = this.stateService.getDeep('currentUser.roles') as string[];
    this.isAdminUser = currentRoles.includes('admin');

    this.service
      .getUsersByRoleNames('qa qc agent,qa qc supervisor')
      .subscribe(response => (this.qaqcUsers = response));
    combineLatest([
      this.getPageAttributeValues(),
      this.getMaxCountAttribute(),
      this.getSLAOptions(),
      this.getStatuses(),
      this.getMissionStatusesRole(),
      this.getActionConfiguration(),
      this.getMissionStatusRoleConfiguration(),
      this.getViewList(this.currentUserId, this.isAdminUser),
      this.loadPredefinedView(),
      this.categoryService.getAll(),
      this.departamentService.getAll(),
    ]).subscribe({
      next: ([
        pageAttributes,
        maxCountAttribute,
        priorityValues,
        statuses,
        roleStatuses,
        actionConfiguration,
        missionStatusRole,
        viewList,
        predefinedView,
        categories,
        departments,
      ]) => {
        this.setPageAttributes(pageAttributes);
        this.setMaxCountAttribute(maxCountAttribute);
        this.setPriorityValues(priorityValues);

        this.currentStatuses = statuses.items;
        this.currentActionConfiguration = actionConfiguration;

        this.categories = categories;
        this.departments = departments;

        this.updatePriorityOptions(priorityValues.items);
        this.updateMissionStatusOptions();
        this.updateTimeframeOptions(this.dataTimeframesFilter);

        this.missionGridFilters.MissionStatusRoles = missionStatusRole;

        this.existingViews = viewList;

        let sView = this.existingViews?.find(x => x.id == predefinedView?.gridViewId);

        if (sView) {
          this.predefinedView = predefinedView;

          this.selectedAssignedFilter = <any>sView.id;

          this.handleOnViewSelected(sView.id);
        } else {
          // Get missions based on stored filters
          this.applyStoredFilterSettings();
        }

        this.spinner.hide();
      },
      error: err => {
        console.error(`Mission List, error while fetching data: ${err}`);
        this.spinner.hide();
      },
    });

    this.usersService.getList({ maxResultCount: 1000 }).subscribe(r => {
      this.users = r.items?.map(q => {
        return { id: q.id, value: `${q.name || ''} ${q.surname || ''}` };
      });
    });

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (
          notif.notificationKey ==
          enumWebBackgroundNotificationKey.EventGlobalForMissionAssignationUpdated
        ) {
          if (
            notif.itemId &&
            notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForRow
          ) {
            let mission = this.data.items.find(x => x.id == notif.itemId);

            if (!mission) return;

            if (notif.extraArgument.userAssigned) {
              mission.assignedTo = notif.extraArgument.userAssigned;
              mission.assigned = notif.extraArgument.assignedList;
            } else if (notif.extraArgument.salesUser) {
              mission.salesName = notif.extraArgument.salesUser;
            } else if (notif.extraArgument?.pilotSourcingResult) {
              mission.pilotRejected = notif.extraArgument?.pilotSourcingResult.rejected;
              mission.pilotAccepted = notif.extraArgument?.pilotSourcingResult.accepted;
              mission.pilotRequested = notif.extraArgument?.pilotSourcingResult.requested;
              this.cdRef.detectChanges();
            }
          }
        }

        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            notif.extraArgument.missionStatus &&
            notif.extraArgument.missionStatusCode
          ) {
            let mission = this.data.items.find(x => x.id == notif.itemId);
            if (mission) {
              // 19756: Prevent invalid transition from status QAQC back to Awaiting Assignment
              if (
                mission.statusCode === MissionStatus.InQAQC &&
                notif.extraArgument.missionStatusCode === MissionStatus.AwaitingQAQCAssignment
              ) {
                console.error('Prevented invalid status update from 10 to 21');
                return;
              }

              this.ifBulkActionWasExecutedUncheckMissions();

              mission.missionStatus = notif.extraArgument.missionStatus;
              mission.statusCode = notif.extraArgument.missionStatusCode;
              mission.statusId = this.currentStatuses.find(s => s.code == mission.statusCode).id;

              this.configureActionInMission(mission);

              this.NotifyMissionDateChanges(
                mission,
                notif.extraArgument.deliverableDueDate,
                notif.extraArgument.fixedDeliverableDueDate,
                notif.extraArgument.slaDueDate,
              );

              //After SLA is calculated
              setTimeout(() => {
                this.slaService.getSLAInformation(notif.itemId).subscribe(r => {
                  this.NotifyMissionDateChanges(
                    mission,
                    r.deliverableDueDate,
                    r.fixedDeliverableDueDate,
                    r.slaDueDate,
                  );
                });
              }, 3000);
            }
          }
        }

        if (
          notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalNoteAddedToMission
        ) {
          if (
            notif.itemId &&
            notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForNoteList
          ) {
            let mission = this.data.items.find(x => x.id == notif.itemId);
            if (mission) {
              this.refreshNoteList(mission);
            }
          }
        }
      }),
    );

    this.usersService.getAssignableRoles().subscribe({
      next: response => {
        this.roles = response;
      },
      error: err => {
        console.log(err);
      },
    });

    this.refreshOrderCreatedService.refreshOrderCreated$.subscribe(() => {
      this.callApiWithFilters();
    });

    this.refreshOrderCreatedService.newChangeLocation$.subscribe(locationForm => {
      this.updateGridDataFromNewChangeOrderRequest(locationForm);
    });

    this.refreshOrderCreatedService.newSlaMilestones$.subscribe(milestones => {
      this.updateGridDataFromNewMilestones(milestones);
    });
    this.refreshOrderCreatedService.newSlaProfile$.subscribe(sla => {
      this.updateNewSla(sla);
    });
  }

  NotifyMissionDateChanges(
    mission: MissionsDto,
    deliverableDueDate: string,
    fixedDeliverableDueDate: string,
    slaDueDate: string,
  ) {
    if (deliverableDueDate) mission.deliverableDueDate = deliverableDueDate;

    if (fixedDeliverableDueDate) mission.fixedDeliverableDueDate = fixedDeliverableDueDate;

    if (slaDueDate) mission.slaDueDate = slaDueDate;

    mission.cssClass = this.defineClassRow(mission);

    this.cdRef.detectChanges();
  }

  loadPredefinedView(): Observable<GridViewAssignationDto> {
    return this.gridViewsService.getAssignation(this.currentUser.id);
  }

  handleOnHover(view: GridViewsDto, hover: boolean, event: any) {
    view.displayStar = hover;
  }

  getDisplayValue(item: any) {
    if (this.viewSelected) {
      return this.viewSelected.name;
    }

    let selected = this.lstAssignedFilter.find(z => z.value == item);

    return selected?.description || '-';
  }

  handleOnAssignView(event: MouseEvent, viewId: string) {
    event.stopPropagation();
    event.preventDefault();

    let assignationDto: GridViewAssignationDto = {
      userId: this.currentUser.id,
      gridViewId: viewId,
    };

    this.gridViewsService.assignToUser(assignationDto).subscribe({
      next: res => {
        this.predefinedView = res;
        this.snackBar.open('View saved as default.', 'Close', {
          duration: this.loadingDelay,
        });
      },
      error: err => console.error(err),
    });
  }

  handleOnUnassignView(event: MouseEvent, viewId: string) {
    event.stopPropagation();
    event.preventDefault();

    this.gridViewsService.removeAssignation(this.currentUser.id).subscribe({
      next: res => {
        this.predefinedView = undefined;
        this.snackBar.open('Default view removed.', 'Close', {
          duration: this.loadingDelay,
        });
      },
      error: err => console.error(err),
    });
  }

  handleOnCreateCustomView(): void {
    const currentFilters: MissionsViewGridFilterModel = {
      priorityId: this.missionGridFilters.priorityId ? [...this.missionGridFilters.priorityId] : [],
      missionName: this.missionGridFilters.missionName
        ? [...this.missionGridFilters.missionName]
        : [],
      customerName: this.missionGridFilters.customerName
        ? [...this.missionGridFilters.customerName]
        : [],
      projectName: this.missionGridFilters.projectName
        ? [...this.missionGridFilters.projectName]
        : [],
      locationName: this.missionGridFilters.locationName
        ? [...this.missionGridFilters.locationName]
        : [],
      captureDate: this.missionGridFilters.captureDate
        ? [...this.missionGridFilters.captureDate]
        : [],
      pilotName: this.missionGridFilters.pilotName ? [...this.missionGridFilters.pilotName] : [],
      missionStatusId: this.missionGridFilters.missionStatusId
        ? [...this.missionGridFilters.missionStatusId]
        : [],
      noMissionStatusId: this.missionGridFilters.noMissionStatusId
        ? [...this.missionGridFilters.noMissionStatusId]
        : [],
      jobId: this.missionGridFilters.jobId ? [...this.missionGridFilters.jobId] : [],
      creationDate: this.missionGridFilters.creationDate
        ? [...this.missionGridFilters.creationDate]
        : [],
      deliverableDueDate: this.missionGridFilters.deliverableDueDate
        ? [...this.missionGridFilters.deliverableDueDate]
        : [],
      nextMilestoneDue: this.missionGridFilters.nextMilestoneDue
        ? [...this.missionGridFilters.nextMilestoneDue]
        : [],
      daysToDelivery: this.missionGridFilters.daysToDelivery
        ? [...this.missionGridFilters.daysToDelivery]
        : [],
      sorting: this.paginationSortingAndGlobalSearch.sorting,
      maxResultCount: this.paginationSortingAndGlobalSearch.maxResultCount,
      filter: this.paginationSortingAndGlobalSearch.filter,
      statusAndAssignedPermissions: this.statusAndAssignedPermissions,
      manualAirspaceWaiverRequired: this.missionGridFilters.manualAirspaceWaiverRequired
        ? [...this.missionGridFilters.manualAirspaceWaiverRequired]
        : [],
    };

    const modalData: CustomViewDataModel = {
      data: currentFilters,
      isEdition: false,
    };

    const dialogRef = this.dialogService.open(CustomViewModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: modalData,
    });

    dialogRef.afterClosed().subscribe((result: MissionsViewGridFilterModel) => {
      if (!result) return;

      let viewDefinition = { ...result, ['viewName']: undefined, ['privateView']: undefined };

      let creationViewModel: GridViewsCreateDto = {
        name: result.viewName,
        private: !this.isAdminUser || !!result.privateView,
        viewDefinition: JSON.stringify(viewDefinition),
        userId: this.currentUserId,
      };

      this.gridViewsService.create(creationViewModel).subscribe({
        next: (res: GridViewsDto) => {
          this.existingViews.push(res);

          this.snackBar.open('New view added.', 'Close', {
            duration: this.loadingDelay,
          });
        },
        error: err => console.error('error saving view', err),
      });
    });
  }

  handleOnViewSelected(id: string) {
    let completeView = this.existingViews.find(x => x.id == id);

    if (!completeView) return;

    this.viewSelected = completeView;

    let filtersObj: MissionsViewGridFilterModel = JSON.parse(completeView.viewDefinition);

    this.missionGridFilters.priorityId = filtersObj.priorityId ? [...filtersObj.priorityId] : [];
    this.missionGridFilters.missionName = filtersObj.missionName ? [...filtersObj.missionName] : [];
    this.missionGridFilters.customerName = filtersObj.customerName
      ? [...filtersObj.customerName]
      : [];
    this.missionGridFilters.projectName = filtersObj.projectName ? [...filtersObj.projectName] : [];
    this.missionGridFilters.locationName = filtersObj.locationName
      ? [...filtersObj.locationName]
      : [];
    this.missionGridFilters.captureDate = filtersObj.captureDate ? [...filtersObj.captureDate] : [];
    this.missionGridFilters.pilotName = filtersObj.pilotName ? [...filtersObj.pilotName] : [];
    this.missionGridFilters.missionStatusId = filtersObj.missionStatusId
      ? [...filtersObj.missionStatusId]
      : [];
    this.missionGridFilters.noMissionStatusId = filtersObj.noMissionStatusId
      ? [...filtersObj.noMissionStatusId]
      : [];
    this.missionGridFilters.manualAirspaceWaiverRequired = filtersObj.manualAirspaceWaiverRequired
      ? [...filtersObj.manualAirspaceWaiverRequired]
      : [];
    this.missionGridFilters.jobId = filtersObj.jobId ? [...filtersObj.jobId] : [];
    this.missionGridFilters.creationDate = filtersObj.creationDate
      ? [...filtersObj.creationDate]
      : [];
    this.missionGridFilters.deliverableDueDate = filtersObj.deliverableDueDate
      ? [...filtersObj.deliverableDueDate]
      : [];
    this.missionGridFilters.nextMilestoneDue = filtersObj.nextMilestoneDue
      ? [...filtersObj.nextMilestoneDue]
      : [];
    this.missionGridFilters.daysToDelivery = filtersObj.daysToDelivery
      ? [...filtersObj.daysToDelivery]
      : [];
    this.missionGridFilters.viewSelected = [id];

    this.paginationSortingAndGlobalSearch.maxResultCount = filtersObj.maxResultCount;
    this.paginationSortingAndGlobalSearch.filter = filtersObj.filter;

    this.viewConfigSelected = filtersObj;

    this.applyViewFilters(filtersObj);

    if (!filtersObj.statusAndAssignedPermissions) {
      this.handleAssignedSelect(assignedFilterEnum.All);

      return;
    }

    this.statusAndAssignedPermissions = { ...filtersObj.statusAndAssignedPermissions };

    this.handleFilterFromView();
  }

  showNavigateToCaptures(row: MissionsDto) {
    return (
      row.statusCode === MissionStatus.PilotSourcing ||
      row.statusCode === MissionStatus.AwaitingMissionCoordinatorAssignment
    );
  }

  allowedByView(column: string): boolean {
    if (!this.viewSelected) return true;

    return this.viewConfigSelected?.columns?.includes(column);
  }

  isSourcingResultsVisible(): boolean {
    return (
      !!this.viewSelected ||
      (this.selectedAssignedFilter != assignedFilterEnum.MyMission &&
        this.selectedAssignedFilter != assignedFilterEnum.All)
    );
  }

  private applyViewFilters(viewFilters: MissionsViewGridFilterModel) {
    for (let condition of this.filterConfig.conditions) {
      condition.existingValues = viewFilters[condition.column] || [];
    }

    this.form.get('searchInput')?.setValue(viewFilters.filter);
  }

  private getViewList(userId: string, isAdmin: boolean): Observable<Array<GridViewsDto>> {
    return this.gridViewsService.getAll(userId, isAdmin);
  }

  private getActionConfiguration(): Observable<ActionOrderConfiguredDto[]> {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    return this.actionOrderService.filterByRoles(this.currentUserRoles);
  }

  private getMissionStatusRoleConfiguration(): Observable<MissionStatusRoleLookup[]> {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    return this.missionStatusRoleService.filterByRoles(this.currentUserRoles);
  }

  private getStatuses(): Observable<PagedResultDto<StatusDto>> {
    return this.statusService.getList(<GetStatusesInput>{});
  }

  private getMissionStatusesRole(): Observable<PagedResultDto<MissionStatusRoleDto>> {
    const currentRoles = this.stateService.getDeep('currentUser.roles');
    const currentRole = currentRoles.length > 0 ? currentRoles[0] : '';
    return this.missionStatusRoleService.getList(<GetMissionStatusRolesInput>{
      roleName: currentRole,
      maxResultCount: 100,
    });
  }

  private mapCurrentStatusEnumValues(missions: MissionsDto[]): void {
    missions.forEach(x => {
      x.statusCode = enumMissionStatusMapping[x.missionStatus];

      if (!x.statusCode) return;

      x.statusId = this.currentStatuses.find(s => s.code == x.statusCode).id;
    });
  }

  private configureActionInMissions(missions: MissionsDto[]): void {
    missions.forEach(mission => this.configureActionInMission(mission));
  }

  private configureActionInMission(mission: MissionsDto): void {
    mission.firstAction = <any>(
      this.currentActionConfiguration.find(
        action =>
          action.order == this.PRIMARY_ACTION_ORDER &&
          this.ALLOWED_ORDER_GRID_ACTIONS.includes(action.code) &&
          action.statusId == mission.statusId,
      )
    );

    let actionsForMission = this.getActionsForMission(mission.statusId);

    let secActionIndex = actionsForMission.findIndex(
      x => x.code == this.FIRST_SECONDARY_ACTION_CODE,
    );

    if (secActionIndex > -1) {
      mission.firstSecondaryAction = actionsForMission[secActionIndex];
      actionsForMission.splice(secActionIndex, 1);
    }

    mission.actions = actionsForMission;
  }

  private getActionsForMission(statusId: string): ActionOrderConfiguredDto[] {
    let actionsForMission = this.currentActionConfiguration.filter(
      x =>
        x.statusId == statusId &&
        x.order > 1 &&
        x.isActionFramework &&
        this.ALLOWED_ORDER_GRID_ACTIONS.includes(x.code),
    );

    actionsForMission.sort((a, b) => a.order - b.order);

    actionsForMission = actionsForMission.filter(
      (action, index, self) => index === self.findIndex(a => a.code === action.code),
    );

    return actionsForMission;
  }

  private getMissionValues(): Observable<PagedResultDto<MissionsDto>> {
    let showAssignedToAnyone = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.AssignedToAnyone',
    );
    let showInAnyStatus = this.permissionService.getGrantedPolicy(
      'missionsService.Mission.InAnyStatus',
    );

    if (showAssignedToAnyone) {
      this.statusAndAssignedPermissions.assignedToAnyone = showAssignedToAnyone;
    }

    if (showInAnyStatus) {
      this.statusAndAssignedPermissions.inAnyStatus = showInAnyStatus;
    }

    return this.service.getMissionsGridData(
      this.missionGridFilters,
      this.statusAndAssignedPermissions,
      this.paginationSortingAndGlobalSearch,
    );
  }

  private setMissionValues(list: PagedResultDto<MissionsDto>) {
    this.data = list;
    this.data.items = this.data.items.map(i => {
      return { ...i, cssClass: this.defineClassRow(i) };
    });
    this.mapCurrentStatusEnumValues(this.data.items);
    this.configureActionInMissions(this.data.items);
  }

  private getPriorityValues(): Observable<PagedResultDto<PrioritiesDto>> {
    const query = {} as ABP.PageQueryParams;
    const priorityFilter = { state: enumState.Enabled } as GetPriorityInput;

    return this.priorityService.getList({
      ...query,
      ...priorityFilter,
      filterText: query.filter,
    });
  }

  private setPriorityValues(res: PagedResultDto<SLADto>) {
    this.priorities = res;
  }

  private getPageAttributeValues(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private setPageAttributes(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
  }

  private getMaxCountAttribute(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private setMaxCountAttribute(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.MaxResultCount = parseInt(attribute.description);
  }

  toggler = (column: string, checked: boolean) => (this.showedColumns[column] = checked);

  columnsStatus() {
    const columnsStatus: ColumnStatus[] = [
      {
        columnName: 'actions',
        softColumnName: 'Actions',
        defaultChecked: this.showedColumns.actions,
        abpPermission:
          'missionsService.Mission.ShowColumn.Actions && General.Common.ShowColumn.Actions',
      },
      {
        columnName: 'name',
        softColumnName: 'Name',
        defaultChecked: this.showedColumns.name,
        abpPermission: 'missionsService.Mission.ShowColumn.Name',
      },
      {
        columnName: 'summary',
        softColumnName: 'Summary',
        defaultChecked: this.showedColumns.summary,
        abpPermission: 'missionsService.Mission.ShowColumn.Summary',
      },
      {
        columnName: 'customer',
        softColumnName: 'Customer',
        defaultChecked: this.showedColumns.customer,
        abpPermission: 'missionsService.Mission.ShowColumn.Customer',
      },
      {
        columnName: 'priority',
        softColumnName: 'Priority',
        defaultChecked: this.showedColumns.priority,
        abpPermission: 'missionsService.Mission.ShowColumn.Priority',
      },
      {
        columnName: 'location',
        softColumnName: 'Location',
        defaultChecked: this.showedColumns.location,
        abpPermission: 'missionsService.Mission.ShowColumn.Location',
      },
      {
        columnName: 'startData',
        softColumnName: 'Start Data',
        defaultChecked: this.showedColumns.startData,
        abpPermission: 'missionsService.Mission.ShowColumn.StartData',
      },
      {
        columnName: 'state',
        softColumnName: 'State',
        defaultChecked: this.showedColumns.state,
        abpPermission:
          'missionsService.Mission.ShowColumn.State && General.Common.ShowColumn.State && General.Common.ShowColumn.State',
      },
      {
        columnName: 'project',
        softColumnName: 'Project',
        defaultChecked: this.showedColumns.project,
        abpPermission: 'missionsService.Mission.ShowColumn.Project',
      },
      {
        columnName: 'additionalNotes',
        softColumnName: 'Additional Notes',
        defaultChecked: this.showedColumns.additionalNotes,
        abpPermission: 'missionsService.Mission.ShowColumn.AdditionalNotes',
      },
      {
        columnName: 'missionStatus',
        softColumnName: 'Mission Status',
        defaultChecked: this.showedColumns.missionStatus,
        abpPermission: 'missionsService.Mission.ShowColumn.MissionStatus',
      },
      {
        columnName: 'pilotSourcingMode',
        softColumnName: 'Pilot Sourcing Mode',
        defaultChecked: this.showedColumns.pilotSourcingMode,
        abpPermission: 'missionsService.Mission.ShowColumn.PilotSourcingMode',
      },
      {
        columnName: 'qaqcMode',
        softColumnName: 'QAQC Mode',
        defaultChecked: this.showedColumns.qaqcMode,
        abpPermission: 'missionsService.Mission.ShowColumn.QaqcMode',
      },
      {
        columnName: 'invoiceMode',
        softColumnName: 'Invoice Mode',
        defaultChecked: this.showedColumns.invoiceMode,
        abpPermission: 'missionsService.Mission.ShowColumn.InvoiceMode',
      },
      {
        columnName: 'cost',
        softColumnName: 'Cost',
        defaultChecked: this.showedColumns.cost,
        abpPermission: 'missionsService.Mission.ShowColumn.Cost',
      },
      {
        columnName: 'portafolio',
        softColumnName: 'Portafolio',
        defaultChecked: this.showedColumns.portafolio,
        abpPermission: 'missionsService.Mission.ShowColumn.Portafolio',
      },
      {
        columnName: 'project',
        softColumnName: 'Project',
        defaultChecked: this.showedColumns.project,
        abpPermission: 'missionsService.Mission.ShowColumn.Project',
      },
      {
        columnName: 'activeCaptureDate',
        softColumnName: 'ActiveCaptureDate',
        defaultChecked: this.showedColumns.activeCaptureDate,
        abpPermission: 'missionsService.Mission.ShowColumn.ActiveCaptureDate',
      },
      {
        columnName: 'pilotName',
        softColumnName: 'PilotName',
        defaultChecked: this.showedColumns.pilotName,
        abpPermission: 'missionsService.Mission.ShowColumn.PilotName',
      },
      {
        columnName: 'assignedTo',
        softColumnName: 'AssignedTo',
        defaultChecked: this.showedColumns.assignedTo,
        abpPermission: 'missionsService.Mission.ShowColumn.AssignedTo',
      },
      {
        columnName: 'assigned',
        softColumnName: 'Assigned',
        defaultChecked: this.showedColumns.assigned,
        abpPermission: 'missionsService.Mission.ShowColumn.Assigned',
      },
      {
        columnName: 'jobId',
        softColumnName: 'JobId',
        defaultChecked: this.showedColumns.jobId,
        abpPermission: 'missionsService.Mission.ShowColumn.JobId',
      },
      {
        columnName: 'nextMilestoneDue',
        softColumnName: 'NextMilestoneDue',
        defaultChecked: this.showedColumns.nextMilestoneDue,
        abpPermission: 'missionsService.Mission.ShowColumn.NextMilestoneDue',
      },
      {
        columnName: 'daysToDelivery',
        softColumnName: 'DaysToDelivery',
        defaultChecked: this.showedColumns.daysToDelivery,
        abpPermission: 'missionsService.Mission.ShowColumn.DaysToDelivery',
      },
    ];

    return columnsStatus;
  }

  /**
   * Reacts to a row clicked, ignores if the action row was hit
   * @param event NGX-Datatable Event
   */
  onSelection(event) {
    if (
      event.type === 'click' &&
      event.column.name !== this.ACTION_COLUMN_NAME &&
      event.column.name !== this.ASSIGNED_COLUMN_NAME &&
      event.column.name !== this.SELECT_COLUMN_NAME &&
      event.column.prop !== this.DAYS_TO_DELIVERY &&
      event.column.prop !== this.PILOT_SOURCING_RESULTS_COLUMN_PROP
    ) {
      const navigateToMissionDetailsDto: NavigateToMissionDetailsDto = { missionId: event.row.id };
      this.onOpenMission.emit(navigateToMissionDetailsDto);
    }
  }

  navigateToPilotSourcingGrid(missionId: string, captureId: string) {
    const navigateToMissionDetailsDto: NavigateToMissionDetailsDto = {
      missionId: missionId,
      navigateToCapturePilotSourcingTab: true,
      currentCaptureId: captureId,
    };

    this.onOpenMission.emit(navigateToMissionDetailsDto);
  }

  findCommonActions(rows: MissionsDto[]): ActionOrderConfiguredDto[] {
    let rowsCopied = [...rows];

    // Get actions from the first row
    const firstRowActions = rowsCopied[0].actions.map(action => action.code);

    if (rowsCopied[0].firstAction?.code) {
      firstRowActions.push(rowsCopied[0].firstAction.code);
    }
    if (rowsCopied[0].firstSecondaryAction?.code) {
      firstRowActions.push(rowsCopied[0].firstSecondaryAction.code);
    }

    // Find the intersection of actions across all rows
    const commonActions = Array.from(
      new Set(
        firstRowActions.filter(action =>
          rowsCopied.every(
            row =>
              row.actions.some(a => a.code === action) ||
              row.firstAction?.code === action ||
              row.firstSecondaryAction?.code === action,
          ),
        ),
      ),
    );

    const uniqueActionsMap: { [key: string]: ActionOrderConfiguredDto } = {};
    const actionsForStatus = this.getActionsForMission(rowsCopied[0].statusId);

    const actions = actionsForStatus.filter(r => commonActions.includes(r.code));

    if (
      commonActions.includes(rowsCopied[0].firstAction?.code) &&
      !actions.includes(rowsCopied[0].firstAction)
    ) {
      actions.push(rowsCopied[0].firstAction);
    }

    actions.forEach(action => {
      uniqueActionsMap[action.code] = action;
    });

    this.isAssignPilotBulk = rowsCopied.every(row => this.isAssignPilot(row));

    return Object.values(uniqueActionsMap)
      .map(r => {
        const acData = this.selectedMissions.map(l =>
          this.getActionData(r.code, l, r.description, this.selectedMissions.length.toString()),
        );

        r.bulkData = acData;
        return r;
      })
      .sort((a, b) => a.order - b.order);
  }

  /**
   * Calls directly to the action clicked on the ... menu
   * @param action: any
   */
  handleAction(action: any) {
    action.currentTarget.querySelector('a').click();
    try {
      this.actionsMenu.closeMenu();
    } catch {}
  }

  handleCopyAction(row: MissionsDto) {
    const copyModal: CopyMissionForm = {
      missionId: row.id,
      jobId: row?.jobId || '',
      missionName: row.name,
      captureDate: this.parseCaptureDate(row.fixedCustomerRequestedCaptureDate) || '',
      captureTime: row.fixedCustomerRequestedCaptureTime || '',
      captureDateMust: row.customerRequestedCaptureMustBe,
    };

    const dialogRef = this.dialogService.open(CopyMissionModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: copyModal,
    });

    dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {});
  }

  handleAssignPilotAction(row: MissionsDto) {
    const missionInfoModal = {
      missionsInfo: [
        {
          missionId: row.id,
          jobId: row?.jobId || '',
          missionName: row.name,
          captureDate: this.parseCaptureDate(row.fixedCustomerRequestedCaptureDate) || '',
          captureTime: row.fixedCustomerRequestedCaptureTime || '',
          pilot: row.pilotName || '',
          location: row.location || '',
          customerName: row.customerName || '',
          captureId: row.captureId || '',
        },
      ],
      isBulk: false,
    };

    const dialogRef = this.dialogService.open(AssignPilotModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: missionInfoModal,
    });

    dialogRef.componentInstance.save.subscribe(pilotData => {
      if (pilotData) {
        let index = this.data.items.findIndex(
          x => x.id == missionInfoModal.missionsInfo[0].missionId,
        );
        if (index !== -1) {
          this.data.items[index].pilotId = pilotData.pilotId;
          this.data.items[index].pilotName = pilotData.pilotName;
        }
      }
    });
  }

  handleAssignPilotActionBulk(rows: MissionsDto[]) {
    const missionInfoModal = {
      missionsInfo: rows.map(row => {
        const createDto: AssignPilotForm = {
          missionId: row.id,
          jobId: row?.jobId || '',
          missionName: row.name,
          captureDate: this.parseCaptureDate(row.fixedCustomerRequestedCaptureDate) || '',
          captureTime: row.fixedCustomerRequestedCaptureTime || '',
          pilot: row.pilotName || '',
          location: row.location || '',
          customerName: row.customerName || '',
          captureId: row.captureId || '',
        };
        return createDto;
      }),
      isBulk: true,
    };

    const dialogRef = this.dialogService.open(AssignPilotModalComponent, {
      disableClose: true,
      panelClass: 'modal-base',
      width: '900px',
      data: missionInfoModal,
    });

    dialogRef.afterClosed().subscribe((data: OrderFormContactModel) => {
      this.ifBulkActionWasExecutedUncheckMissions();
    });

    dialogRef.componentInstance.save.subscribe(pilotData => {
      if (pilotData) {
        missionInfoModal.missionsInfo.forEach(x => {
          let index = this.data.items.findIndex(y => y.id == x.missionId);
          if (index !== -1) {
            this.data.items[index].pilotId = pilotData.pilotId;
            this.data.items[index].pilotName = pilotData.pilotName;
          }
        });
      }
    });
  }

  private parseCaptureDate(dateString: string): string | '' {
    if (!dateString) {
      return '';
    }

    const dateParts = dateString.split('/');

    if (dateParts.length !== 3) {
      return '';
    }

    const formattedDate = `${dateParts[2]}-${dateParts[0].padStart(2, '0')}-${dateParts[1].padStart(
      2,
      '0',
    )}T00:00:00`;
    const captureDate = new Date(formattedDate);

    if (isNaN(captureDate.getTime())) {
      return '';
    }

    return formattedDate;
  }

  buildPriorityRules() {
    this.priorities.items.forEach(pr => {
      var newRule: ColumnColoringRules = {
        columnValue: pr.id,
        softValue: pr.name,
        valueClass: 'badge',
      };
      this.prioritiesRules.push(newRule);
    });

    var defaultRule: ColumnColoringRules = {
      columnValue: 'default',
      softValue: 'Undefined',
      valueClass: 'badge',
      valueStyle: {
        'background-color': 'rgba(51, 51, 51, 0.1)',
        color: '#333',
        'border-radius': '0.2rem',
      },
    };
    this.prioritiesRules.push(defaultRule);
  }

  getBackgroundColor(mainColor) {
    var rgb = this.hexToRgb(mainColor);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`;
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  showAssigned(mission: MissionsDto): void {
    var missionAssignments: AssignedDto[] = [];

    forkJoin([
      this.service.getMissionAssigned(mission.id),
      this.usersService.get(mission.creatorId),
    ]).subscribe(([assigned, creator]) => {
      missionAssignments.push({
        user: `${creator.name ?? ''} ${creator.surname ?? ''}`,
        role: 'Created By',
        roleId: '',
        userId: creator.id,
      });

      assigned.forEach(element => {
        if (missionAssignments.findIndex(ma => ma.role == element.role) == -1)
          missionAssignments.push(element);
      });

      const dialogRef = this.dialogService.open(MissionAssignmentsModalComponent, {
        disableClose: true,
        panelClass: 'modal-base',
        width: '900px',
        data: {
          missionAssignments,
          title: '',
          actions: {},
        },
      });
    });
  }

  // Actions section
  getActionData(
    actionCode: string,
    mission: MissionsDto,
    actionDescription?: string,
    bulkDescription?: string,
  ) {
    if (mission?.deliverablesNames) {
      const uniqueDeliverables = [
        ...new Set(mission.deliverablesNames.split(',').map(r => r.trim())),
      ];
      mission.deliverablesNames = uniqueDeliverables.join(', ');
    }

    let captureDate: string = mission.fixedActiveCaptureDate;
    let captureTime: string = mission.fixedActiveCaptureTime;

    if (!captureDate && mission.activeCaptureDate) {
      let newDate: string = `${format(new Date(mission.activeCaptureDate), 'yyyy-MM-dd')}T${
        mission.activeCaptureTime || '00:00'
      }Z`;

      captureDate = format(new Date(newDate), 'MM/dd/yyyy');
      captureTime = format(new Date(newDate), 'HH:mm');
    }

    let currentUser = this.stateService.getOne('currentUser');

    switch (actionCode) {
      case 'request_refly':
      case 'request_data': {
        return {
          id: mission.id,
          title: mission.name || '-',
          captureDate: captureDate || '-',
          customer: mission.customerName || '-',
          missionSummary: mission.summary || '-',
          pilot: mission.pilotName || '-',
          pilotName: mission.pilotName || '-',
          location: mission.location || '-',
          missionStatus: mission.missionStatus || '-',
          originAuthToken: this.currentToken,
          lastScopeOfWork: mission.lastScopeOfWork,
          creatorId: this.currentUser.id,
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
        };
      }
      case 'reassign_mc':
        return {
          id: mission.id,
          reassignTitle: mission.name || '-',
          captureDate: captureDate || '-',
          customer: mission.customerName || '-',
          reassignSummary: mission.summary,
          pilot: mission.pilotName || '-',
          location: mission.location || '-',
          pilotSuccess: mission.pilotSuccessName || '-',
          creatorId: this.currentUser.id,
          pilotName: mission.pilotName || '-',
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
        };
      case 'check_in_mc': {
        return {
          missionId: mission.id,
          missionName: mission.name || '-',
          customerName: mission.customerName || '-',
          captureDate: captureDate || '-',
          captureTime: captureTime || '-',
          missionStatusCode: mission.statusCode || '-',
          statusEnum: mission.missionStatus,
          missionStatusId: mission.statusId || '-',
          pilotSuccessName: mission.pilotSuccessName || null,
          coordinatorName: mission.missionCoordinatorName,
          salesName: mission.missionCoordinatorName,
          lastScopeOfWork: mission.lastScopeOfWork || null,
          deliverable: mission.deliverablesNames || null,
          missionCoordinator: mission.missionCoordinatorName,
          title: mission.name || '-',
          pilotName: mission.pilotName || '-',
          pilot: mission.pilotName || '-',
          creatorId: this.currentUser.id,
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
        };
      }
      case 'airspace_requested':
        return {
          missionId: mission.id,
          deliverable: mission.deliverablesNames || null,
          airspaceRequestedTitle: mission.name || '-',
          pilotName: mission.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: captureDate || '-',
          captureTime: captureTime || '-',
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
        };
      case 'airspace_denied':
        return {
          missionId: mission.id,
          deliverable: mission.deliverablesNames || null,
          airspaceRequestedTitle: mission.name || '-',
          pilotName: mission.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: captureDate || '-',
          captureTime: captureTime || '-',
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
        };
      case 'airspace_approved':
        return {
          missionId: mission.id,
          deliverable: mission.deliverablesNames || null,
          airspaceRequestedTitle: mission.name || '-',
          pilotName: mission.pilotName || '-',
          creatorId: this.currentUser.id,
          captureDate: captureDate || '-',
          captureTime: captureTime || '-',
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
        };
      case this.QaQcCompleteActionCode:
      case this.ClientInvoicedActionCode: {
        return {
          missionCaptureDetails: {
            currentCaptureDate: captureDate || '-',
            currentCaptureTime: captureTime || '-',
            captureId: mission.missionOrderResume.missionCaptureDetails.captureId,
          },
          missionOrderDetails: {
            pilotName: mission.pilotName,
            pilotId: mission.pilotId,
            locationAddress: mission.location,
            locationCity: mission.locationCity,
            pilotSuccessUserId: mission.pilotSuccessUserId || this.emptyGuid,
            portafolioName: '',
            projectName: '',
          },
          missionName: mission.name,
          missionSummary: mission.summary,
          customerName: mission.customerName || '-',
          customer: mission.customerName || '-',
          pilotName: mission.pilotName || '-',
          pilot: mission.pilotName || '-',
          id: mission.name,
          missionId: mission.id,
          creatorId: this.currentUser.id,
          roles: this.roles,
          currentUserId: this.currentUser.id,
          pilotSuccess: mission.pilotSuccessName || '-',
          qaqcManagerId: `${mission.qaqcUserId}/${this.getUsernameUser(mission.qaqcUserId)}/${
            mission.qaqcRoleId
          }`,
          missionCoordinator: mission.missionCoordinatorName || '-',
          title: mission.name,
          captureDate: captureDate || '-',
          captureTime: captureTime || '-',
          deliverable: mission.deliverablesNames || null,
          salesName: mission.salesName || '-',
          qaqcName: mission.qaqcName || '-',
          currentRoles: this.buildCurrentRolesAF(),
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
          confirmationTitle: 'Warning!',
          confirmationDescription: this.getMessageForConfirmationAction(actionCode),
        };
      }

      default: {
        const orderResume = {
          missionCaptureDetails: {
            currentCaptureDate: captureDate || '-',
            currentCaptureTime: captureTime || '-',
            captureId: mission.missionOrderResume.missionCaptureDetails.captureId,
          },
          missionOrderDetails: {
            pilotName: mission.pilotName,
            pilotId: mission.pilotId,
            locationAddress: mission.location,
            locationCity: mission.locationCity,
            pilotSuccessUserId: mission.pilotSuccessUserId || this.emptyGuid,
            portafolioName: '',
            projectName: '',
          },
          missionName: mission.name,
          missionSummary: mission.summary,
          customerName: mission.customerName || '-',
          customer: mission.customerName || '-',
          pilotName: mission.pilotName || '-',
          pilot: mission.pilotName || '-',
          id: mission.name,
          missionId: mission.id,
          creatorId: this.currentUser.id,
          creatorName: this.currentUser.name,
          roles: this.roles,
          currentUserId: this.currentUser.id,
          pilotSuccess: mission.pilotSuccessName || '-',
          pilotSuccessId:
            mission.missionOrderResume.missionOrderDetails.pilotSuccessUserId &&
            mission.missionOrderResume.missionOrderDetails.pilotSuccessUserId !=
              '00000000-0000-0000-0000-000000000000'
              ? mission.missionOrderResume.missionOrderDetails.pilotSuccessUserId
              : currentUser.id,
          qaqcManagerId: `${mission.qaqcUserId}/${this.getUsernameUser(mission.qaqcUserId)}/${
            mission.qaqcRoleId
          }`,
          missionCoordinator: mission.missionCoordinatorName || '-',
          missionCoordinatorUserId: mission.missionCoordinatorUserId ?? currentUser.id,
          title: mission.name,
          captureDate: captureDate || '-',
          captureTime: captureTime || '-',
          deliverable: mission.deliverablesNames || null,
          salesName: mission.salesName || '-',
          salesUserId: mission.salesUserId ?? currentUser.id,
          qaqcName: mission.qaqcName || '-',
          qaqcUserId: mission.qaqcUserId ?? currentUser.id,
          currentRoles: this.buildCurrentRolesAF(),
          bulkTitle: 'Bulk Actions: ' + actionDescription,
          bulkDescription: bulkDescription,
          airSpaceClasificationId: mission.airSpaceClasificationId,
          fixedDeliverableDueDate: mission.fixedDeliverableDueDate,
          deliverableDueDate: mission.deliverableDueDate,
          priorityDescription: mission.priorityDescription,
        };
        return orderResume;
      }
    }
  }

  getUsernameUser(id: string): string {
    if (id && id !== this.emptyGuid) {
      return this.qaqcUsers?.items?.find(x => x.id === id)?.userName ?? '-';
    }
    return '-';
  }

  // Filters Section
  missionGridFilters: MissionsGridFilters = {};
  filterConfig: FilterConfig = MissionsFilterColumns;
  statusAndAssignedPermissions: StatusAndAssignedPermissions = {
    assignedToAnyone: false,
    inAnyStatus: false,
    assignedToMe: true,
    inTheTeam: false,
    allRecords: true,
  };

  onFiltersApplied(updatedFilters: FilterConfig) {
    if (this.selectedMissions?.length < 1) {
      this.firstRow = null;
      this.selectedActions = new Array();
      this.selectedMissions = new Array();
    }

    updatedFilters.conditions.forEach(updatedCondition => {
      const originalCondition = this.filterConfig.conditions.find(
        c => c.column === updatedCondition.column,
      );
      if (originalCondition) {
        originalCondition.existingValues = updatedCondition.existingValues;

        this.missionGridFilters[updatedCondition.column] = updatedCondition.existingValues;
      }
    });

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();

    this.ifBulkActionWasExecutedUncheckMissions();
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [] };
        }
        return condition;
      }),
    };

    // Clear the specific filter
    this.missionGridFilters[column as keyof MissionsGridFilters] = [];

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.ifBulkActionWasExecutedUncheckMissions();

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    // Reset filters
    for (let condition of this.filterConfig.conditions) {
      this.missionGridFilters[condition.column as keyof MissionsGridFilters] = [];
    }

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.ifBulkActionWasExecutedUncheckMissions();

    this.form.get('searchInput')?.setValue('');
    this.paginationSortingAndGlobalSearch.filter = '';

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  callApiWithFilters() {
    this.spinner.show();
    this.getMissionValues().subscribe({
      next: (missionValues: PagedResultDto<MissionsDto>) => {
        this.setMissionValues(missionValues);
        this.spinner.hide();

        this.changingPageMaxResoultCount = false;

        this.columnPersistance.recalculate();
      },
      error: error => {
        console.error(error);
        this.spinner.hide();
      },
    });
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown || condition.type === FilterType.DateWithOptions) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    // Return the value itself for other types
    return value;
  }

  private updatePriorityOptions(data: any[]): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'priorityId');
    if (condition) {
      condition.options = data.map(item => ({ id: item.id, description: item.name }));
    }
  }

  private updateTimeframeOptions(data: any[]): void {
    const conditionCaptureTime = this.filterConfig.conditions.find(c => c.column === 'captureDate');
    if (conditionCaptureTime) {
      conditionCaptureTime.options = data.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }

    const condition = this.filterConfig.conditions.find(c => c.column === 'creationDate');
    if (condition) {
      condition.options = data.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }

    const dueDateCondition = this.filterConfig.conditions.find(
      c => c.column === 'deliverableDueDate',
    );
    if (dueDateCondition) {
      dueDateCondition.options = data.map(item => ({
        id: item.id.toString(),
        description: item.description,
      }));
    }
  }

  private updateMissionStatusOptions(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'missionStatusId');
    const conditionNoStatus = this.filterConfig.conditions.find(
      c => c.column === 'noMissionStatusId',
    );

    if (condition) {
      this.statusMissionOrderService
        .getList({ maxResultCount: 100, sorting: 'order' })
        .subscribe(res => {
          condition.options = res.items.map(status => {
            return { id: status.statusEnum.toString(), description: status.statusDescription };
          });

          if (conditionNoStatus) conditionNoStatus.options = condition.options;
        });
    }
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;

      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.storeFilterSettings();
      this.callApiWithFilters();
    }
  }

  setPage(pageInfo: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: pageInfo.offset * pageInfo.pageSize,
      maxResultCount: pageInfo.pageSize,
    };

    if (!this.changingPageMaxResoultCount) {
      if (this.selectAll) {
        this.ifBulkActionWasExecutedUncheckMissions();
      }
      this.storeFilterSettings();
      this.callApiWithFilters();
    }
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  onBeforePageSizeChange() {
    this.changingPageMaxResoultCount = true;
  }

  onGlobalSearch() {
    this.paginationSortingAndGlobalSearch.filter = this.form.get('searchInput')?.value.trim();

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  hasFilters() {
    return (
      this.paginationSortingAndGlobalSearch.filter ||
      this.missionGridFilters.priorityId?.length > 0 ||
      this.missionGridFilters.missionName?.length > 0 ||
      this.missionGridFilters.projectId?.length > 0 ||
      this.missionGridFilters.portfolioId?.length > 0 ||
      this.missionGridFilters.locationName?.length > 0 ||
      this.missionGridFilters.captureDate?.length > 0 ||
      this.missionGridFilters.pilotName?.length > 0 ||
      this.missionGridFilters.missionStatusId?.length > 0 ||
      this.missionGridFilters.jobId?.length > 0 ||
      this.missionGridFilters.creationDate?.length > 0
    );
  }

  // Local Storage Filters section
  private storeFilterSettings() {
    const filterSettings: MissionsGridSettingsForLocalStorage = {
      missionGridFilters: this.missionGridFilters,
      paginationSortingAndGlobalSearch: this.paginationSortingAndGlobalSearch,
      statusAndAssignedPermissions: this.statusAndAssignedPermissions,
    };
    localStorage.setItem(`filterSettings_${this.currentUserId}`, JSON.stringify(filterSettings));
  }

  private applyStoredFilterSettings() {
    const storedSettings = localStorage.getItem(`filterSettings_${this.currentUserId}`);

    if (storedSettings) {
      const { missionGridFilters, paginationSortingAndGlobalSearch, statusAndAssignedPermissions } =
        JSON.parse(storedSettings);

      // Variables to call the API
      this.missionGridFilters = missionGridFilters || this.missionGridFilters;
      this.paginationSortingAndGlobalSearch =
        paginationSortingAndGlobalSearch || this.paginationSortingAndGlobalSearch;
      this.statusAndAssignedPermissions =
        statusAndAssignedPermissions || this.statusAndAssignedPermissions;

      // Variables to update ngx-datatable UI
      if (
        paginationSortingAndGlobalSearch.skipCount !== undefined &&
        paginationSortingAndGlobalSearch.maxResultCount
      ) {
        this.pageNumber =
          paginationSortingAndGlobalSearch.skipCount /
          paginationSortingAndGlobalSearch.maxResultCount;
        this.clientMaxResultCount = paginationSortingAndGlobalSearch.maxResultCount;
      }

      // Variables to update the filter chips and sidenav filter summary UI
      for (let i = 0; i < this.filterConfig.conditions.length; i++) {
        const condition: FilterCondition = this.filterConfig.conditions[i];
        const storedValue: string[] | MissionStatusRoleLookup[] =
          this.missionGridFilters[condition.column as keyof MissionsGridFilters];

        if (storedValue && this.isArrayOfString(storedValue)) {
          condition.existingValues = storedValue;
        }
      }

      // Update the global search input in the form
      if (paginationSortingAndGlobalSearch && paginationSortingAndGlobalSearch.filter) {
        this.form.get('searchInput')?.setValue(paginationSortingAndGlobalSearch.filter);
      }

      if (
        this.missionGridFilters.viewSelected &&
        this.missionGridFilters.viewSelected.length > 0 &&
        this.existingViews.find(x => x.id == this.missionGridFilters.viewSelected[0])
      ) {
        this.selectedAssignedFilter = <any>this.missionGridFilters.viewSelected[0];
        this.refreshFilterConfig();
        this.handleOnViewSelected(this.missionGridFilters.viewSelected[0]);
        return;
      } else {
        if (this.statusAndAssignedPermissions.allRecords) {
          this.selectedAssignedFilter = 2;
        } else if (this.statusAndAssignedPermissions.assignedToMe) {
          this.selectedAssignedFilter = 0;
        } else if (this.statusAndAssignedPermissions.inTheTeam) {
          this.selectedAssignedFilter = 1;
        } else {
          this.selectedAssignedFilter = 2;
        }
      }

      this.refreshFilterConfig();
    }

    this.callApiWithFilters();
  }

  // We need this to ensure that the component app-grid-filters is re-rendered.
  // Otherwise we get weird behaviour, displaying previous filters in the summary.
  private refreshFilterConfig() {
    this.filterConfig = { ...this.filterConfig };
  }

  private refreshNoteList(mission: MissionsDto) {
    mission.reloadingNotes = true;

    setTimeout(() => {
      mission.reloadingNotes = false;
    }, 1000);
  }

  handleAssignedSelect(value: assignedFilterEnum, uiEvent: boolean = false) {
    if (uiEvent) {
      this.viewConfigSelected = undefined;
      this.viewSelected = undefined;
      this.missionGridFilters.viewSelected = [];
    }

    switch (value) {
      case assignedFilterEnum.MyMission:
        this.statusAndAssignedPermissions.assignedToMe = true;
        this.statusAndAssignedPermissions.inTheTeam = false;
        this.statusAndAssignedPermissions.allRecords = false;
        break;
      case assignedFilterEnum.MyTeam:
        this.statusAndAssignedPermissions.inTheTeam = true;
        this.statusAndAssignedPermissions.allRecords = false;
        this.statusAndAssignedPermissions.assignedToMe = false;
        break;
      case assignedFilterEnum.All:
        this.statusAndAssignedPermissions.allRecords = true;
        this.statusAndAssignedPermissions.assignedToMe = false;
        this.statusAndAssignedPermissions.inTheTeam = false;
        break;
    }

    this.columnPersistance.load(this.viewSelected?.id);

    this.paginationSortingAndGlobalSearch.sorting =
      this.columnPersistance.getSorting() || this.viewConfigSelected?.sorting;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  handleFilterFromView() {
    this.columnPersistance.load(this.viewSelected?.id);

    this.paginationSortingAndGlobalSearch.sorting =
      this.columnPersistance.getSorting() || this.viewConfigSelected?.sorting;

    this.storeFilterSettings();
    this.callApiWithFilters();
  }

  isArrayOfString(arr: any[]): arr is string[] {
    return arr.every(element => typeof element === 'string');
  }

  buildCurrentRolesAF() {
    return this.currentUserRoles.map(d => {
      return {
        currentRole: d,
        currentUserId: this.currentUserId,
      };
    });
  }

  isCopyMissionAllowed(row: MissionsDto): boolean {
    const statusId = row.statusId;

    return !!this.currentActionConfiguration?.find(
      t => t.code == this.COPY_MISSION && t.statusId == statusId,
    );
  }

  isAssignPilot(row: MissionsDto): boolean {
    const statusId = row.statusId;

    return !!this.currentActionConfiguration?.find(
      t => t.code == this.ADD_PILOT && t.statusId == statusId,
    );
  }

  QaQcCompleteActionCode: string = 'QA_QC_Complete';
  ClientInvoicedActionCode: string = 'client_invoiced';

  checkIfThrowsConfirmationAction(mission: MissionsDto, actionCode: string): boolean {
    switch (actionCode) {
      case this.QaQcCompleteActionCode:
        return mission.preventQaQcComplete;
      case this.ClientInvoicedActionCode:
        return mission.preventClientInvoiced;
      default:
        return false;
    }
  }

  getMessageForConfirmationAction(actionCode: string): string {
    switch (actionCode) {
      case this.QaQcCompleteActionCode:
        return 'At least one or more deliverables are not accepted, are you sure you want to mark QA QC Complete?';
      case this.ClientInvoicedActionCode:
        return 'Pilot payment date is missing, are you sure you want to mark as client invoiced?';
      default:
        return '';
    }
  }

  /**
   * Handles the opening of a selected mission in a new tab
   * @param mission MissionsDto
   */
  handleMissionInNewTab(mission: MissionsDto) {
    window.open(`${this.PILOT_SOURCING_PATH}${mission.id}`);
  }

  onSelect({ selected }) {
    if (selected.length === 0) {
      this.ifBulkActionWasExecutedUncheckMissions();
      return;
    }

    this.selectedMissions.splice(0, this.selectedMissions.length);
    this.selectedMissions.push(...selected);

    if (this.selectedMissions.length == this.data.items?.length) {
      this.selectAll = true;
    }

    this.checkCommonActions();
  }

  openPopupSlaMilestones(misionId: string, slaName: string) {
    const dialogRef = this.dialogService.open(ShowSlaMilestonesComponent, {
      panelClass: 'modal-base',
      width: '900px',
    });
    dialogRef.componentInstance.Initialize(misionId, slaName);
  }

  getPriorityClass(row: any): any {
    return row.cssClass;
  }

  defineClassRow(row: any): any {
    if (!row.slaDueDate) return '';

    if (
      row?.missionStatus &&
      ['11', '14', '22', '23', '10'].includes(row.missionStatus.toString())
    ) {
      return '';
    }

    const today = new Date();
    const targetDate = new Date(row.slaDueDate);

    today.setHours(0, 0, 0, 0);
    targetDate.setHours(0, 0, 0, 0);

    if (today > targetDate) {
      return 'overdue';
    } else if (today.getTime() === targetDate.getTime()) {
      return 'risk';
    }
    return 'common-row';
  }

  CreatePilotVisibilityAcceptanceFormattedString(
    requested: number,
    accepted: number,
    rejected: number,
  ): string {
    return `<b>${this.localizationService.instant('missionsService::Requested')}</b>: ${
      requested ?? 0
    }<br><b>${this.localizationService.instant('missionsService::Accepted')}</b>: ${
      accepted ?? 0
    }<br><b>${this.localizationService.instant('missionsService::Rejected')}</b>: ${rejected ?? 0}`;
  }

  private getSLAOptions() {
    return this.slaService.getList({
      maxResultCount: 100,
      isPaginated: false,
    });
  }

  private parseDate(dateString: string): string | '' {
    if (!dateString) {
      return '';
    }

    const dateParts = dateString.split('/');

    if (dateParts.length !== 3) {
      return '';
    }

    const formattedDate = `${dateParts[0].padStart(2, '0')}/${dateParts[1].padStart(
      2,
      '0',
    )}/${dateParts[2]}`;

    const captureDate = new Date(formattedDate);

    if (isNaN(captureDate.getTime())) {
      return '';
    }

    return formattedDate;
  }

  updateGridDataFromNewChangeOrderRequest(location: LocationForm) {
    if (this.data.totalCount > 0) {
      const mission = this.data.items.find(item => item.id == location.missionId);
      if (mission) {
        mission.airSpaceClasificationId = location.airspaceClassificationId;
      }
    }
  }

  updateGridDataFromNewMilestones(slaMilestones: any) {
    if (this.data.totalCount > 0) {
      const mission = this.data.items.find(item => item.id == slaMilestones.missionId);
      if (mission) {
        this.NotifyMissionDateChanges(
          mission,
          slaMilestones.deliverableDueDate,
          slaMilestones.fixedDeliverableDueDate,
          slaMilestones.slaDueDate,
        );
      }
    }
  }

  updateNewSla(newSla: any) {
    if (this.data.totalCount > 0) {
      const mission = this.data.items.find(item => item.id == newSla.missionId);
      if (mission) {
        mission.priorityDescription = newSla.priorityDescription;
        mission.priorityId = newSla.priorityId;
      }
    }
  }
}
