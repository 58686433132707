<!-- We need to remove hardcoded values from here, see [here](https://dev.azure.com/newtonvision/FLYGUYS/_workitems/edit/10613) -->
<ngx-spinner
  bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="false"
  [zIndex]="111"
>
  <p style="color: #bc5a2e">{{ 'missionsService::LoadingSourcePilots' | abpLocalization }}</p>
</ngx-spinner>
<mat-sidenav-container
  class="filters-container bg-white mt-2"
  [class.disabled-content]="callingApi"
>
  <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
    <app-grid-filters
      [filterTitle]="'Filter Source Pilots'"
      [filterConfig]="filterConfig"
      (applyFilters)="onFiltersApplied($event)"
      (closeSidenav)="drawer.close()"
    >
    </app-grid-filters>
  </mat-sidenav>

  <mat-sidenav-content class="m-2">
    <div class="d-flex align-items-center t-mt-12" *ngIf="!titleKey; else showTitle">
      <ol class="breadcrumb mb-0 ps-0">
        <li class="breadcrumb-item">
          <a (click)="navigateToCaptures()" role="button">{{
            'missionsService::Captures' | abpLocalization
          }}</a>
        </li>
        <li class="breadcrumb-item isLast">
          {{ 'missionsService::PilotSourcing' | abpLocalization }}
        </li>
      </ol>
    </div>

    <ng-template #showTitle>
      <h1>
        {{ titleKey | abpLocalization }}
      </h1>
    </ng-template>

    <form [formGroup]="searchForm" (submit)="onGlobalSearch()">
      <mat-form-field class="w-100 global-search-input pb-0 mt-2">
        <mat-label>{{ 'missionsService::Search' | abpLocalization }}</mat-label>
        <input matInput placeholder="Search" formControlName="searchInput" />
        <mat-icon matSuffix class="cursor-pointer blue-color" (click)="onGlobalSearch()"
          >search</mat-icon
        >
      </mat-form-field>
    </form>

    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center py-0">
          <span class="fg-subtitle me-2">{{
            'missionsService::IWantToSourcePilotsWith' | abpLocalization
          }}</span>

          <!-- Display applied filters -->
          <div class="d-flex align-items-center">
            <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
              <ng-container *ngIf="condition.existingValues.length">
                <app-filter-chip
                  [label]="condition.columnDisplayName"
                  [value]="getFilterDisplayValue(condition)"
                  (removeEvent)="removeFilter(condition.column)"
                ></app-filter-chip>
                <span *ngIf="!isLast" class="comma-separator">, </span>
              </ng-container>
            </ng-container>
          </div>

          <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
            add_circle
          </span>

          <span
            class="d-inline-block main-color fw-medium cursor-pointer"
            (click)="removeAllFilters()"
            >{{ 'missionsService::ClearAll' | abpLocalization }}</span
          >
        </div>

        <div class="d-flex align-items-center">
          <mat-slide-toggle
            [(ngModel)]="pilotForSourcingGridExtraFilters.infoChecked"
            (click.stop)="onInfoCheckedTurn()"
            >{{ 'pilotsService::OnlyActive' | abpLocalization }}</mat-slide-toggle
          >
          <div *abpPermission="'General.Common.Actions.SMSSending'">
            <mat-slide-toggle
              [(ngModel)]="pilotForSourcingGridExtraFilters.sendSMS"
              *ngIf="selectedPilots.length > 0"
              >{{ 'pilotsService::SendSMS' | abpLocalization }}</mat-slide-toggle
            >
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-end mb-3" *ngIf="selectedPilots.length > 0">
      <lib-management-actions
        class="pointer flex"
        gridName="Captures"
        [rowData]="getActionData(0, 'REQUEST')"
        uniqueAction="add_pilot_payment"
        [alwaysExecuteParentEvent]="true"
        (parentEvent)="requestPilots()"
      >
        <button class="btn btn-outline-primary">
          <div class="d-flex align-items-center">
            <span class="material-symbols-rounded"> crisis_alert </span>
            <span *ngIf="selectedPilots.length === 1" class="t-ml-8">
              {{ 'missionsService::RequestPilot' | abpLocalization }}
            </span>
            <span *ngIf="selectedPilots.length !== 1" class="t-ml-8">
              {{ 'missionsService::RequestPilot' | abpLocalization }}s
            </span>
          </div>
        </button>
      </lib-management-actions>
      <lib-management-actions
        class="pointer flex"
        gridName="Captures"
        [rowData]="getActionData(1, 'ASSIGN')"
        uniqueAction="add_pilot_payment"
        [alwaysExecuteParentEvent]="true"
        (parentEvent)="assignPilots()"
      >
        <button *ngIf="selectedPilots.length === 1" class="t-ml-8 btn btn-primary">
          {{ 'missionsService::AssignPilot' | abpLocalization }}
        </button>
        <button *ngIf="selectedPilots.length !== 1" class="t-ml-8 btn btn-primary">
          {{ 'missionsService::AssignPilot' | abpLocalization }}s
        </button>
      </lib-management-actions>
    </div>
    <div class="card grid">
      <div class="card-body grid-body">
        <ngx-datatable
          [rows]="pilotsForSourcingData.items"
          [offset]="pageNumber"
          [count]="pilotsForSourcingData.totalCount"
          [list]="list"
          [limit]="clientMaxResultCount"
          [externalPaging]="true"
          [headerHeight]="60"
          [footerHeight]="55"
          [rowHeight]="50"
          [selectAllRowsOnPage]="true"
          (sort)="onSort($event)"
          (page)="setPage($event)"
          [selected]="selectedPilots"
          [selectionType]="SelectionType.checkbox"
          [selectCheck]="checkSelectable"
          [displayCheck]="checkSelectable"
          (select)="onSelect($event)"
          class="flg-grid-table"
          default
          #dataTable
        >
          <ngx-datatable-column
            [frozenLeft]="true"
            [width]="50"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            name="Click here to Select/Deselect All"
          >
            <ng-template
              ngx-datatable-header-template
              let-value="value"
              let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn"
            >
              <mat-checkbox
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
                matTooltip="Click here to Select/Deselect All"
              >
              </mat-checkbox>
            </ng-template>
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <mat-checkbox
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
                matTooltip="Click here to Select/Deselect"
              >
              </mat-checkbox>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'pilotsService::PilotName' | abpLocalization }}"
            prop="name"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.name }}" matTooltipPosition="left">{{ row.name }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'pilotsService::Prefered' | abpLocalization }}"
            prop="isPreferred"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon color="primary" *ngIf="row.isPreferred">check_box</mat-icon>
              <mat-icon color="primary" *ngIf="!row.isPreferred">check_box_outline_blank</mat-icon>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'pilotsService::PilotPhoneNumber' | abpLocalization }}"
            prop="phone"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.phone }}" matTooltipPosition="left">{{ row.phone }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'pilotsService::TimeZone' | abpLocalization }}"
            prop="timeZone"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.timeZone }}" matTooltipPosition="left">{{
                row.timeZone
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PilotDistance' | abpLocalization }}"
            prop="distance"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span
                class="cursor-pointer"
                (click)="showDrivingDistance(row)"
                matTooltip="{{ row.distance == -1 ? 'N/A' : row.distance + ' Miles' }}"
                matTooltipPosition="left"
                >{{ row.distance == -1 ? 'N/A' : row.distance + ' Miles' }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PilotFullAddress' | abpLocalization }}"
            prop="address"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.address }}" matTooltipPosition="left">{{
                row.address
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'pilotsService::CustomerJobCount' | abpLocalization }}"
            prop="jobsForThisCustomer"
            [minWidth]="160"
            [maxWidth]="200"
            [width]="160"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.jobsForThisCustomer }}" matTooltipPosition="left">{{
                row.jobsForThisCustomer
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::TotalRanking' | abpLocalization }}"
            prop="totalRanking"
            [minWidth]="160"
            [maxWidth]="200"
            [width]="160"
            *ngIf="showRanking"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.totalRanking }}" matTooltipPosition="left"
                >{{ row.totalRanking }}%</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PilotJobCount' | abpLocalization }}"
            prop="jobCount"
            [minWidth]="130"
            [maxWidth]="200"
            [width]="130"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.jobCount }}" matTooltipPosition="left">{{
                row.jobCount
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::Badges' | abpLocalization }}"
            prop="lastBadgeDescription"
            [minWidth]="150"
            [maxWidth]="250"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span class="badge-container">
                <app-grid-badge
                  *ngIf="row.lastBadgeId"
                  [badge]="{
                    text: getBadgeText(row),
                    color: '#0252ED',
                    background: '#0252ED1A',
                    id: row.lastBadgeId,
                  }"
                  [shortenText]="true"
                ></app-grid-badge>

                <div *ngIf="row.totalBadgesCount > 1" class="badge-plus-chip">
                  {{ row.totalBadgesCount - 1 }}+
                </div>
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::RequestStatus' | abpLocalization }}"
            prop="requestStatus"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <app-fg-chip
                *ngIf="row.requestStatusDescription"
                [type]="getRequestStatusChipType(row.requestStatusDescription)"
                [text]="getRequestStatusDescription(row.requestStatusDescription)"
                matTooltip="{{ getRequestStatusDescription(row.requestStatusDescription) }}"
                matTooltipPosition="left"
              >
              </app-fg-chip>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::Availability' | abpLocalization }}"
            prop="availabilityId"
            [minWidth]="150"
            [maxWidth]="250"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.availabilityDescription }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column name="{{ 'pilotsService::Note' | abpLocalization }}" prop="note">
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span
                matTooltip="{{ row.lastNote }}"
                matTooltipPosition="left"
                matTooltipClass="red-tooltip"
              >
                {{ row.lastNote }}
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::SuggestedDate' | abpLocalization }}"
            prop="expectedDate"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span
                matTooltip="{{
                  (row.expectedDate ? row.expectedDate : '') | date: 'MM/dd/yyyy h:mm a'
                }}"
                matTooltipPosition="left"
                >{{ (row.expectedDate ? row.expectedDate : '') | date: 'MM/dd/yyyy h:mm a' }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PreferredContactStyle' | abpLocalization }}"
            prop="preferredContactStyle"
            [minWidth]="150"
            [maxWidth]="250"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.preferredContactStyle }}" [matTooltipShowDelay]="1000">
                {{ row.preferredContactStyle }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::SensorsType' | abpLocalization }}"
            prop="sensorsType"
            [minWidth]="150"
            [maxWidth]="250"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.sensorsType }}" [matTooltipShowDelay]="1000">
                {{ row.sensorsType }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::Actions' | abpLocalization }}"
            [width]="250"
            [frozenRight]="true"
            [sortable]="false"
            [cellClass]="'fixed-column'"
          >
            <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
              <div class="flg-grid-table-actions">
                <div class="secondary-actions secondary-actions--alone">
                  <button
                    type="button"
                    class="btn p-0 single-action"
                    (click)="chatwithPilot(row)"
                    matTooltip="Chat with pilot"
                    *ngIf="allowChatWithPilot"
                  >
                    <span class="material-symbols-rounded"> chat </span>
                  </button>

                  <button
                    type="button"
                    class="btn p-0 single-action"
                    matTooltip="Pilot Information"
                    (click)="displayPilotProfile(row.id, row.userId)"
                  >
                    <span class="material-symbols-rounded"> info </span>
                  </button>

                  <div
                    class="p-0 single-action"
                    *ngIf="row.email?.length > 0"
                    matTooltip="Mail Pilot"
                  >
                    <a href="mailto:{{ row.email }}" target="_blank" class="contact-method">
                      <span class="material-symbols-rounded">mail</span></a
                    >
                  </div>
                  <button
                    mat-button
                    [disableRipple]="true"
                    [matMenuTriggerFor]="menu"
                    aria-label="Grid Actions"
                    class="single-action"
                  >
                    <mat-icon>more_vert</mat-icon>
                  </button>
                  <mat-menu #menu="matMenu" [class]="'secondary-actions mat-elevation-z2'">
                    <button
                      *abpPermission="'General.Common.Actions.SMSSending'"
                      mat-menu-item
                      class="secondary-actions secondary-actions__action"
                      (click)="sendSms(row)"
                    >
                      <div class="flex-center">
                        <span>{{ 'missionsService::SMS' | abpLocalization }}</span>
                      </div>
                    </button>

                    <a
                      mat-menu-item
                      class="secondary-actions secondary-actions__action"
                      href="tel:{{ row.phone }}"
                    >
                      <div class="flex-center">
                        <span>{{ 'missionsService::Call' | abpLocalization }}</span>
                      </div>
                    </a>

                    <a
                      mat-menu-item
                      class="secondary-actions secondary-actions__action"
                      (click)="openAssignBadgeModal(row)"
                      *ngIf="allowAssignBadge"
                    >
                      <div class="flex-center">
                        <span>{{ 'missionsService::AssignBadge' | abpLocalization }}</span>
                      </div>
                    </a>

                    <a
                      (click)="openRevokeBadgeModal(row)"
                      *ngIf="allowRevokeBadge"
                      class="secondary-actions secondary-actions__action"
                      mat-menu-item
                    >
                      <div class="flex-center">
                        <span>{{ 'missionsService::RevokeBadge' | abpLocalization }}</span>
                      </div>
                    </a>

                    <button
                      mat-menu-item
                      class="secondary-actions secondary-actions__action"
                      (click)="openUpdateRequestStatusModal(row)"
                    >
                      <div class="flex-center">
                        <span>{{ 'missionsService::UpdateRequestStatus' | abpLocalization }}</span>
                      </div>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-curPage="curPage"
            >
              <app-table-paginator
                [(maxResultCount)]="list.maxResultCount"
                [(clientMaxResultCount)]="clientMaxResultCount"
                [rowCount]="rowCount"
                [pageSize]="pageSize"
                [(pageNumber)]="pageNumber"
                [curPage]="curPage"
                [dataTable]="dataTable"
                (clientMaxResultCountChange)="onPaginationChange($event)"
              >
              </app-table-paginator>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<abp-modal [busy]="isModalBusy" [options]="{ size: 'lg' }" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>{{ 'missionsService::UpdateRequestStatus' | abpLocalization }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form
      [formGroup]="form"
      id="update-status"
      (ngSubmit)="submitUpdateRequestStatus()"
      validateOnSubmit
    >
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="status">
            {{ 'missionsService::RequestStatus' | abpLocalization }}</label
          >
          <select class="form-control" formControlName="status" id="status">
            <option *ngFor="let status of requestStatuses.items" [value]="status.id">
              {{ status.description }}
            </option>
          </select>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="update-status">
      {{ 'AbpUi::Save' | abpLocalization }}
    </abp-button>
  </ng-template>
</abp-modal>

<abp-modal [busy]="isSMSModalBusy" [options]="{ size: 'lg' }" [(visible)]="isSMSModalOpen">
  <ng-template #abpHeader>
    <h3>New Sms Notification - {{ pilotsForSMS[0].name }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form
      [formGroup]="notificationForm"
      id="notifications"
      (ngSubmit)="submitNotificationForm()"
      validateOnSubmit
    >
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="sms-notification-message"> Message * </label>

          <textarea
            id="sms-notification-message"
            formControlName="message"
            class="form-control"
            maxlength="320"
          ></textarea>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="notifications">
      {{ 'Send' }}
    </abp-button>
  </ng-template>
</abp-modal>
