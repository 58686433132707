import {
  ABP,
  ConfigStateService,
  LIST_QUERY_DEBOUNCE_TIME,
  ListService,
  PagedResultDto,
  TrackByService,
} from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';
import { NgxSpinnerService } from 'ngx-spinner';
import { combineLatest, Observable, Subscription, switchMap } from 'rxjs';
import {
  ActionOrderConfiguredDto,
  ConfigurationAttributeTypesDto,
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetStatusesInput,
  PrioritiesDto,
  StatusDto,
  MissionStatusRoleLookup,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  MissionStatusRoleService,
  PrioritiesService,
  StatusService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';

import {
  AssignedDto,
  Control,
  MissionsDto,
  ProjectsDto,
} from '../../../proxy/missions-service/basics/models';
import { MissionsService } from '../../../proxy/missions-service/controllers/basics/missions.service';
import {
  enumState,
  EnumStateOptions,
} from '../../../proxy/missions-service/shared/enum-state.enum';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import { missionStatusOptions } from '../../../proxy/missions-service/shared/mission-status.enum';
import { stateRules } from 'projects/flyguys/src/app/components/columns/rules/lookup-rules';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { OAuthService } from 'angular-oauth2-oidc';

import { enumMissionStatusMapping } from '../../../proxy/missions-service/basics/enum-mission-status-mapping.enum';
import { IdentityUserService } from '@volo/abp.ng.identity/proxy';

import { NotificationBroadcastService } from 'projects/flyguys/src/app/services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { ColumnColoringRules } from 'projects/flyguys/src/app/components/columns/components/column-stylizer/column-stylizer.component';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';
import { ProductsDeliverablesService } from '../../../../../../flyguys/src/app/services/products-deliverables.service';

import { PaginationSortingAndGlobalSearch } from '../../../../../../flyguys/src/app/shared/grid-filters/models/pagination-sorting-and-global-search';
import { StatusAndAssignedPermissions } from '../../../../../../flyguys/src/app/shared/grid-filters/models/status-and-assigned-permissions';
import { FilterConfig } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-config.model';
import { FilterCondition } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-condition.model';
import { FilterType } from '../../../../../../flyguys/src/app/shared/grid-filters/models/filter-type.enum';
import { PilotSuccessDto } from '../../../proxy/missions-service/relationals';
import { MissionAssignmentsModalComponent } from '../../mission-assignments-modal/mission-assignments-modal.component';
import { MatDialog } from '@angular/material/dialog';

import {
  assignedFilterEnum,
  assignedFilterDisplayNames,
} from '../../../proxy/missions-service/shared/assignedFilter.enum';
import { StatusMissionOrderService } from '../../../proxy/missions-service/controllers/basics/status-mission-order.service';
import { MissionsGridFilters } from '../../missions/components/models/missions-grid-filters';
import { PortfolioMissionsFilterColumns } from '../../missions/components/models/portfolio-missions-filter-columns';
import { NewSiteFormComponent } from 'projects/flyguys/src/app/components/orders/new-site/new-site-form.component';
import { OrderFormSiteModel } from 'projects/flyguys/src/app/components/orders/model/order-form-site.model';
import {
  OrdersService,
  PortafoliosService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';

@Component({
  selector: 'app-portfolio-missions',
  providers: [
    ListService,
    { provide: NgbDateAdapter, useClass: DateAdapter },
    { provide: LIST_QUERY_DEBOUNCE_TIME, useValue: 600 },
  ],
  templateUrl: './portfolio-missions.component.html',
  styleUrls: ['./portfolio-missions.component.scss'],
})
export class PortfolioMissionsComponent implements OnInit, OnDestroy {
  [x: string]: any;

  private readonly MAX_COUNT_ATTR = 'MaxResultCount';
  private readonly PAGE_SIZE_ATTR = 'PageSize';

  @Input() portfolioId: string;
  @Input() customerId: string;

  @Output()
  onMissionSelected = new EventEmitter<string>();

  currentUserId: string;

  subscription = new Subscription();

  data: PagedResultDto<MissionsDto> = {
    items: [],
    totalCount: 0,
  };

  projects: PagedResultDto<ProjectsDto> = {
    items: [],
    totalCount: 0,
  };

  priorities: PagedResultDto<PrioritiesDto> = {
    items: [],
    totalCount: 0,
  };

  lstAssigned: PagedResultDto<AssignedDto> = {
    items: [],
    totalCount: 0,
  };

  form: FormGroup = new FormGroup({
    searchInput: new FormControl(''),
  });

  isFiltersHidden = true;

  isModalBusy = false;
  isModalOpen = false;

  isModalOpenAssigned = false;

  isExportToExcelBusy = false;

  selected?: MissionsDto;

  states = EnumStateOptions;

  public readonly missionStatus = missionStatusOptions;

  values = [];

  controls: Control<string>[] = [];

  @Input() export: Boolean = false;

  clientMaxResultCount = 10;
  valuesMaxResultCount = [];
  pageNumber = 0;
  action: any;
  MaxResultCount = 100;

  qaqcUsers: PagedResultDto<PilotSuccessDto> = {
    items: [],
    totalCount: 0,
  };

  stateRules = stateRules;
  prioritiesRules: ColumnColoringRules[] = [];
  reloadingNotes: boolean = false;

  currentToken: string;

  currentUserRoles: Array<string>;
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentStatuses: Array<StatusDto>;

  users: any;
  roles: any;
  currentUser: any;

  emptyGuid: string = '00000000-0000-0000-0000-000000000000';
  priority: string;
  // Default paging values
  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearch = {
    skipCount: 0,
    maxResultCount: 10,
  };

  lstAssignedFilter = Object.keys(assignedFilterEnum)
    .filter(key => typeof assignedFilterEnum[key] === 'number')
    .map(key => ({
      value: assignedFilterEnum[key],
      description: assignedFilterDisplayNames[assignedFilterEnum[key]],
    }));

  selectedAssignedFilter: number = 0;
  selectedActions: ActionOrderConfiguredDto[] = new Array();
  firstRow: MissionsDto;
  selectedMissions: MissionsDto[] = [];
  loadingCommonActions: boolean = false;

  // Filters Section
  missionGridFilters: MissionsGridFilters = {};
  filterConfig: FilterConfig = PortfolioMissionsFilterColumns;
  statusAndAssignedPermissions: StatusAndAssignedPermissions = {
    assignedToAnyone: false,
    inAnyStatus: false,
    assignedToMe: true,
    inTheTeam: false,
    allRecords: true,
  };

  constructor(
    public readonly list: ListService,
    public readonly track: TrackByService,
    public readonly service: MissionsService,
    public readonly customerService: CustomersService,
    public readonly pilotService: PilotService,
    public readonly priorityService: PrioritiesService,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationTypesList: ListService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly configurationAttributeTypesList: ListService,
    private oAuthService: OAuthService,
    private stateService: ConfigStateService,
    private readonly statusService: StatusService,
    private usersService: IdentityUserService,
    private spinner: NgxSpinnerService,
    private notificationBroadcastService: NotificationBroadcastService,
    private dialogService: MatDialog,
    private readonly missionStatusRoleService: MissionStatusRoleService,
    private readonly statusMissionOrderService: StatusMissionOrderService,
    private orderService: OrdersService,
    public readonly portfolioService: PortafoliosService,
  ) {}

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  ngOnInit() {
    this.spinner.show();

    this.currentUser = this.stateService.getDeep('currentUser');

    combineLatest([
      this.getPageAttributeValues(),
      this.getMaxCountAttribute(),
      this.getStatuses(),
      this.getMissionStatusRoleConfiguration(),
      this.getPorfolioMissionIds(this.portfolioId),
    ]).subscribe({
      next: ([pageAttributes, maxCountAttribute, statuses, missionStatusRole, missionList]) => {
        this.setPageAttributes(pageAttributes);
        this.setMaxCountAttribute(maxCountAttribute);

        this.currentStatuses = statuses.items;

        this.updateMissionStatusOptions();

        this.missionGridFilters.MissionStatusRoles = missionStatusRole;

        this.missionIdList = missionList;

        // Get missions based on stored filters
        this.callApiWithFilters();

        this.spinner.hide();
      },
      error: err => {
        console.error(`Mission List, error while fetching data: ${err}`);
        this.spinner.hide();
      },
    });

    this.currentToken = this.oAuthService.getAccessToken();
    this.currentUserId = this.stateService.getDeep('currentUser.id');

    this.usersService.getList({ maxResultCount: 1000 }).subscribe(r => {
      this.users = r.items?.map(q => {
        return { id: q.id, value: `${q.name || ''} ${q.surname || ''}` };
      });
    });

    this.subscription.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (notif.notificationKey == enumWebBackgroundNotificationKey.EventGlobalForMissionStatus) {
          if (
            notif.itemId &&
            (notif.extraArgument.missionStatus || notif.extraArgument.missionStatus == 0) &&
            notif.extraArgument.missionStatusCode &&
            this.missionIdList.find(x => x == notif.itemId)
          ) {
            this.callApiWithFilters();
            this.refreshPortfolioStatuses();
          }
        }
      }),
    );

    this.usersService.getAssignableRoles().subscribe({
      next: response => {
        this.roles = response;
      },
      error: err => {
        console.log(err);
      },
    });
  }

  handleOnAddNewSite(): void {
    let dialogData = {
      siteModel: new OrderFormSiteModel(),
      portfolioId: this.portfolioId,
      customerId: this.customerId,
      priorityId: this.priority,
    };

    const dialogRef = this.dialogService.open(NewSiteFormComponent, {
      disableClose: true,
      width: '1200px',
      data: dialogData,
    });

    dialogRef.afterClosed().subscribe(data => {
      if (!data) {
        return;
      }

      this.orderService.createPortfolioMission(data).subscribe({
        next: res => {
          this.callApiWithFilters();
        },
        error: error => {
          console.log('Error creating customer admin user', error);
        },
      });
    });
  }

  public handleOnOpenMission(missionId: string): void {
    this.onMissionSelected.emit(missionId);
  }

  private getPorfolioMissionIds(portfolioId: string): Observable<Array<string>> {
    return this.portfolioService.getMissionIds(portfolioId);
  }

  private getMissionStatusRoleConfiguration(): Observable<MissionStatusRoleLookup[]> {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    return this.missionStatusRoleService.filterByRoles(this.currentUserRoles);
  }

  private getStatuses(): Observable<PagedResultDto<StatusDto>> {
    return this.statusService.getList(<GetStatusesInput>{});
  }

  private mapCurrentStatusEnumValues(missions: MissionsDto[]): void {
    missions.forEach(x => {
      x.statusCode = enumMissionStatusMapping[x.missionStatus];

      if (!x.statusCode) return;

      x.statusId = this.currentStatuses.find(s => s.code == x.statusCode).id;
    });
  }

  private getMissionValues(portfolioId: string): Observable<PagedResultDto<MissionsDto>> {
    return this.service.getPortfolioMissionsData(
      portfolioId,
      this.missionGridFilters,
      this.statusAndAssignedPermissions,
      this.paginationSortingAndGlobalSearch,
    );
  }

  private setMissionValues(list: PagedResultDto<MissionsDto>) {
    this.data = list;
    if (this.data.items.length > 0) {
      this.priority = this.data.items[0].priorityId;
    }else{
      this.priority = this.emptyGuid;
    }
    this.mapCurrentStatusEnumValues(this.data.items);
  }

  private getPageAttributeValues(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.PAGE_SIZE_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private setPageAttributes(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.valuesMaxResultCount = attribute.description.split(',').map(val => parseInt(val, 10));
  }

  private getMaxCountAttribute(): Observable<PagedResultDto<ConfigurationAttributeTypesDto>> {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.MAX_COUNT_ATTR,
    } as GetConfigurationTypeInput;

    return this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        }),
      );
  }

  private setMaxCountAttribute(res: PagedResultDto<ConfigurationAttributeTypesDto>) {
    let attribute = res.items.find(_ => true);
    this.MaxResultCount = parseInt(attribute.description);
  }

  toggler = (column: string, checked: boolean) => (this.showedColumns[column] = checked);

  findCommonActions(rows: MissionsDto[]): ActionOrderConfiguredDto[] {
    let rowsCopied = [...rows];

    // Get actions from the first row
    const firstRowActions = rowsCopied[0].actions.map(action => action.code);

    if (rowsCopied[0].firstAction?.code) {
      firstRowActions.push(rowsCopied[0].firstAction.code);
    }
    if (rowsCopied[0].firstSecondaryAction?.code) {
      firstRowActions.push(rowsCopied[0].firstSecondaryAction.code);
    }

    // Find the intersection of actions across all rows
    const commonActions = Array.from(
      new Set(
        firstRowActions.filter(action =>
          rowsCopied.every(
            row =>
              row.actions.some(a => a.code === action) ||
              row.firstAction?.code === action ||
              row.firstSecondaryAction?.code === action,
          ),
        ),
      ),
    );

    const uniqueActionsMap: { [key: string]: ActionOrderConfiguredDto } = {};
    const actionsForStatus = this.getActionsForMission(rowsCopied[0].statusId);

    const actions = actionsForStatus.filter(r => commonActions.includes(r.code));

    if (
      commonActions.includes(rowsCopied[0].firstAction?.code) &&
      !actions.includes(rowsCopied[0].firstAction)
    ) {
      actions.push(rowsCopied[0].firstAction);
    }

    actions.forEach(action => {
      uniqueActionsMap[action.code] = action;
    });

    return Object.values(uniqueActionsMap)
      .map(r => {
        const acData = this.selectedMissions.map(l =>
          this.getActionData(r.code, l, r.description, this.selectedMissions.length.toString()),
        );

        r.bulkData = acData;
        return r;
      })
      .sort((a, b) => a.order - b.order);
  }

  buildPriorityRules() {
    this.priorities.items.forEach(pr => {
      var newRule: ColumnColoringRules = {
        columnValue: pr.id,
        softValue: pr.description,
        valueClass: 'badge',
        valueStyle: {
          'background-color': this.getBackgroundColor(pr.color),
          color: pr.color,
          'border-radius': '0.2rem',
        },
      };
      this.prioritiesRules.push(newRule);
    });

    var defaultRule: ColumnColoringRules = {
      columnValue: 'default',
      softValue: 'Undefined',
      valueClass: 'badge',
      valueStyle: {
        'background-color': 'rgba(51, 51, 51, 0.1)',
        color: '#333',
        'border-radius': '0.2rem',
      },
    };
    this.prioritiesRules.push(defaultRule);
  }

  getBackgroundColor(mainColor) {
    var rgb = this.hexToRgb(mainColor);
    return `rgba(${rgb.r}, ${rgb.g}, ${rgb.b}, 0.1)`;
  }

  hexToRgb(hex) {
    var result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
    return result
      ? {
          r: parseInt(result[1], 16),
          g: parseInt(result[2], 16),
          b: parseInt(result[3], 16),
        }
      : null;
  }

  showAssigned(missionId: string): void {
    var missionAssignments: AssignedDto[] = [];

    this.service.getMissionAssigned(missionId).subscribe(res => {
      res.forEach(element => {
        if (missionAssignments.findIndex(ma => ma.role == element.role) == -1)
          missionAssignments.push(element);
      });

      const dialogRef = this.dialogService.open(MissionAssignmentsModalComponent, {
        disableClose: true,
        panelClass: 'modal-base',
        width: '900px',
        data: {
          missionAssignments,
          title: '',
          actions: {},
        },
      });
    });
  }

  getUsernameUser(id: string): string {
    if (id && id !== this.emptyGuid) {
      return this.qaqcUsers?.items?.find(x => x.id === id)?.userName ?? '-';
    }
    return '-';
  }

  onFiltersApplied(updatedFilters: FilterConfig) {
    if (this.selectedMissions?.length < 1) {
      this.firstRow = null;
      this.selectedActions = new Array();
      this.selectedMissions = new Array();
    }

    updatedFilters.conditions.forEach(updatedCondition => {
      const originalCondition = this.filterConfig.conditions.find(
        c => c.column === updatedCondition.column,
      );
      if (originalCondition) {
        originalCondition.existingValues = updatedCondition.existingValues;

        this.missionGridFilters[updatedCondition.column] = updatedCondition.existingValues;
      }
    });

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  removeFilter(column: string) {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => {
        if (condition.column === column) {
          return { ...condition, existingValues: [] };
        }
        return condition;
      }),
    };

    // Clear the specific filter
    this.missionGridFilters[column as keyof MissionsGridFilters] = [];

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  removeAllFilters() {
    this.filterConfig = {
      ...this.filterConfig,
      conditions: this.filterConfig.conditions.map(condition => ({
        ...condition,
        existingValues: [],
      })),
    };

    // Reset filters
    for (let condition of this.filterConfig.conditions) {
      this.missionGridFilters[condition.column as keyof MissionsGridFilters] = [];
    }

    this.paginationSortingAndGlobalSearch.skipCount = 0;
    this.pageNumber = 0;

    this.callApiWithFilters();
  }

  callApiWithFilters() {
    this.spinner.show();
    this.getMissionValues(this.portfolioId).subscribe({
      next: (missionValues: PagedResultDto<MissionsDto>) => {
        this.setMissionValues(missionValues);
        this.spinner.hide();
      },
      error: error => {
        console.log(error);
        this.spinner.hide();
      },
    });
  }

  getFilterDisplayValue(condition: FilterCondition): string {
    return condition.existingValues.map(value => this.getDescription(condition, value)).join(', ');
  }

  getDescription(condition: FilterCondition, value: string): string {
    // Return the description of the option if it's a dropdown
    if (condition.type === FilterType.Dropdown) {
      const option = condition.options?.find(o => o.id === value);
      return option ? option.description : value;
    }

    // Return the value itself for other types
    return value;
  }

  private updateMissionStatusOptions(): void {
    const condition = this.filterConfig.conditions.find(c => c.column === 'missionStatusId');
    const conditionNoStatus = this.allMissionFilters.conditions.find(
      c => c.column === 'noMissionStatusId',
    );

    if (condition) {
      this.statusMissionOrderService
        .getList({ maxResultCount: 1000, sorting: 'order' })
        .subscribe(res => {
          condition.options = res.items.map(status => {
            return { id: status.statusEnum.toString(), description: status.statusDescription };
          });

          if (conditionNoStatus) conditionNoStatus.options = condition.options;
        });
    }
  }

  onSort(sortInfo: any) {
    if (sortInfo.sorts && sortInfo.sorts.length > 0) {
      const sort = sortInfo.sorts[0];
      const sortingCriteria = `${sort.prop} ${sort.dir}`;

      this.paginationSortingAndGlobalSearch.sorting = sortingCriteria;

      this.callApiWithFilters();
    }
  }

  setPage(pageInfo: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: pageInfo.offset * pageInfo.pageSize,
      maxResultCount: pageInfo.pageSize,
    };

    this.callApiWithFilters();
  }

  onPaginationChange(event: any) {
    this.paginationSortingAndGlobalSearch = {
      ...this.paginationSortingAndGlobalSearch,
      skipCount: 0,
      maxResultCount: event,
    };

    this.callApiWithFilters();
  }

  handleAssignedSelect(value: assignedFilterEnum) {
    switch (value) {
      case assignedFilterEnum.MyMission:
        this.statusAndAssignedPermissions.assignedToMe = true;
        this.statusAndAssignedPermissions.inTheTeam = false;
        this.statusAndAssignedPermissions.allRecords = false;
        break;
      case assignedFilterEnum.MyTeam:
        this.statusAndAssignedPermissions.inTheTeam = true;
        this.statusAndAssignedPermissions.allRecords = false;
        this.statusAndAssignedPermissions.assignedToMe = false;
        break;
      case assignedFilterEnum.All:
        this.statusAndAssignedPermissions.allRecords = true;
        this.statusAndAssignedPermissions.assignedToMe = false;
        this.statusAndAssignedPermissions.inTheTeam = false;
        break;
    }

    this.callApiWithFilters();
  }

  isArrayOfString(arr: any[]): arr is string[] {
    return arr.every(element => typeof element === 'string');
  }
}
