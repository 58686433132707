import { Component, Input, OnChanges, OnInit } from '@angular/core';

interface ChipType {
  backgroundColor: string;
  color: string;
}

const CHIP_TYPES: { [key: string]: ChipType } = {
  grayChip: {
    backgroundColor: '#EBEBEB',
    color: '#0000008E',
  },
  orangeChip: {
    backgroundColor: 'rgba(237, 108, 2, 0.04)',
    color: '#ED6C02',
  },
  pinkChip: {
    backgroundColor: '#FCE4EC',
    color: '#E91E63',
  },
  purpleChip: {
    backgroundColor: '#9C27B014',
    color: '#9C27B0',
  },
  blueChip: {
    backgroundColor: '#00A0DF',
    color: '#FFFFFF',
  },
  greenChip: {
    backgroundColor: 'rgba(46, 125, 50, 0.08)',
    color: '#2E7D32',
  },
};

@Component({
  selector: 'app-fg-chip',
  templateUrl: './fg-chip.component.html',
  styleUrls: ['./fg-chip.component.scss'],
})
export class FgChipComponent implements OnInit, OnChanges {
  @Input() type: string;
  @Input() text: string;

  chipType: ChipType;

  ngOnInit() {
    this.updateChipType();
  }

  ngOnChanges() {
    this.updateChipType();
  }

  updateChipType() {
    this.chipType = CHIP_TYPES[this.type] || CHIP_TYPES['grayChip'];
  }
}
