import { Injectable } from '@angular/core';
import { Rest, RestService } from '@abp/ng.core';
import {
  CaptureInfoDto,
  CaptureWithDeliverablesDto,
} from '../pilot-sourcing/models/capture-with-deliverables-dto';
import { OrderRequestModel } from '../pilot-sourcing/models/order-request-model';
import {
  FinancialDataDto,
  OrderResumeDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { OrderRequestDeliverableDTO } from '../components/orders/dto/order-request-deliverable.dto';
import {
  AddressesDto,
  LocationWithAddressDto,
  MissionDeliverablesDto,
} from '../../../../missions-service/src/lib/proxy/missions-service/basics';
import { MissionFlowDto } from '../pilot-sourcing/models/mission-flow-dto';
import { MissionDetailsForm } from '../pilot-sourcing/components/order-request/models/mission-details-form.model';
import { LocationForm } from '../pilot-sourcing/components/order-request/models/location-form.model';
import { ActionOrdered } from '../pilot-sourcing/models/action-ordered-dto';
import { DeliverableAspectsFormModel } from '../pilot-sourcing/components/order-request/models/deliverable-aspects-form.model';
import { DeliverableAspectsDto } from '../pilot-sourcing/models/deliverable-aspects-dto';
import { AttachmentsNotesFormModel } from '../pilot-sourcing/components/order-request/models/attachments-notes-form-model';
import { Observable, forkJoin, map, tap } from 'rxjs';
import { IdentityRoleService } from '@volo/abp.ng.identity/proxy';
import { CustomerRequestedDataDto } from '../pilot-sourcing/models/customer-requested-data-dto';
import { OrderDetailsDto } from '../pilot-sourcing/models/order-details-dto';

@Injectable({
  providedIn: 'root',
})
export class MissionFlowService {
  apiName = 'missionsService';
  coreServiceApi = 'CoreService';

  constructor(private restService: RestService, private rolesService: IdentityRoleService) {}

  getMissionData = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionFlowDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/mission-data/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getFinancialsData = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, FinancialDataDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/financials-data/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getCapturesWithDeliverables = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CaptureWithDeliverablesDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/captures-with-deliverables/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getByMissionAndDeliverableIdAsync = (
    missionId: string,
    deliverableId: string,
    orderDetailId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, CaptureInfoDto[]>(
      {
        method: 'GET',
        url: `/api/missions-service/capture/mission/${missionId}/deliverable/${deliverableId}/orderDetail/${orderDetailId}`,
      },
      { apiName: this.apiName, ...config }
    );

  getOrderRequestData = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, OrderRequestModel>(
      {
        method: 'GET',
        url: `/api/missions-service/mission/order-request/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

  addDeliverableToMission = (
    deliverableRequestDto: OrderRequestDeliverableDTO,
    missionId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, MissionDeliverablesDto>(
      {
        method: 'POST',
        url: `/api/missions-service/mission-deliverable/add-deliverable-to-mission/${missionId}`,
        body: deliverableRequestDto,
      },
      { apiName: this.apiName, ...config }
    );

  updateDeliverablesToMission = (
    missionId: string,
    deliverables: OrderRequestDeliverableDTO[],
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, OrderDetailsDto[]>(
      {
        method: 'POST',
        url: `/api/missions-service/mission-deliverable/update-deliverables-to-mission/${missionId}`,
        body: deliverables,
      },
      { apiName: this.apiName, ...config }
    );

  deleteDeliverableToMission = (
    missionId: string,
    deliverable: OrderRequestDeliverableDTO,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, OrderDetailsDto>(
      {
        method: 'POST',
        url: `/api/missions-service/mission-deliverable/delete-deliverable-to-mission/${missionId}`,
        body: deliverable,
      },
      { apiName: this.apiName, ...config }
    );

  updateMissionDetails = (missionDetailsForm: MissionDetailsForm, config?: Partial<Rest.Config>) =>
    this.restService.request<any, MissionDeliverablesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/update-mission-details',
        body: missionDetailsForm,
      },
      { apiName: this.apiName, ...config }
    );

  updateAddressAndLocation = (locationForm: LocationForm, config?: Partial<Rest.Config>) =>
    this.restService.request<any, AddressesDto>(
      {
        method: 'POST',
        url: '/api/missions-service/address/update-address-and-location',
        body: locationForm,
      },
      { apiName: this.apiName, ...config }
    );
  getOrderResume = (
    missionId: string,
    config?: Partial<Rest.Config>
  ): Observable<OrderResumeDto> => {
    const missionOrderRequest = this.restService.request<any, OrderResumeDto>(
      {
        method: 'GET',
        url: `/api/missions-service/mission-orderaf/${missionId}`,
      },
      { apiName: this.apiName, ...config }
    );

    const rolesRequest = this.rolesService.getList({ maxResultCount: 100 });

    return forkJoin([missionOrderRequest, rolesRequest]).pipe(
      map(([orderResume, rolesResponse]) => {
        orderResume.roles = rolesResponse.items;
        return orderResume;
      })
    );
  };

  getActionsPermissions = (missionStatusCode: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrdered[]>(
      {
        method: 'GET',
        url: `/api/core-service/action-orders/actions-permissions/${missionStatusCode}`,
      },
      { apiName: this.coreServiceApi, ...config }
    );

  updateMissionDeliverableAspects = (
    updatedDeliverableAspects: DeliverableAspectsFormModel,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, DeliverableAspectsDto>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/update-mission-deliverable-aspects',
        body: updatedDeliverableAspects,
      },
      { apiName: this.apiName, ...config }
    );

  updateNotesAndAttachment = (
    updatedNotesAndAttachment: AttachmentsNotesFormModel,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, AttachmentsNotesFormModel>(
      {
        method: 'POST',
        url: '/api/missions-service/mission/update-notes-and-attachments',
        body: updatedNotesAndAttachment,
      },
      { apiName: this.apiName, ...config }
    );

  getFirstAction = (statusCode: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, ActionOrdered>(
      {
        method: 'GET',
        url: `/api/core-service/action-orders/mission-primary-action/${statusCode}`,
      },
      { apiName: this.coreServiceApi, ...config }
    );

  updateCustomerRequestedData = (input: CustomerRequestedDataDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, CustomerRequestedDataDto>(
      {
        method: 'PUT',
        url: '/api/missions-service/mission/update-customer-requested-data',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  updateLocationAirspaceClassification = (
    updatedLocation: LocationForm,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, CustomerRequestedDataDto>(
      {
        method: 'POST',
        url: '/api/missions-service/location/update-location-airspace-classification',
        body: {
          locationId: updatedLocation.locationId,
          airSpaceClassificationId: updatedLocation.airspaceClassificationId,
          airSpaceClassificationDescription: updatedLocation.airspaceClassification,
        },
      },
      { apiName: this.apiName, ...config }
    );

  updateLocationReference = (
    missionId: string,
    locationId: string,
    config?: Partial<Rest.Config>
  ) =>
    this.restService.request<any, CustomerRequestedDataDto>(
      {
        method: 'POST',
        url: '/api/missions-service/location/update-location-reference',
        body: {
          missionId: missionId,
          locationId: locationId,
        },
      },
      { apiName: this.apiName, ...config }
    );

  createLocation = (input: LocationWithAddressDto, config?: Partial<Rest.Config>) =>
    this.restService.request<any, string>(
      {
        method: 'POST',
        url: '/api/missions-service/location/create-location-with-address',
        body: input,
      },
      { apiName: this.apiName, ...config }
    );

  IsAnyDeliverableNotAccepted = (missionId: string, config?: Partial<Rest.Config>) =>
    this.restService.request<any, boolean>({
      method: 'POST',
      url: `/api/missions-service/mission/IsAnyDeliverableNotAccepted/${missionId}`,
    },
    {apiName: this.apiName, ...config  } 
  );
}
