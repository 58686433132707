<mat-sidenav-container [hasBackdrop]="false">
  <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
    <app-grid-filters
      [filterConfig]="filterConfig"
      [filterTitle]="'Filter Activity'"
      (applyFilters)="onFiltersApplied($event)"
      (closeSidenav)="drawer.close()"
    >
    </app-grid-filters>
  </mat-sidenav>

  <mat-sidenav-content>
    <div class="filter-section">
      <h3>{{ 'missionsService::TimelineTitle' | abpLocalization }}</h3>

      <div class="d-flex align-items-center">
        <span class="fg-subtitle me-2">
          {{ 'missionsService::ShowMeActivity' | abpLocalization }}
        </span>
        <div class="d-flex align-items-center">
          <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
            <ng-container *ngIf="condition.existingValues.length">
              <app-filter-chip
                class="me-2"
                [label]="condition.columnDisplayName"
                [value]="getFilterDisplayValue(condition)"
                (removeEvent)="removeFilter(condition.column)"
              ></app-filter-chip>
            </ng-container>
          </ng-container>
        </div>

        <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
          add_circle
        </span>

        <span
          class="d-inline-block main-color fw-medium cursor-pointer"
          (click)="removeAllFilters()"
          >{{ 'missionsService::ClearFilterTimeline' | abpLocalization }}</span
        >
      </div>
    </div>

    <div *ngIf="logs.length > 0" class="card border rounded m-0">
      <div class="card-body">
        <div class="row">
          <div class="col-3 g-0 vertical-divider">
            <h4>{{ 'missionsService::Activity' | abpLocalization }}</h4>
            <div class="col-12 pe-2">
              <ng-scrollbar visibility="always" style="height: 60vh">
                <div
                  class="d-flex mb-1 p-2 align-items-start align-self-stretch rounded pointer"
                  (click)="showDetails(log)"
                  *ngFor="let log of logs; let isLast = last"
                  [ngClass]="{ 'selected-item': log.id == logSelected?.id }"
                >
                  <div
                    class="icon-container justify-content-center d-flex align-items-center rounded-circle flex-shrink-0"
                    [ngClass]="{
                      'flow-container': !log.captureId && !log.deliverableId,
                      'change-container': log.captureId || log.deliverableId,
                    }"
                  >
                    <span
                      class="material-symbols-outlined change-action"
                      *ngIf="
                        !log.captureId &&
                        !log.deliverableId &&
                        log.displayEventName != 'NoteDeleted'
                      "
                      >flowsheet</span
                    >
                    <span
                      class="material-symbols-outlined flow-action"
                      *ngIf="
                        log.captureId || log.deliverableId || log.displayEventName == 'NoteDeleted'
                      "
                      >heat_pump_balance</span
                    >
                  </div>
                  <div class="gap"></div>
                  <div class="d-flex w-100">
                    <div class="right-content">
                      <div class="title">
                        {{ 'missionsService::' + log.displayEventName | abpLocalization }}
                      </div>
                      <div class="content">
                        <span class="soft d-inline">{{ log.date + 'Z' | date: 'H:mm' }}</span>
                        &nbsp;
                        <span class="soft d-inline"> | </span>
                        &nbsp;
                        <span class="soft d-inline">{{ log.date + 'Z' | date: 'MM/dd/yyyy' }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-scrollbar>
            </div>
          </div>
          <div class="col-9">
            <h4 *ngIf="logSelected" class="mb-2">
              {{ 'missionsService::' + logSelected.displayEventName | abpLocalization }}
              <span class="contents">
                <span class="d-inline">{{ logSelected.date + 'Z' | date: 'H:mm' }}</span>

                <span class="d-inline"> | </span>

                <span class="d-inline">{{ logSelected.date + 'Z' | date: 'MM/dd/yyyy' }}</span>
              </span>
            </h4>
            <p class="mb-4" *ngIf="logSelected">
              <span class="heavier-title"
                >{{ 'missionsService::CompletedBy' | abpLocalization }}:</span
              >
              <span class="ms-1 text"> {{ logSelected.eventUserName || '-' }} </span>
            </p>
            <div class="col-12 mt-3">
              <ng-container *ngFor="let item of logSelectedDetails | keyvalue">
                <div class="status">
                  <div class="softer-title">
                    {{ 'missionsService::' + item.key | abpLocalization }}
                  </div>
                  <div class="text">
                    {{ 'missionsService::' + (item.value || '-') | abpLocalization }}
                  </div>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center" *ngIf="logs.length === 0">
      <h4>{{ 'missionsService::NoActivityFound' | abpLocalization }}</h4>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>
