<div class="d-flex align-items-center flex-wrap pt-2 pb2">
  <abp-button
    *abpPermission="fileCreatePermission"
    iconClass="fa fa-file me-2"
    buttonClass="btn btn-secondary btn-sm"
    class="me-2"
    (click)="openProgressViewer()"
  >
  {{ 'FileManagement::UploadStatus' | abpLocalization }}
  </abp-button>

  <abp-button
    *abpPermission="fileCreatePermission"
    iconClass="fa fa-upload me-2"
    buttonClass="btn btn-primary btn-sm"
    (click)="openModal()"
    [disabled]="!canUpload()"
  >
    {{ 'FileManagement::UploadFilesToThisFolder' | abpLocalization }}
  </abp-button>
</div>

<abp-modal
  [options]="{ size: 'lg', centered: true }"
  [(visible)]="isModalOpen"
  (disappear)="OnModalClose()"
>
  <ng-template #abpBody>
    <h3 class="text-center mt-3">You are uploading to the folder {{ folderName }}</h3>
    <div class="d-flex justify-content-center align-items-center gap-2">
      <p class="p-0 m-0 fs-14">
        Selected {{ _parallelFileUploadService.LoadedFiles.length }} files
      </p>
      <label class="fs-14 browse-btn" for="fileDropRef">Browse for files</label>
      <label class="fs-14 browse-btn" for="dirDropRef">Select directory</label>
    </div>

    <div class="dropzone">
      <input style="visibility: hidden;"
          [accept]="allowedFileExtensions"
          type="file"
          #fileDropRef
          id="fileDropRef"
          multiple
          (change)="InputChangeHandle($event)"
        />
      <input style="visibility: hidden;"
          [accept]="allowedFileExtensions"
          type="file"
          #dirDropRef
          id="dirDropRef"
          multiple
          (change)="InputChangeHandle($event)"
          webkitDirectory
        />

      <div class="d-flex flex-wrap justify-content-around">
        <div
          class="d-flex flex-column gap-1 file-preview-container"
          *ngFor="let file of _parallelFileUploadService.LoadedFiles"
        >
          <button class="file-preview-remove-btn" (click)="removeFile(file)">
            <span
              class="material-symbols-outlined d-flex justify-content-center align-items-center"
            >
              close
            </span>
          </button>
          <div class="d-flex justify-content-center align-items-center file-preview">
            <div class="icon-container d-flex justify-content-center align-items-center">
              <span class="material-symbols-outlined"> draft </span>
            </div>
          </div>
          <p>{{ file.name }}</p>
        </div>
      </div>
    </div>
    <div class="mb-3 d-flex align-items-center">
      <button mat-flat-button type="button" class="outline-button me-2" (click)="closeModal()" [disabled]="isBusy">
        Cancel
      </button>
      <button mat-flat-button type="button" class="common-button" (click)="uploadFiles()" [disabled]="isBusy || _parallelFileUploadService.LoadedFiles.length == 0">
        Upload
      </button>
      <mat-spinner class="ms-3" *ngIf="isBusy" [diameter]="20"></mat-spinner>
    </div>
  </ng-template>
</abp-modal>

