<ng-container *ngIf="missionData">
  <div class="d-flex align-items-center mb-3">
    <app-fg-breadcrumb
      *ngIf="!displayFromPortfolio && !displayFromOrders"
      [breadcrumbs]="[
        { text: 'Orders', link: '/orders' },
        { text: 'Mission', link: '/orders' },
        { text: missionData.missionName },
      ]"
    ></app-fg-breadcrumb>

    <ol class="breadcrumb mb-0 ps-0" *ngIf="displayFromPortfolio || displayFromOrders">
      <li class="breadcrumb-item ng-star-inserted" (click)="handleOnBack()">
        <a
          href="javascript:void(0)"
          (click)="handleOnBack()"
          class="d-inline-flex align-items-center ng-star-inserted"
        >
          <mat-icon matTooltip="Go Back" matTooltipPosition="left" matTooltipClass="red-tooltip"
            >keyboard_backspace</mat-icon
          >
          <span>{{ displayFromPortfolio ? 'Portfolio' : 'Orders' }}</span>
        </a>
      </li>
      <li class="breadcrumb-item ng-star-inserted mt-1">
        <a href="javascript:void(0)" class="ng-star-inserted"> Mission </a>
      </li>
      <li class="breadcrumb-item breadcrumb-last ng-star-inserted mt-1">
        {{ missionData?.missionName }}
      </li>
    </ol>

    <div class="d-flex">
      <app-fg-chip
        class="ml-4 no-shrink"
        type="purpleChip"
        text="{{ missionStatus[missionData.statusEnum].value }}"
      ></app-fg-chip>
    </div>
    <div class="d-flex justify-content-end ms-auto">
      <!-- this button is hidden due to ticket 19372. -->
      <button
        [disableRipple]="true"
        (click)="goToPortfolioPoc()"
        [disableRipple]="true"
        mat-stroked-button
        type="button"
        *ngIf="false"
      >
        <mat-icon aria-hidden="false" aria-label="All Staff" fontIcon="bar_chart"></mat-icon>
        Charts
      </button>
      <button
        [disableRipple]="true"
        (click)="showAssigned()"
        [disableRipple]="true"
        mat-stroked-button
        type="button"
      >
        <mat-icon aria-hidden="false" aria-label="All Staff" fontIcon="groups"></mat-icon>
        {{ 'missionsService::AssignedHistory' | abpLocalization }}
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="d-flex align-items-center">
      <app-mission-info-badge
        [icon]="'tag'"
        [subtitle]="'Job ID:'"
        [description]="missionData.jobId"
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'person_raised_hand'"
        [subtitle]="'Requesting Customer:'"
        [description]="extendedMissionDto?.customerName || missionData?.customerName"
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'calendar_today'"
        [subtitle]="'Capture Date:'"
        [description]="
          extendedMissionDto?.missionCaptureDetails?.fixedCurrentCaptureDate ||
          (extendedMissionDto?.missionCaptureDetails?.currentCaptureDate + 'Z' | date: 'MM/dd/yyyy')
        "
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'schedule'"
        [subtitle]="'Capture Time:'"
        [description]="
          (extendedMissionDto?.missionCaptureDetails?.fixedCurrentCaptureTime ||
            extendedMissionDto?.missionCaptureDetails?.currentCaptureTime ||
            '--:--') +
          ' ' +
          (missionData?.timeZone ? missionData?.timeZone : '')
        "
      ></app-mission-info-badge>
    </div>

    <div
      *ngIf="actions.length > 0"
      class="d-flex align-items-center justify-content-end flg-grid-table-actions"
    >
      <ng-container *ngFor="let action of iconActions; let i = index">
        <div class="secondary-actions">
          <button
            *ngIf="action.isActionFramework"
            type="button"
            class="btn btn-link p-0"
            [matTooltip]="action.description"
          >
            <ng-container>
              <lib-management-actions
                *ngIf="action.code != 'add_note'"
                class="af-missions"
                gridName="Missions"
                [validateExecuteAction]="checkIfThrowsConfirmationAction(action.code)"
                [rowData]="getActionData(action.code)"
                [uniqueAction]="action.code"
                (parentEvent)="reloadAction()"
              >
              </lib-management-actions>

              <lib-management-actions
                *ngIf="action.code == 'add_note' && !reloadingNotes && missionId"
                class="af-missions"
                gridName="Missions"
                [rowData]="getActionData(action.code)"
                [validateExecuteAction]="checkIfThrowsConfirmationAction(action.code)"
                modalClass="notes-modal"
                [uniqueAction]="action.code"
                [validateExecuteAction]="checkIfThrowsConfirmationAction(action.code)"
                loadingHtml='<span class="material-symbols-rounded mr-8px">add_notes</span>'
                (parentEvent)="reloadNotes()"
                [additionalData]="users"
              >
              </lib-management-actions>
            </ng-container>
          </button>

          <ng-container *ngIf="action.code === 'copy_mission'">
            <span
              class="material-symbols-rounded mr-8px cursor-pointer"
              (click)="handleCopyAction()"
              [matTooltip]="action.description"
            >
              content_copy
            </span>
          </ng-container>
        </div>
      </ng-container>

      <button
        mat-button
        [disableRipple]="true"
        [matMenuTriggerFor]="menu"
        aria-label="Grid Actions"
        class="single-action single-action--wide"
        *ngIf="listActions.length > 0"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu" [class]="'secondary-actions mat-elevation-z2'" #actionsMenu>
        <ng-container *ngFor="let action of listActions; let i = index">
          <button
            *ngIf="action.isActionFramework"
            mat-menu-item
            (click)="handleAction($event)"
            class="secondary-actions secondary-actions__action secondary-actions--no-icon"
          >
            <div class="d-flex align-items-center">
              <lib-management-actions
                class="af-missions pe-none"
                gridName="Missions"
                [rowData]="getActionData(action.code)"
                [validateExecuteAction]="checkIfThrowsConfirmationAction(action.code)"
                [uniqueAction]="action.code"
                [additionalData]="users"
              >
              </lib-management-actions>
              <span class="secondary-actions secondary-actions__description pe-none">
                {{ action.description }}
              </span>
            </div>
          </button>
          <button
            *ngIf="action.code === 'copy_mission'"
            mat-menu-item
            (click)="handleCopyAction()"
            class="secondary-actions secondary-actions__action"
          >
            <span class="secondary-actions secondary-actions__description pe-none">
              {{ action.description }}
            </span>
          </button>
          <button
            *ngIf="action.code === 'no_code'"
            mat-menu-item
            (click)="handleAssignPilotAction(row)"
            class="secondary-actions secondary-actions__action"
          >
            <span class="secondary-actions secondary-actions__description pe-none">
              Assign Pilot
            </span>
          </button>
        </ng-container>
        <button
          mat-menu-item
          (click)="copyMissionLink()"
          class="secondary-actions secondary-actions__action"
        >
          <span class="secondary-actions secondary-actions__description pe-none"
            >Copy Mission URL</span
          >
        </button>
      </mat-menu>
    </div>
  </div>
  <div *ngIf="actions.length > 0" class="d-flex align-items-center mb-4">
    <div class="d-flex align-items-center" *ngIf="showNextStepHelper()">
      <span class="fg-next-step">Next Step:</span>
    </div>
    <div class="d-flex align-items-center" *ngIf="firstAction && firstAction.isActionFramework">
      <div>
        <ng-container>
          <lib-management-actions
            class="m-2 af-button"
            gridName="Missions"
            [rowData]="getActionData(firstAction.code)"
            [validateExecuteAction]="checkIfThrowsConfirmationAction(firstAction.code)"
            [uniqueAction]="firstAction.code"
            (parentEvent)="reloadAction()"
            [additionalData]="firstAction.code === 'add_note' ? users : {}"
            [modalClass]="firstAction.code === 'add_note' ? 'notes-modal' : 'modal'"
          >
            <button type="button" class="btn btn-primary mx-8px">
              {{ firstAction.description }}
            </button>
            <span helperText *ngIf="firstAction" class="fg-description-text ml-3">
              {{ firstAction?.helperText }}
            </span>
          </lib-management-actions>
        </ng-container>
      </div>
    </div>
    <div class="d-flex align-items-center" *ngIf="firstAction && !firstAction.isActionFramework">
      <ng-container>
        <span helperText class="fg-description-text ml-3">{{ firstAction?.helperText }}</span>
      </ng-container>
    </div>
  </div>

  <div>
    <mat-tab-group
      #tabGroup
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="{{ 'missionsService::OrderRequest' | abpLocalization }}">
        <ng-template matTabContent>
          <app-order-request
            [missionId]="missionId"
            [missionStatusId]="missionData.missionStatusId"
            [missionStatusCode]="missionData.missionStatusCode"
            (newUpdate)="newUpdateFromOrderRequest($event)"
          >
          </app-order-request>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::Deliverables' | abpLocalization }}">
        <ng-template matTabContent>
          <app-deliverables [missionId]="missionId" [missionStatusId]="missionData.missionStatusId">
          </app-deliverables>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="displayCaptures" label="{{ 'missionsService::Captures' | abpLocalization }}">
        <ng-template matTabContent>
          <div *ngIf="!displayPilotSourcingTable">
            <app-captures
              [missionId]="missionId"
              [mission]="missionData"
              [missionStatusId]="missionStatusId"
              [reloadActions]="reloadCapturesActions"
              [missionName]="missionData?.missionName || ''"
              (captureIdClicked)="handleCaptureId($event)"
            ></app-captures>
          </div>
          <div *ngIf="displayPilotSourcingTable">
            <app-pilot-sourcing-sourcing
              [missionId]="missionId"
              [missionStatusId]="missionStatusId"
              [captureId]="selectedCaptureId"
              [customerId]="customerId"
              [capture]="selectedCapture"
              [missionDetails]="true"
              [missionName]="missionData.missionName"
              (captureBreadcrumbClicked)="handleCaptureBreadcrumb()"
            ></app-pilot-sourcing-sourcing>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::Financials' | abpLocalization }}">
        <ng-template matTabContent>
          <app-financials [missionId]="missionId" [mission]="missionData"> </app-financials>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::ClientInformation' | abpLocalization }}">
        <ng-template matTabContent>
          <app-client-information
            [missionId]="missionId"
            [missionStatusId]="missionData.missionStatusId"
            [customerId]="missionData.customerId"
          ></app-client-information>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::NotesTab' | abpLocalization }}">
        <ng-template matTabContent>
          <app-mission-notes
            *ngIf="noteTabActive"
            [missionId]="missionId"
            (noteDeleted)="reloadNotes()"
          ></app-mission-notes>
        </ng-template>
      </mat-tab>
      <!-- <mat-tab label="Files / Data">
        <app-files-data></app-files-data>
      </mat-tab>-->
      <mat-tab label="{{ 'missionsService::Metrics' | abpLocalization }}">
        <ng-template matTabContent>
          <app-metrics
            [missionId]="missionId"
            [mission]="missionData"
            [statusName]="missionStatus[missionData.statusEnum].value"
          ></app-metrics>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::TimelineTab' | abpLocalization }}">
        <ng-template matTabContent>
          <app-timeline *ngIf="timelineTabActive" [missionId]="missionId"></app-timeline>
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  </div>
</ng-container>
