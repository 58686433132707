<ngx-spinner
  bdColor="rgba(245,245,245,0.7)"
  size="default"
  color="#bc5a2e"
  type="ball-square-clockwise-spin"
  [fullScreen]="false"
  [zIndex]="111"
>
  <p style="color: #bc5a2e">{{ 'missionsService::LoadingSourcePilots' | abpLocalization }}</p>
</ngx-spinner>
<mat-sidenav-container class="filters-container bg-white mt-2">
  <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
    <app-grid-filters
      [filterTitle]="'Filter Source Pilots'"
      [filterConfig]="filterConfig"
      (applyFilters)="onFiltersApplied($event)"
      (closeSidenav)="drawer.close()"
    >
    </app-grid-filters>
  </mat-sidenav>

  <mat-sidenav-content class="m-2">
    <h1>
      {{ 'pilotsService::PilotFinder' | abpLocalization }}
    </h1>

    <form [formGroup]="searchForm" (submit)="onGlobalSearch()">
      <mat-form-field class="w-100 global-search-input pb-0 mt-2">
        <mat-label>{{ 'missionsService::Search' | abpLocalization }}</mat-label>
        <input matInput placeholder="Search" formControlName="searchInput" />
        <mat-icon matSuffix class="cursor-pointer blue-color" (click)="onGlobalSearch()"
          >search</mat-icon
        >
      </mat-form-field>
    </form>

    <div class="card">
      <div class="card-body d-flex justify-content-between">
        <div class="d-flex align-items-center py-0">
          <span class="fg-subtitle me-2">{{
            'missionsService::IWantToSourcePilotsWith' | abpLocalization
          }}</span>

          <div class="d-flex align-items-center">
            <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
              <ng-container *ngIf="condition.existingValues.length">
                <app-filter-chip
                  [label]="condition.columnDisplayName"
                  [value]="getFilterDisplayValue(condition)"
                  (removeEvent)="removeFilter(condition.column)"
                ></app-filter-chip>
                <span *ngIf="!isLast" class="comma-separator">, </span>
              </ng-container>
            </ng-container>
          </div>

          <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
            add_circle
          </span>

          <span
            class="d-inline-block main-color fw-medium cursor-pointer"
            (click)="removeAllFilters()"
            >{{ 'missionsService::ClearAll' | abpLocalization }}</span
          >
        </div>

        <div class="d-flex align-items-center">
          <mat-slide-toggle
            [(ngModel)]="pilotForSourcingGridExtraFilters.infoChecked"
            (click.stop)="onInfoCheckedTurn()"
            >{{ 'pilotsService::OnlyActive' | abpLocalization }}</mat-slide-toggle
          >
        </div>
      </div>
      <div class="w-100 global-search-input pb-0 mt-2">
        <mat-accordion class="mt-2">
          <mat-expansion-panel #matExpansionPanel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                <span class="site-address">
                  {{ 'pilotsService::FilterByLocationAndRadius' | abpLocalization }}
                </span>
              </mat-panel-title>
              <mat-panel-description> {{ currentLocationFilter }} </mat-panel-description>
            </mat-expansion-panel-header>
            <h4 class="mb-3">
              Add service area by searching for an address.
              <span class="highlight">You can add up to 1 area</span>
            </h4>
            <div class="row mt-2">
              <mat-autocomplete
                (optionSelected)="updateMap($event)"
                #auto="matAutocomplete"
                [displayWith]="formatOption"
              >
                <mat-option *ngFor="let option of options" [value]="option">
                  {{ option.description }}
                </mat-option>
              </mat-autocomplete>
            </div>
            <div id="row map">
              <flg-geofence
                (geofenceRemoved)="handleRemoveGeofence($event)"
                [maxCount]="1"
                [maxSize]="804670"
                [options]="{
                  mapTypeId: 'roadmap',
                }"
              ></flg-geofence>
            </div>

            <div class="d-flex justify-content-end">
              <button
                (click)="handleSaveChanges()"
                mat-flat-button
                class="common-button"
                type="button"
              >
                {{ 'pilotsService::Permission:Filter' | abpLocalization }}
              </button>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div
        *ngIf="this.selectedPilots?.length > 0"
        class="d-flex align-items-center mb-3 box mt-3"
        [class.sticky-header]="true"
      >
        <div>
          <span class="bold">{{ 'missionsService::BulkActions' | abpLocalization }} </span>
          <span>
            {{ 'missionsService::ItemsSelected' | abpLocalization: this.selectedPilots?.length }}
          </span>
        </div>
        <div class="d-flex ms-4">
          <button
            type="button"
            class="mat-mdc-tooltip-trigger secondary-actions secondary-actions__action align-items-center d-flex border-0 ms-2 ng-star-inserted"
            (click)="handleOnBulkAssign()"
          >
            <span class="secondary-actions secondary-actions__description pe-none">
              {{ 'pilotsService::AssignBadge' | abpLocalization }}
            </span>
          </button>
        </div>
        <div class="me-auto d-flex">
          <button
            type="button"
            class="mat-mdc-tooltip-trigger secondary-actions secondary-actions__action align-items-center d-flex border-0 ms-2 ng-star-inserted"
            (click)="handleOnBulkRevoke()"
          >
            <span class="secondary-actions secondary-actions__description pe-none">
              {{ 'pilotsService::RevokeBadge' | abpLocalization }}
            </span>
          </button>
        </div>
      </div>
    </div>

    <div class="card grid">
      <div class="card-body grid-body">
        <ngx-datatable
          [rows]="pilotsForSourcingData?.items"
          [offset]="pageNumber"
          [count]="pilotsForSourcingData?.totalCount"
          [list]="list"
          [limit]="clientMaxResultCount"
          [externalPaging]="true"
          class="flg-grid-table"
          [headerHeight]="60"
          [footerHeight]="55"
          [rowHeight]="50"
          (sort)="onSort($event)"
          (page)="setPage($event)"
          default
          #dataTable
          [selected]="selectedPilots"
          [selectionType]="selectionType.checkbox"
          [selectAllRowsOnPage]="true"
          (select)="onSelect($event)"
        >
          <ngx-datatable-column
            [frozenLeft]="true"
            [width]="50"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            name="Select"
            prop="select"
          >
            <ng-template
              ngx-datatable-header-template
              let-value="value"
              let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn"
            >
              <mat-checkbox [checked]="allRowsSelected" (change)="selectFn(!allRowsSelected)">
              </mat-checkbox>
            </ng-template>
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <mat-checkbox [checked]="isSelected" (change)="onCheckboxChangeFn($event)">
              </mat-checkbox>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'pilotsService::PilotName' | abpLocalization }}"
            prop="name"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.name }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::Prefered' | abpLocalization }}"
            prop="isPreferred"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <mat-icon color="primary" *ngIf="row.isPreferred">check_box</mat-icon>
              <mat-icon color="primary" *ngIf="!row.isPreferred">check_box_outline_blank</mat-icon>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PilotPhoneNumber' | abpLocalization }}"
            prop="phone"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.phone }}" matTooltipPosition="left">{{ row.phone }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::TimeZone' | abpLocalization }}"
            prop="phone"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.timeZone }}" matTooltipPosition="left">{{
                row.timeZone
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::Email' | abpLocalization }}"
            prop="descriptionUser"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.email }}" matTooltipPosition="left">{{ row.email }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::Country' | abpLocalization }}"
            prop="addressCountry"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.addressCountry }}" matTooltipPosition="left">{{
                row.addressCountry
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::State' | abpLocalization }}"
            prop="addressState"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.addressState }}" matTooltipPosition="left">{{
                row.addressState
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::City' | abpLocalization }}"
            prop="addressCity"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.addressCity }}" matTooltipPosition="left">{{
                row.addressCity
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PilotStreetAddress' | abpLocalization }}"
            prop="address"
            [minWidth]="200"
            [maxWidth]="300"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.address }}" matTooltipPosition="left">{{
                row.address
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::ZipCode' | abpLocalization }}"
            prop="addressZipCode"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.addressZipCode }}" matTooltipPosition="left">{{
                row.addressZipCode
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PilotJobCount' | abpLocalization }}"
            prop="jobCount"
            [minWidth]="130"
            [maxWidth]="200"
            [width]="130"
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>{{
              row.jobCount + row.missionCount
            }}</ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::Badges' | abpLocalization }}"
            prop="lastBadgeDescription"
            [minWidth]="150"
            [maxWidth]="250"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span class="badge-container">
                <app-grid-badge
                  *ngIf="row.lastBadgeId"
                  [badge]="{
                    text: getBadgeText(row),
                    color: '#0252ED',
                    background: '#0252ED1A',
                    id: row.lastBadgeId,
                  }"
                  [shortenText]="true"
                ></app-grid-badge>

                <div *ngIf="row.totalBadgesCount > 1" class="badge-plus-chip">
                  {{ row.totalBadgesCount - 1 }}+
                </div>
              </span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::Availability' | abpLocalization }}"
            prop="availabilityId"
            [minWidth]="150"
            [maxWidth]="250"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.availabilityDescription }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::PreferredContactStyle' | abpLocalization }}"
            prop="preferredContactStyle"
            [minWidth]="150"
            [maxWidth]="250"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.preferredContactStyle }}" [matTooltipShowDelay]="1000">
                {{ row.preferredContactStyle }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::SensorsType' | abpLocalization }}"
            prop="sensorsType"
            [minWidth]="150"
            [maxWidth]="250"
            [width]="200"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.sensorsType }}" [matTooltipShowDelay]="1000">
                {{ row.sensorsType }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'pilotsService::CreatedDate' | abpLocalization }}"
            prop="creationTime"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.creationTime | date: 'MM/dd/yyyy' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::Actions' | abpLocalization }}"
            [width]="250"
            [frozenRight]="true"
            [sortable]="false"
            [cellClass]="'fixed-column'"
          >
            <ng-template let-row="row" let-i="rowIndex" ngx-datatable-cell-template>
              <div class="flg-grid-table-actions">
                <div class="secondary-actions secondary-actions--alone">
                  <button
                    type="button"
                    class="btn p-0 single-action"
                    matTooltip="Pilot Information"
                    (click)="displayPilotProfile(row.id)"
                  >
                    <span class="material-symbols-rounded"> info </span>
                  </button>

                  <div
                    class="p-0 single-action"
                    *ngIf="row.email?.length > 0"
                    matTooltip="Mail Pilot"
                  >
                    <a href="mailto:{{ row.email }}" target="_blank" class="contact-method">
                      <span class="material-symbols-rounded">mail</span></a
                    >
                  </div>

                  <div
                    class="p-0 single-action"
                    *ngIf="row.phone?.length > 0"
                    matTooltip="Call Pilot"
                  >
                    <a class="contact-method" target="_blank" href="tel:{{ row.phone }}">
                      <span class="material-symbols-rounded">call</span>
                    </a>
                  </div>

                  <button
                    type="button"
                    class="btn p-0 single-action"
                    matTooltip="Sms pilot"
                    (click)="sendSms(row)"
                    *abpPermission="'General.Common.Actions.SMSSending'"
                  >
                    <span class="material-symbols-rounded"> sms </span>
                  </button>

                  <button
                    type="button"
                    class="btn p-0 single-action"
                    matTooltip="Assign Badge"
                    (click)="openAssignBadgeModal(row)"
                  >
                    <span class="material-symbols-rounded">bookmark</span>
                  </button>

                  <button
                    type="button"
                    class="btn p-0 single-action"
                    matTooltip="Revoke Badge"
                    (click)="openRevokeBadgeModal(row)"
                  >
                    <span class="material-symbols-rounded"> bookmark_remove </span>
                  </button>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-curPage="curPage"
            >
              <app-table-paginator
                [(maxResultCount)]="list.maxResultCount"
                [(clientMaxResultCount)]="clientMaxResultCount"
                [rowCount]="rowCount"
                [pageSize]="pageSize"
                [(pageNumber)]="pageNumber"
                [curPage]="curPage"
                [dataTable]="dataTable"
                (clientMaxResultCountChange)="onPaginationChange($event)"
                (beforePageSizeChange)="onBeforePageSizeChange()"
              >
              </app-table-paginator>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </div>
  </mat-sidenav-content>
</mat-sidenav-container>

<abp-modal [busy]="isModalBusy" [options]="{ size: 'lg' }" [(visible)]="isModalOpen">
  <ng-template #abpHeader>
    <h3>New Sms Notification - {{ pilotsForSMS[0].name }}</h3>
  </ng-template>

  <ng-template #abpBody>
    <form
      [formGroup]="notificationForm"
      id="notifications"
      (ngSubmit)="submitNotificationForm()"
      validateOnSubmit
    >
      <div class="mt-2 fade-in-top">
        <div class="mb-3">
          <label class="form-label" for="sms-notification-message"> Message * </label>

          <textarea
            id="sms-notification-message"
            formControlName="message"
            class="form-control"
            maxlength="320"
          ></textarea>
        </div>
      </div>
    </form>
  </ng-template>

  <ng-template #abpFooter>
    <button type="button" class="btn btn-secondary" abpClose>
      {{ 'AbpUi::Cancel' | abpLocalization }}
    </button>

    <abp-button iconClass="fa fa-check" buttonType="submit" formName="notifications">
      {{ 'Send' }}
    </abp-button>
  </ng-template>
</abp-modal>
