<div class="header d-flex justify-content-between align-items-center" *ngIf="!folderMissions">
  <div>
    <h3 class="title mb-0">
      <span (click)="goBack()" role="button">{{
        'missionsService::Deliverables' | abpLocalization
      }}</span>
    </h3>
  </div>

  <div class="d-flex align-items-center gap-2">
    <button
      mat-button
      type="button"
      class="plain-button d-flex justify-content-center align-items-center"
      (click)="downloadSelectedFiles()"
    >
      <mat-icon>download</mat-icon>
      Download Files
    </button>

    <button
      *ngIf="this.mission?.isRaptorMapCustomer"
      class="common-button"
      (click)="getFileDumpingSession()"
      mat-button
    >
      <mat-icon>publish</mat-icon>
      Upload Files (Raptor Maps)
      <i class="fa fa-circle-o-notch fa-spin" *ngIf="filesDumpingSessionInProgress"></i>
    </button>

    <button
      *ngIf="allowDeliverableAddModifyAction"
      [disabled]="!readyToAddItems"
      class="common-button"
      (click)="addDeliverable()"
      mat-button
    >
      <mat-icon>add</mat-icon>
      Add Packages/Products
    </button>
  </div>
</div>

<ngx-datatable
  class="flg-grid-table"
  *ngIf="!selectedDeliverable"
  [rows]="deliverablesFromCapture"
  [columns]="deliverableColumns"
  [headerHeight]="45"
  [footerHeight]="55"
  [rowHeight]="45"
  default
  [sorts]="[{ prop: 'deliverableName', dir: 'asc' }]"
>
  <ngx-datatable-column name="Deliverable" prop="deliverableName">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ row.deliverableName }}" [matTooltipShowDelay]="2000">{{
        row.deliverableName
      }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Quantity" prop="quantity">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ row.quantity }}" [matTooltipShowDelay]="2000">
        {{ row.quantity }}
      </span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Product" prop="product">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ row.productName }}" [matTooltipShowDelay]="2000">
        {{ row.productName }}
      </span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Package" prop="package">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ row.packageName }}" [matTooltipShowDelay]="2000">{{
        row.packageName
      }}</span>
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column name="Status" prop="status">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <app-column-stylizer
        [columnValue]="row.status"
        [typeColumn]="'text'"
        [columnRules]="statusRules"
      ></app-column-stylizer>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="FilesCount" prop="filesCount">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ row.filesCount }}" [matTooltipShowDelay]="2000">
        {{ row.filesCount }}
      </span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Automatic QA" prop="automaticQa">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="qa-status-wrapper cursor-pointer" (click)="openValidationResultsDialog(row)">
        <app-column-stylizer
          [columnValue]="row.automaticQa"
          [typeColumn]="'text'"
          [columnRules]="automaticQaRules"
        ></app-column-stylizer>
      </div>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Actions" [sortable]="false" [frozenRight]="true" [width]="250">
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="flg-grid-table-actions" *ngIf="mission">
        <div class="secondary-actions secondary-actions--alone">
          <button
            *ngIf="allowDeliverableTableShowFilesAction"
            type="button"
            class="btn single-action"
            matTooltip="Deliverable Files"
            (click)="viewFiles(row)"
          >
            <span class="material-symbols-rounded">folder</span>
          </button>
          <button
            *ngIf="allowDeliverableTableViewDetailsAction && false"
            type="button"
            class="btn single-action"
            matTooltip="Deliverable Attributes"
            (click)="openInfo(row)"
          >
            <span class="material-symbols-rounded">info</span>
          </button>
          <button
            *ngIf="allowDeliverableEditAttributesAction"
            type="button"
            class="btn single-action"
            matTooltip="Edit Attributes"
            (click)="openDeliverableAttributesForm(row)"
          >
            <span class="material-symbols-rounded">settings</span>
          </button>
          <button
            *ngIf="allowDeliverableTableDeleteAction"
            type="button"
            class="btn single-action"
            matTooltip="Delete Deliverables"
            (click)="deleteDeliverable(row)"
          >
            <span class="material-symbols-rounded">delete</span>
          </button>
          <button
            type="button"
            class="btn single-action"
            (click)="downloadSelectedFiles(row)"
            matTooltip="Download"
          >
            <span class="material-symbols-rounded"> download </span>
          </button>
          <lib-action-form
            *ngIf="allowDeliverableTableRejectAction"
            [row]="{
              missionId: mission.missionId,
              deliverableId: row.deliverableId,
              orderDetailId: row.orderDetailId,
              title: mission.missionName,
              altitude: row.altitude || '-',
              gimbal: row.grimbalangle || '-',
              overlap: row.overlap || '-',
              originAuthToken: currentToken,
              creatorId: currentUserId,
            }"
            [gridAction]="action"
            [isMobile]="false"
            [config]="{ modalWidth: '900px', showCloseOnModal: true }"
            (parentEvent)="fetchDeliverables(row)"
            class="single-action single-action--wide"
          >
            <button type="button" class="table-outline-button me-2" [disabled]="!row.canReview">
              Reject
            </button>
          </lib-action-form>
          <lib-action-form
            *ngIf="allowDeliverableTableAcceptAction"
            [row]="{
              title: DELIVERABLE_ACCEPT_TITLE,
              question: DELIVERABLE_ACCEPT_QUESTION,
              missionId: mission.missionId,
              deliverableId: row.deliverableId,
              orderDetailId: row.orderDetailId,
              creatorId: currentUserId,
            }"
            [gridAction]="acceptAction"
            [isMobile]="false"
            [config]="{ modalWidth: '900px', showCloseOnModal: true }"
            (parentEvent)="fetchDeliverables()"
            class="single-action single-action--wide"
          >
            <button
              type="button"
              class="table-outline-button table-outline-button--inverted"
              [disabled]="!row.canReview"
            >
              Accept
            </button>
          </lib-action-form>
          &nbsp;&nbsp;
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<app-deliverable-files
  class="deliverable-files-content"
  *ngIf="folderMissions"
  [class.d-none]="!showFileManager"
  [missionFilter]="folderMissions"
  [deliverable]="selectedDeliverable"
  (nameInteracted)="goBack()"
  [deliverables]="deliverables"
  [missionStatusId]="missionStatusId"
  [allowToAccept]="allowUpdateDeliverableStatusInCaptures"
  [allowToReject]="allowUpdateDeliverableStatusInCaptures"
  [missionName]="mission.missionName"
  [acceptTitle]="DELIVERABLE_ACCEPT_TITLE"
  [acceptQuestion]="DELIVERABLE_ACCEPT_QUESTION"
></app-deliverable-files>

<app-responsive-grid
  class="d-none"
  gridName="RejectDeliverable"
  (action)="setAction($event)"
></app-responsive-grid>

<app-responsive-grid
  class="d-none"
  gridName="AcceptDeliverable"
  (action)="setAcceptAction($event)"
></app-responsive-grid>

<ng-template #fileModal>
  <flg-generic-modal>
    <ng-container modalTitle>Upload to Raptor Maps</ng-container>
    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form id="file-dumping" [formGroup]="formFile">
          <mat-form-field class="w-100">
            <mat-label>Order Id / External reference code</mat-label>
            <input matInput formControlName="externalCode" maxlength="100" required="required" />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> Session Started </mat-label>
            <input matInput formControlName="startedDate" readonly />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> Session Finished </mat-label>
            <input matInput formControlName="finishDate" readonly />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> Total processed files </mat-label>
            <input matInput formControlName="totalProcessFiles" readonly />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> Triggered by </mat-label>
            <input matInput formControlName="triggeredBy" maxlength="100" readonly />
          </mat-form-field>

          <mat-form-field class="w-100">
            <mat-label> Status </mat-label>
            <input matInput formControlName="sessionStatusString" maxlength="100" readonly />
          </mat-form-field>

          <div class="d-flex justify-content-end mt-3" modalActions>
            <button mat-flat-button class="outline-button me-2" type="button" (click)="hideForm()">
              {{ 'AbpUi::Cancel' | abpLocalization }}
            </button>
            <button
              mat-flat-button
              color="primary"
              class="common-button"
              type="button"
              (click)="submitFilesDumpingSessionForm()"
              [disabled]="formFile.invalid || filesDumpingSessionInProgress"
            >
              {{ 'AbpUi::Save' | abpLocalization }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </flg-generic-modal>
</ng-template>
