<div class="header">
  <h3 class="title">{{ 'missionsService::Captures' | abpLocalization }}</h3>
</div>

<ngx-datatable
  class="flg-grid-table"
  [rows]="captures"
  [columns]="captureColumns"
  [headerHeight]="50"
  [footerHeight]="55"
  [rowHeight]="50"
  [scrollbarH]="true"
  default
>
  <ngx-datatable-column name="#" prop="captureNumber" [minWidth]="75" [maxWidth]="75">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.captureNumber }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::Date' | abpLocalization }}"
    prop="captureDate"
    [minWidth]="130"
    [maxWidth]="130"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.fixedCaptureDate || (row.captureDateTime | date: 'MM/dd/yyyy') }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::Time' | abpLocalization }}"
    prop="captureTime"
    [minWidth]="130"
    [maxWidth]="130"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.fixedCaptureTime || (row.captureTime ? (row.captureDateTime | date: 'hh:mm a') : '') }}
      {{ mission?.timeZone }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::CaptureMustBe' | abpLocalization }}"
    prop="captureMustBe"
    [width]="200"
    [maxWidth]="200"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.captureMustBe">{{ row.captureMustBe }}</span>
      <span *ngIf="!row.captureMustBe">--</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::CaptureDescription' | abpLocalization }}"
    prop="CaptureDescription"
    [minWidth]="150"
    [width]="200"
    [maxWidth]="250"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ sanitizeHtml(row.scopeOfWork) }}" [matTooltipPosition]="'left'">{{
        sanitizeHtml(row.scopeOfWork)
      }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::Deliverables' | abpLocalization }}"
    prop="deliverables"
    [minWidth]="200"
    [width]="350"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span matTooltip="{{ row.deliverablesAsString }}">{{ row.deliverablesAsString }}</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::Pilots' | abpLocalization }}"
    prop="pilots"
    [width]="200"
    [maxWidth]="200"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <span *ngIf="row.pilots.length === 1">
        <span
          class="pilot-link"
          (click)="displayPilotProfile(pilot.id)"
          *ngFor="let pilot of row.pilots; let last = last"
          >{{ pilot.name }}{{ last ? '' : ', ' }}</span
        >
      </span>
      <button
        (click)="displayPilotsModal(row.pilots)"
        mat-button
        class="common-button"
        *ngIf="row.pilots.length > 1"
      >
        Show Pilots
      </button>
      <span *ngIf="row.pilots.length === 0">--</span>
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::CapturePilotPay' | abpLocalization }}"
    prop="capturePilotPay"
    [minWidth]="130"
    [maxWidth]="130"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      ${{ row.pilotPay || 0 | number: '1.2-2' }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column
    name="{{ 'missionsService::PilotPaymentDate' | abpLocalization }}"
    prop="pilotPaymentDate"
    [width]="200"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{
        row.fixedPilotPayDate ||
          ((row.pilotPayDate ? row.pilotPayDate + 'Z' : '') | date: 'MM/dd/yyyy')
      }}
    </ng-template>
  </ngx-datatable-column>
  <ngx-datatable-column name="Flexible Capture Time" prop="flexibleCaptureTime" [width]="200">
    <ng-template let-row="row" ngx-datatable-cell-template>
      {{ row.flexibleCaptureTime ? 'Yes' : 'No' }}
    </ng-template>
  </ngx-datatable-column>

  <ngx-datatable-column
    name="Actions"
    [width]="250"
    [sortable]="false"
    [frozenRight]="true"
    [headerClass]="'actions-column'"
    [cellClass]="'fixed-column'"
  >
    <ng-template let-row="row" ngx-datatable-cell-template>
      <div class="flg-grid-table-actions">
        <div class="secondary-actions">
          <button
            *ngIf="allowNavigateToPilotSourcing"
            type="button"
            class="btn p-0 single-action"
            (click)="navigateToSourcing(row)"
            matTooltip="Navigate to Pilot Sourcing"
          >
            <span class="material-symbols-rounded">record_voice_over</span>
          </button>

          <button
            type="button"
            class="btn p-0 single-action"
            (click)="showEditCaptureDialog(row)"
            matTooltip="Change Capture Description"
            *ngIf="allowEditCaptureDescription"
          >
            <span class="material-symbols-rounded"> edit </span>
          </button>

          <button
            type="button"
            class="btn p-0 single-action"
            (click)="showSelectPilotDialog(row)"
            matTooltip="Chat with pilot"
            *ngIf="showChat(row)"
          >
            <span class="material-symbols-rounded"> chat </span>
          </button>

          <lib-management-actions
            *ngFor="let action of actions"
            class="pointer single-action"
            gridName="Captures"
            [rowData]="getActionData(row)"
            uniqueAction="{{ action.code }}"
            (parentEvent)="reloadPilotSourcing()"
            matTooltip="{{ action.helperText }}"
          >
          </lib-management-actions>
        </div>
      </div>
    </ng-template>
  </ngx-datatable-column>
</ngx-datatable>

<ng-template #selectPilotModal>
  <flg-generic-modal>
    <ng-container modalTitle>Select pilot</ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form id="contact-types" [formGroup]="formSelectPilot">
          <mat-form-field class="w-100">
            <mat-label> Pilot </mat-label>
            <mat-select name="pilot" formControlName="pilotId">
              <mat-option *ngFor="let data of pilots" [value]="data.id">
                {{ data.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <div class="d-flex justify-content-end mt-3" modalActions>
            <button
              mat-flat-button
              class="outline-button me-2"
              type="button"
              (click)="closeModal()"
            >
              {{ 'AbpUi::Cancel' | abpLocalization }}
            </button>
            <button
              mat-flat-button
              color="primary"
              class="common-button"
              [disabled]="formSelectPilot.invalid"
              type="button"
              (click)="chatWithPilot()"
            >
              {{ 'AbpUi::Save' | abpLocalization }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </flg-generic-modal>
</ng-template>

<ng-template #editCaptureDescriptionModal>
  <flg-generic-modal>
    <ng-container modalTitle>
      {{ 'missionsService::EditCaptureDescription' | abpLocalization }}</ng-container
    >

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <form id="contact-types" [formGroup]="formEditCaptureDescription">
          <mat-form-field class="w-100">
            <mat-label> {{ 'missionsService::CaptureDescription' | abpLocalization }}</mat-label>
            <textarea
              class="height-increase"
              matInput
              placeholder="'{{ 'missionsService::CaptureDescription' | abpLocalization }}'"
              formControlName="captureDescription"
            ></textarea>
          </mat-form-field>
          <div class="d-flex justify-content-end mt-3" modalActions>
            <button
              mat-flat-button
              class="outline-button me-2"
              type="button"
              (click)="closeModal()"
            >
              {{ 'AbpUi::Cancel' | abpLocalization }}
            </button>
            <button
              mat-flat-button
              color="primary"
              class="common-button"
              [disabled]="formEditCaptureDescription.invalid"
              type="button"
              (click)="editCaptureDescription()"
            >
              {{ 'AbpUi::Save' | abpLocalization }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </flg-generic-modal>
</ng-template>

<ng-template #showPilotsModal let-data>
  <flg-generic-modal>
    <ng-container modalTitle> Assigned Pilots</ng-container>

    <div class="container p-0" modalBody>
      <div class="row justify-content-center">
        <ul class="pilot-list">
          <li *ngFor="let pilot of data">
            <a (click)="displayPilotProfile(pilot.id)" class="pilot-name">{{ pilot.name }}</a>
          </li>
        </ul>
      </div>
    </div>

    <div modalActions>
      <button
        mat-flat-button
        color="primary"
        class="common-button"
        type="button"
        cdkFocusInitial
        matDialogClose
      >
        Ok
      </button>
    </div>
  </flg-generic-modal>
</ng-template>
