import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { FormGroup, Validators } from '@angular/forms';

import { OrderFormModel } from '../../model/order-form.model';
import { ABP, ListService, PagedResultDto, downloadBlob } from '@abp/ng.core';
import {
  GetConfigurationAttributeTypeInput,
  GetConfigurationTypeInput,
  GetIndustryInput,
  GetPriorityInput,
  IndustriesDto,
  PrioritiesDto,
  SLADto,
} from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { Observable, Subscription, catchError, debounceTime, distinctUntilChanged, finalize, firstValueFrom, forkJoin, interval, map, of, switchMap, tap } from 'rxjs';
import {
  ConfigurationAttributeTypesService,
  ConfigurationTypesService,
  CountriesService,
  IndustriesService,
  PrioritiesService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { OrderFormSiteModel } from '../../model/order-form-site.model';
import { ProductDeliverablesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals';
import { OrderSiteComponent } from '../../new-order-form/order-site/order-site.component';
import { FormHelpers } from 'projects/flyguys/src/app/form-helpers';
import { OrderRequestMissionDTO } from '../../dto/order-request-mission.dto';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '../../../common/confirm-dialog/confirm.dialog.component';
import { getPendingControls } from '@flyguys/components';
import { MessageSucessComponent } from '../../../common/message/message.success.component';

import * as XLSX from 'xlsx';
import { MissionBulkDTO, MissionBulkStatuses } from '../../dto/order-bulk-dto';
import { OrderBulkStates } from '../../../columns/rules/lookup-rules';
import {
  DirectoryDescriptorService,
  FileDescriptorService,
} from '@volo/abp.ng.file-management/proxy';
import {
  OrdersService,
  BulkUploadDetailsService,
  BulkUploadsService,
  PortafoliosService
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import {
  BulkUploadDetailsDto,
  BulkUploadDetailsExcelDto,
  BulkUploadsBUDto,
  BulkUploadsCreateBUDto,
  BulkUploadsCreateDto,
  BulkUploadsDto,
  GetBulkUploadDetailsInput,
  GetBulkUploadsInput,
  enumState,
  GetPortafolioInput,
  MissionSLADto,
  PortafoliosDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { enumBulkUpload } from 'projects/missions-service/src/lib/proxy/missions-service/basics/enum-bulkupload.enum';
import { FileUploadComponent } from '../../../common/file-upload/file-upload.component';
import { BulkUploadDetailErrorsComponent } from '../../bulk-upload-detail-errors/bulk-upload-detail-errors.component';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { Confirmation } from '@abp/ng.theme.shared';

const requiredControlsNames = {
  priorityId: 'SLA Profile',
  portfolioName: 'Portfolio Name',
};

@Component({
  selector: 'app-second-step',
  templateUrl: './second-step.component.html',
  styleUrls: ['./second-step.component.scss'],
})
export class SecondStepComponent implements OnInit, OnChanges, OnDestroy {
  [x: string]: any;
  // @TODO This should be its own form, not a generic one
  @Input() form: FormGroup;

  // @TODO This model is redundant as the previous form should handle
  // serialization
  @Input() model: OrderFormModel;

  @Input() contactsUpdated: boolean;
  @Output() discardChange: EventEmitter<boolean> = new EventEmitter();

  @ViewChildren('orderMission') orderMissions: QueryList<OrderSiteComponent>;
  @ViewChild(FileUploadComponent) fileUploadInput: FileUploadComponent;

  orderBulkStates = OrderBulkStates;

  @ViewChild(OrderSiteComponent) orderSiteComponent: OrderSiteComponent;

  bulkDetailsData: PagedResultDto<BulkUploadDetailsDto> = {
    items: [],
    totalCount: 0,
  };

  dataPriorities: PagedResultDto<PrioritiesDto> = {
    items: [],
    totalCount: 0,
  };
  @Input() SLAOptions: PagedResultDto<SLADto> = {
    items: [],
    totalCount: 0,
  };

  sLASelected: SLADto;

  filterPriorities = {} as GetPriorityInput;

  dataIndustries: PagedResultDto<IndustriesDto> = {
    items: [],
    totalCount: 0,
  };
  filterIndustries = {} as GetIndustryInput;

  private subscriptions = new Subscription();
  private siteCount = 0;
  loadingPackages = false;

  excelData: BulkUploadDetailsExcelDto[] = [];

  bulkFolderId: string;
  uploadedExcel: File;
  uploadedFileId: string = null;
  uploadedFileName: string = null;
  bulkUploadId: string;

  bulkImportProgress: number = 0;

  intervalBulkStatus: Subscription;

  flatBulkOrdersStatus: number = 0;

  private readonly BULK_UPLOAD_LIMIT_ATTR: string = 'BULKUPLOADLIMIT';
  private bulkUploadLimit: number = 40; // Default value

  bulkClientMaxResultCount = 10;
  bulkPageNumber = 0;

  isHaveFileCleanLines: boolean = false;
  isEmptyFile: boolean = false;
  intLineCount: number = 0;

  portfolioselected: PortafoliosDto;
  dataPortfolios: PagedResultDto<PortafoliosDto> = {
    items: [],
    totalCount: 0,
  };
  filterPortfolios = {} as GetPortafolioInput;
  filteredPortfolios: Observable<string[]>;
  loaders: Map<string, boolean> = new Map();

  portfolioTotalMissions: string = '';
  slaGenerated: MissionSLADto[];

  constructor(
    public readonly list: ListService,
    public readonly industriesService: IndustriesService,
    public readonly priorityService: PrioritiesService,
    public productDeliverablesService: ProductDeliverablesService,
    public readonly countriesService: CountriesService, // This is just for mock
    public readonly directoryDescriptorService: DirectoryDescriptorService,
    public readonly fileDescriptorService: FileDescriptorService,
    public readonly ordersService: OrdersService,
    public readonly bulkUploadDetailsService: BulkUploadDetailsService,
    public readonly bulkUploadsService: BulkUploadsService,
    public dialogService: MatDialog,
    public readonly configurationTypesService: ConfigurationTypesService,
    public readonly configurationAttributeTypesService: ConfigurationAttributeTypesService,
    public readonly portfoliosService: PortafoliosService,
    private readonly slaService: SlaService
  ) {}

  ngOnInit() {
    const sub = forkJoin([
      this.priorityService.getList(this.filterPriorities),
      this.industriesService.getList(this.filterIndustries),
    ]).subscribe({
      next: ([priorities, industries]) => {
        this.dataPriorities = priorities;
        this.dataIndustries = industries;
      },
      error: error => console.log(`New Order - Step Two Error: ${error}`),
    });

    this.getFolgerId();

    this.subscriptions.add(sub);

    this.addNewSite();
    this.getBulkUploadLimit();

    this.filteredPortfolios = this.form.controls.portfolioName.valueChanges.pipe(
      tap(() => {
      }),
      debounceTime(400),
      distinctUntilChanged(),
      tap(() => this.loaders.set('portfolioName', true)),
      switchMap(() =>
        this.generateSuggestionsPortfolios().pipe(
          finalize(() => this.loaders.set('portfolioName', false))
        )
      )
    );  
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes['model'] && !changes['model'].firstChange) {
      this.siteCount = 0;
      this.checkAndAddNewSite();
    }
    if (changes['SLAOptions']) {
      if (this.model.priorityId && this.SLAOptions.totalCount > 0) {
        this.sLASelected = this.SLAOptions.items.find(p => p.id == this.model.priorityId);
      }
    }
  }

  addNewSite(): void {
    const newSite = new OrderFormSiteModel();
    this.siteCount++;
    newSite.number = String(this.siteCount);
    this.model.orderSites.push(newSite);
    this.updatePortfolioNameValidation();
  }

  checkAndAddNewSite(): void {
    if (!this.model.orderSites || this.model.orderSites.length === 0) {
      this.addNewSite();
    }
  }

  setDescriptionFromId(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    const item = items.find(x => x.id === id?.toString());
    this.model[fieldtoSet] = item?.[fieldName];
  }

  async discard() {
    this.discardChange.emit(true);
    await this.discardBulkImport();
  }

  getMissionsDto(): OrderRequestMissionDTO[] {
    const orderMissionsDto: OrderRequestMissionDTO[] = [];
    this.orderMissions.forEach((orderMission: OrderSiteComponent) => {
      orderMissionsDto.push(
        orderMission.buildOrderRequestMissionDto(
          this.model.customerId,
          this.model.customerDescription,
          this.model.priorityId,
          this.model.priorityDescription
        )
      );
    });
    return orderMissionsDto;
  }

  updatePortfolioNameValidation() {
    const portfolioNameControl = this.form.get('portfolioName');
    if (this.model.orderSites.length > 1 || this.model.isBulkImport) {
      portfolioNameControl.setValidators([Validators.required]);
    } else {
      portfolioNameControl.clearValidators();
    }
    portfolioNameControl.updateValueAndValidity();
  }

  isStepValid(): boolean {
    if (this.model.isBulkImport) {
      if (
        this.uploadedExcel &&
        !this.model.bulkData.bulkUploadDetails.find(
          x => x.bulkUploadStatus == enumBulkUpload.InValidation || x.bulkUploadStatus == null
        ) &&
        this.model.bulkData.bulkUploadDetails.find(
          x => x.bulkUploadStatus == enumBulkUpload.DataValidationPassed
        ) &&
        this.model.portfolioName.trim() != ''
      ) {
        return this.form.status !== FormHelpers.ValidationStatus.INVALID;
      }
      return false;
    }

    // If there are no order missions yet, the step is considered invalid
    if (!this.orderMissions) {
      return false;
    }

    // Check if all order missions have no pending controls
    // If any order mission has pending controls, the step is considered invalid
    return this.orderMissions
      .toArray()
      .every(orderMission => !orderMission.getPendingControls()?.length);
  }

  handleRemoveSite(psite: OrderFormSiteModel) {
    const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
      data: {
        title: 'Delete this Site?',
        actions: {
          confirm: 'Delete',
          cancel: 'Cancel',
        },
      },
      disableClose: true,
      width: '400px',
    });

    this.subscriptions.add(
      dialogRef.afterClosed().subscribe((removeSite: Boolean) => {
        if (removeSite) {
          const indexToRemove = this.model.orderSites.indexOf(psite);

          if (indexToRemove !== -1) {
            this.model.orderSites.splice(indexToRemove, 1);
          }

          this.model.orderSites = [...this.model.orderSites];
          this.siteCount = this.model.orderSites.length;
          this.updatePortfolioNameValidation();
        }
      })
    );
  }

  /**
   * Searches for controls that are required and not filled on the form
   * @returns pendingControl[]
   */
  getPendingControls() {
    if (this.orderMissions) {
      let pendingSiteControls = [];

      for (const site of this.orderMissions) {
        pendingSiteControls = [...pendingSiteControls, ...site.getPendingControls()];
      }

      const pendingOrderControls = getPendingControls(this.form, requiredControlsNames);

      return [...pendingOrderControls, ...pendingSiteControls];
    }
  }

  /**
   * Indicates if the forms have been interacted with
   *
   * @returns boolean
   */
  formsTouched(): boolean {
    if (this.orderMissions) {
      let touchedSites = [];

      for (const site of this.orderMissions) {
        touchedSites.push(site.formOrderSite.touched);
      }

      touchedSites = [...touchedSites, this.form.touched];

      return touchedSites.some(e => e === true);
    } else {
      return false;
    }
  }

  /**
   * This is done in order to update sibling sites' tables, when a contact in another table is updated.
   * By changing the this.contactsUpdated variable, we trigger a change detection in all order-site components.
   *
   */
  onContactsUpdatedChange() {
    this.contactsUpdated = !this.contactsUpdated;
  }

  getFolgerId() {
    const folderName = 'Bulk Missions';

    this.directoryDescriptorService
      .getContent({ filter: folderName, maxResultCount: 1 })
      .subscribe(allContent => {
        const folderItem = allContent.items[0];

        if (folderItem) {
          this.bulkFolderId = folderItem.id;
          return;
        }

        this.directoryDescriptorService.create({ name: folderName }).subscribe(newFolder => {
          this.bulkFolderId = newFolder.id;
        });
      });
  }

  downloadTemplate() {
    this.bulkUploadDetailsService
      .getDownloadToken()
      .pipe(
        switchMap(({ token }) =>
          this.bulkUploadDetailsService.getListAsExcelFile({
            bulkUploadId: '00000000-0000-0000-0000-000000000000',
            downloadToken: token,
          })
        )
      )
      .subscribe(result => {
        downloadBlob(result, 'BulkTemplate.xlsx');
      });
  }

  async onBulkSelect(file: File[]) {
    await this.discardBulkImport();
    this.isHaveFileCleanLines = false;
    this.isEmptyFile = false;

    if (file.length !== 1) throw new Error('Cannot use multiple files'); // TODO: change to modal
    const reader: FileReader = new FileReader();

    this.uploadedExcel = file[0];

    reader.onload = (e: any) => {
      /* read workbook */
      const bstr: string = e.target.result;
      const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });

      /* grab first sheet */
      const wsname: string = wb.SheetNames[0];
      const ws: XLSX.WorkSheet = wb.Sheets[wsname];

      /* save data */
      this.excelData = XLSX.utils.sheet_to_json<BulkUploadDetailsExcelDto>(ws);

      if (this.excelData.length == 0) {
        this.fileUploadInput.removeAllSelectedFiles();
        this.isEmptyFile = true;
        return;
      }

      if(this.excelData.length > this.bulkUploadLimit) {
        this.fileUploadInput.removeAllSelectedFiles();
        return;
      }

      let _intLineEmptyCount: number = 0;

      _intLineEmptyCount = this.excelData.filter(d => d.MissionName == undefined &&
        d.MissionSummary == undefined &&
        d.MissionName == undefined &&
        d.ProjectName == undefined &&
        d.Recurrence == undefined &&
        d.RepeatsOn == undefined &&
        d.BeginDate == undefined &&
        d.EndDate == undefined &&
        d.Repetitions == undefined &&
        d.Time == undefined &&
        d.CaptureDateMust == undefined &&
        d.DeliverableDueDate == undefined &&
        d.DeliverablesNote == undefined &&
        d.DataUploadingInstructions == undefined &&
        d.AdditionalNotes == undefined &&
        d.SiteName == undefined &&
        d.LocationStreet == undefined &&
        d.LocationCountry == undefined &&
        d.LocationState == undefined &&
        d.LocationCity == undefined &&
        d.LocationZipCode == undefined &&
        d.LocationLatitude == undefined &&
        d.LocationLongitude == undefined &&
        d.AirspaceClassification == undefined &&
        d.SiteContactFrom == undefined &&
        d.SiteContactEmail == undefined &&
        d.SiteContactFirstName == undefined &&
        d.SiteContactLastName == undefined &&
        d.SiteContactPhone == undefined &&
        d.SiteContactLevelofCoordination == undefined &&
        d.SiteContactPreferredContact == undefined).length;

      if(_intLineEmptyCount > 0){
        this.isHaveFileCleanLines = true;
        this.intLineCount = this.excelData.length - _intLineEmptyCount;
        if(this.intLineCount <= 0){
          this.fileUploadInput.removeAllSelectedFiles();
          return;          
        }   
      }

      _intLineEmptyCount = this.excelData.filter(
        d =>
          d.MissionName == undefined &&
          d.MissionSummary == undefined &&
          d.MissionName == undefined &&
          d.ProjectName == undefined &&
          d.Recurrence == undefined &&
          d.RepeatsOn == undefined &&
          d.BeginDate == undefined &&
          d.EndDate == undefined &&
          d.Repetitions == undefined &&
          d.Time == undefined &&
          d.CaptureDateMust == undefined &&
          d.DeliverableDueDate == undefined &&
          d.DeliverablesNote == undefined &&
          d.DataUploadingInstructions == undefined &&
          d.AdditionalNotes == undefined &&
          d.SiteName == undefined &&
          d.LocationStreet == undefined &&
          d.LocationCountry == undefined &&
          d.LocationState == undefined &&
          d.LocationCity == undefined &&
          d.LocationZipCode == undefined &&
          d.LocationLatitude == undefined &&
          d.LocationLongitude == undefined &&
          d.AirspaceClassification == undefined &&
          d.SiteContactFrom == undefined &&
          d.SiteContactEmail == undefined &&
          d.SiteContactFirstName == undefined &&
          d.SiteContactLastName == undefined &&
          d.SiteContactPhone == undefined &&
          d.SiteContactLevelofCoordination == undefined &&
          d.SiteContactPreferredContact == undefined
      ).length;

      if (_intLineEmptyCount > 0) {
        this.isHaveFileCleanLines = true;
        this.intLineCount = this.excelData.length - _intLineEmptyCount;
        if (this.intLineCount <= 0) {
          this.fileUploadInput.removeAllSelectedFiles();
          return;
        }
      }

      this.uploadExcel();
    };

    reader.readAsArrayBuffer(this.uploadedExcel);
  }

  async onBulkRemoved() {
    if (this.intervalBulkStatus != null) {
      this.intervalBulkStatus.unsubscribe();
    }

    this.bulkImportProgress = 0;
    this.excelData = [];
    this.bulkDetailsData.items = [];
    this.bulkDetailsData.totalCount = 0;

    await this.discardBulkImport();
  }

  deleteExcel() {
    if (this.uploadedFileId != null) {
      const _delete = this.fileDescriptorService
        .delete(this.uploadedFileId.toUpperCase())
        .subscribe(_return => {
          this.uploadedFileId = null;
          this.uploadedFileName = null;

          if (this.model.bulkData.id != null) {
            const _dataDelete = this.bulkUploadsService
              .delete(this.model.bulkData.id.toUpperCase())
              .subscribe(_return => {
                this.model.bulkData = {
                  id: null,
                  fileId: null,
                  fileName: null,
                  bulkUploadDetails: [],
                } as BulkUploadsBUDto;
              });
          } else {
            this.model.bulkData = {
              id: null,
              fileId: null,
              fileName: null,
              bulkUploadDetails: [],
            } as BulkUploadsBUDto;
          }
        });
    }
  }

  uploadExcel() {
    var formData = new FormData();
    formData.append('file', this.uploadedExcel);

    const dateString = new Date()
      .toISOString()
      .replace(/([:,.,-])/g, '')
      .slice(0, -4);
    const fileName = `${dateString}-${this.uploadedExcel.name}`;

    this.uploadedFileName = fileName;

    this.fileDescriptorService
      .uploadFile(this.bulkFolderId, fileName, null, formData)
      .subscribe(uploaded => {
        this.uploadedFileId = uploaded.id;
        this.sendBulkOrders();
      });

    this.updatePortfolioNameValidation();
  }

  sendBulkOrders() {
    let bulkUploadsCreateBUDto: BulkUploadsCreateBUDto = {} as BulkUploadsCreateBUDto;
    bulkUploadsCreateBUDto.fileId = this.uploadedFileId.toUpperCase();
    bulkUploadsCreateBUDto.fileName = this.uploadedFileName;
    bulkUploadsCreateBUDto.bulkUploadStatus = enumBulkUpload.Pending;
    bulkUploadsCreateBUDto.customerId = this.model.customerId;
    bulkUploadsCreateBUDto.customerName = this.model.customerDescription;
    bulkUploadsCreateBUDto.priorityId = this.model.priorityId;
    bulkUploadsCreateBUDto.priorityName = this.model.priorityDescription;
    bulkUploadsCreateBUDto.bulkUploadDetails  = [];

    this.bulkUploadsService.createBUFile(bulkUploadsCreateBUDto).subscribe(importStatusId =>{
      this.model.bulkData.id = importStatusId.id.toUpperCase();
      this.model.bulkData.fileName = importStatusId.fileName;
      this.model.bulkData.fileId = importStatusId.fileId.toUpperCase();
      this.model.bulkData.bulkUploadStatus = importStatusId.bulkUploadStatus;
      this.model.bulkData.bulkUploadDetails = importStatusId.bulkUploadDetails;
      this.defineHookQuery();

      this.getBulkOrdersStatus();
      if(!this.model.bulkData.bulkUploadDetails.find(x => x.bulkUploadStatus == enumBulkUpload.Pending))
        this.intervalBulkStatus = interval(5000).subscribe(() => this.getBulkOrdersStatus());
    }),
      error => {
        console.error('Error createing order request:', error);
      };
  }

  sendBulkOrdersTest() {
    let bulkUploadsCreateDto: BulkUploadsCreateDto = {} as BulkUploadsCreateDto;

    bulkUploadsCreateDto.fileId = this.uploadedFileId.toUpperCase();
    bulkUploadsCreateDto.fileName = this.uploadedFileName;
    bulkUploadsCreateDto.bulkUploadStatus = enumBulkUpload.Pending;
    bulkUploadsCreateDto.customerId = this.model.customerId;
    bulkUploadsCreateDto.customerName = this.model.customerDescription;
    bulkUploadsCreateDto.priorityId = this.model.priorityId;
    bulkUploadsCreateDto.priorityName = this.model.priorityDescription;

    this.bulkUploadsService.create(bulkUploadsCreateDto).subscribe(importStatusId => {
      this.model.bulkData.id = importStatusId.id.toUpperCase();
      this.model.bulkData.fileName = importStatusId.fileName;
      this.model.bulkData.fileId = importStatusId.fileId.toUpperCase();

      this.model.bulkData.bulkUploadDetails.forEach((element, index) => {
        element.bulkUploadId = importStatusId.id;

        this.bulkUploadDetailsService.create(element).subscribe();

        if (index + 1 == this.model.bulkData.bulkUploadDetails.length) {
          this.intervalBulkStatus = interval(5000).subscribe(() => this.getBulkOrdersStatus());
        }
      });
    }),
      error => {
        console.error('Error createing order request:', error);
      };
  }

  getBulkOrdersStatus() {
    let getBulkUploadsInput: GetBulkUploadsInput = {} as GetBulkUploadsInput;

    getBulkUploadsInput.id = this.model.bulkData.id.toUpperCase();
    getBulkUploadsInput.isPaginated = false;

    if (this.flatBulkOrdersStatus == 0) {
      this.flatBulkOrdersStatus = 1;

      this.bulkUploadsService.getListBU(getBulkUploadsInput).subscribe(res => {
        res.items.forEach(element => {
          this.model.bulkData.bulkUploadDetails = element.bulkUploadDetails;

          this.bulkDetailsData.items = this.bulkDetailsData.items.map(x => {
            return this.model.bulkData.bulkUploadDetails.find(updated => updated.id == x.id) ?? x;
          });
                    
          this.bulkImportProgress =
            (this.model.bulkData.bulkUploadDetails.filter(
              x => x.bulkUploadStatus != enumBulkUpload.Pending
            ).length /
              this.model.bulkData.bulkUploadDetails.length) *
            100;
          if (
            !this.model.bulkData.bulkUploadDetails.find(
              x => x.bulkUploadStatus == enumBulkUpload.Pending
            )
          )
          if(this.intervalBulkStatus) this.intervalBulkStatus.unsubscribe();
          this.flatBulkOrdersStatus = 0;
        });
      });
    }
  }

  async discardBulkImport() {
    this.intervalBulkStatus?.unsubscribe();
    this.uploadedExcel = undefined;
    this.bulkImportProgress = 0;

    if (this.uploadedFileId != null)
      await firstValueFrom(this.fileDescriptorService.delete(this.uploadedFileId.toUpperCase()));
    this.uploadedFileId = null;
    this.uploadedFileName = null;

    if (this.model.bulkData.id != null)
      await firstValueFrom(this.bulkUploadsService.delete(this.model.bulkData.id.toUpperCase()));
    this.model.bulkData = {
      id: null,
      fileId: null,
      fileName: null,
      bulkUploadDetails: [],
    } as BulkUploadsBUDto;
  }

  private getBulkUploadLimit(): void {
    const query = {} as ABP.PageQueryParams;
    const configurationTypeFilter = {
      state: enumState.Enabled,
      code: this.BULK_UPLOAD_LIMIT_ATTR,
    } as GetConfigurationTypeInput;

    this.configurationTypesService
      .getList({
        ...query,
        ...configurationTypeFilter,
        filterText: query.filter,
      })
      .pipe(
        switchMap(result => {
          let configuration = result.items.find(_ => true);
          let configurationAttributeTypeFilter = {
            configurationTypeId: configuration?.id,
          } as GetConfigurationAttributeTypeInput;
          return this.configurationAttributeTypesService.getList({
            ...query,
            ...configurationAttributeTypeFilter,
            filterText: query.filter,
          });
        })
      )
      .subscribe(res => {

        let attribute = res.items.find(_ => true);
        this.bulkUploadLimit = parseInt(attribute.description);
      });
  }

  private defineHookQuery() {
    let getBulkUploadsDetailsInput: GetBulkUploadDetailsInput = {} as GetBulkUploadDetailsInput;

    getBulkUploadsDetailsInput.bulkUploadId = this.model.bulkData.id.toUpperCase();
    getBulkUploadsDetailsInput.isPaginated = true;

    const getData = (query: ABP.PageQueryParams) =>
      this.bulkUploadDetailsService.getList({
        ...query,
        ...getBulkUploadsDetailsInput,
      });

    const setData = (list: PagedResultDto<BulkUploadsBUDto>) => (this.bulkDetailsData = list);

    this.list.hookToQuery(getData).subscribe(setData);
  }

  viewErrorDetail(row: BulkUploadDetailsDto) {
    const dialogData = {
      detail: row,
    };

    const dialogRef = this.dialogService.open(BulkUploadDetailErrorsComponent, {
      data: dialogData,
      disableClose: true,
      width: '1150px',
    });
  }

  ngOnDestroy() {
    if (this.intervalBulkStatus != null){
      this.intervalBulkStatus.unsubscribe();      
    }
 }


 getPortfolio(event: MatAutocompleteSelectedEvent) {
  if (this.dataPortfolios?.items.length > 0) {
    const selectedPortfolio = this.dataPortfolios.items.find(
      s => s.name.trim() === event.option.value.trim()
    );

    if (selectedPortfolio) {
      this.portfolioselected = selectedPortfolio;
      this.model.portfolioId = selectedPortfolio.id;
      this.model.portfolioName = event.option.value.trim();
      this.portfolioTotalMissions = "Total Missions: " + selectedPortfolio.totalMissions.toString();

      this.form.get('portfolioId').setValue(selectedPortfolio.id);
    }
  }
}


private generateSuggestionsPortfolios(): Observable<string[]> {
  this.filterPortfolios.name = this.form.controls.portfolioName.value.trim();
  this.filterPortfolios.customerId =  this.model.customerId;

  return this.portfoliosService.getList(this.filterPortfolios).pipe(
    map((data: PagedResultDto<PortafoliosDto>) => {
      const suggestions: string[] = [];
      if (data?.totalCount !== 0) {
        this.dataPortfolios = data;
        for (let s of this.dataPortfolios.items) {
          if (s.portfolioStatus.trim() === "In Progress"){
            suggestions.push(s.name.trim());
          }
        }
      }
      else{
        this.portfolioselected = null;
        this.model.portfolioId = null;
        this.portfolioTotalMissions  = "";
      }

      return suggestions;
    }),
    catchError(error => {
      console.log('Error on getting portfolios: ' + JSON.stringify(error));

      return of([]);
    })
  );
  }

  toggle(event: MatSlideToggleChange) {
    this.model.portfolioExists = event.checked;

    if (!event.checked){
      this.model.portfolioId = null;
      this.model.portfolioName = null;
    }
  }

  handleChangeSlaProfile(id: string) {
    this.sLASelected = this.SLAOptions.items.find(d => d.id == id);
    this.slaService.recalculateSLASubject.next(id);
  }

  focusOutPortfolioname() {
    if (this.form.controls.portfolioName.value.trim() === '') {
      this.portfolioTotalMissions = '';
    }
  }

  slaProcessed(slaProcessed) {
    this.slaGenerated = slaProcessed;
  }

  changeMilestoneMatchWithCaptureDate(newCaptureDate) {
    this.orderMissions.get(0).formOrderSite.get('captureDate').setValue(newCaptureDate);
  }
}
