<div class="container">
  <div class="row justify-content-center">
    <div class="col-lg-11 col-md-11">
      <div class="d-flex justify-content-between">
        <h3 class="mt-4 mb-4">Order Request</h3>
        <div class="d-flex align-items-center">
          <mat-slide-toggle [(ngModel)]="model.isBulkImport">
            <span class="bulkImport">{{ 'missionsService::BulkImport' | abpLocalization }}</span>
          </mat-slide-toggle>

          <button mat-button class="plain-button ms-2" (click)="downloadTemplate()">
            <mat-icon>download</mat-icon>{{ 'missionsService::BulkTemplate' | abpLocalization }}
          </button>
        </div>
      </div>
      <form [formGroup]="form" skipValidation>
        <div class="justify-content-end">
          <mat-form-field class="w-50">
            <mat-label>SLA</mat-label>
            <mat-select
              name="orderPriority"
              [(ngModel)]="model.priorityId"
              (ngModelChange)="
                setDescriptionFromId(
                  model.priorityId,
                  SLAOptions.items,
                  'priorityDescription',
                  'name'
                );
                handleChangeSlaProfile(model.priorityId)
              "
              formControlName="priorityId"
            >
              <mat-option
                *ngFor="let dp of SLAOptions.items"
                [value]="dp.id"
                title="{{ dp.name }}"
              >
                {{ dp.name }}
              </mat-option>
            </mat-select>

            <mat-error
              *ngIf="
                form.get('priorityId').hasError('required') ||
                form.get('priorityId').hasError('whitespace')
              "
            >
              Please enter a value
            </mat-error>
          </mat-form-field>
        </div>

        <div class="justify-content-end">
          <mat-form-field class="w-50">
            <mat-label>{{ 'missionsService::AssetOrder' | abpLocalization }}</mat-label>
            <input
              matInput
              placeholder="Asset/Order Number"
              [(ngModel)]="model.missionAssetOrder"
              formControlName="missionAssetOrder"
            />
          </mat-form-field>
        </div>

        <div class="justify-content-end" *ngIf="slaGenerated && sLASelected?.isCustom == true">
          <app-order-custom-sla
            [slas]="slaGenerated"
            (changeCaptureDate)="changeMilestoneMatchWithCaptureDate($event)"
          ></app-order-custom-sla>
        </div>

        <mat-progress-spinner
          class="spinner-container"
          *ngIf="loadingPackages"
          mode="indeterminate"
          diameter="50"
        ></mat-progress-spinner>

        <ng-container *ngIf="model.isBulkImport; else default">
          <label for="attachDocumentation" class="label-order">{{
            'missionsService::AttachBulkUpload' | abpLocalization
          }}</label>

          <app-file-upload
            (filesSelected)="onBulkSelect($event)"
            (fileRemoved)="onBulkRemoved($event)"
            [allowMultiple]="false"
            [allowedExtensions]="'.xlsx'"
          ></app-file-upload>

          <mat-error *ngIf="!uploadedExcel">{{
            'missionsService::PendingTemplate' | abpLocalization
          }}</mat-error>
          <mat-error *ngIf="excelData.length > this.bulkUploadLimit"
            >{{ 'missionsService::BulkUploadLimitExceeded' | abpLocalization }}
            {{ this.bulkUploadLimit }}</mat-error
          >
          <mat-error *ngIf="isHaveFileCleanLines"
            >{{ 'missionsService::MessageCleanLine' | abpLocalization }}
            {{ this.intLineCount }}</mat-error
          >
          <mat-error *ngIf="isEmptyFile">{{
            'missionsService::MessageEmptyFile' | abpLocalization
          }}</mat-error>

          <div class="my-2 h-25" *ngIf="model.bulkData.bulkUploadDetails.length > 0">
            <div class="my-4 d-flex align-items-center">
              <span
                ><strong>Total Missions: </strong
                >{{ model.bulkData.bulkUploadDetails.length }}</span
              >
              <mat-progress-bar
                *ngIf="model.bulkData"
                mode="determinate"
                [value]="bulkImportProgress"
                class="ms-3"
                style="max-width: 200px"
              ></mat-progress-bar>
              <span class="ms-3"
                ><strong>Data Validation Percentage: </strong>{{ bulkImportProgress }}</span
              >
            </div>

            <ngx-datatable
              [rows]="bulkDetailsData.items"
              [offset]="bulkPageNumber"
              [count]="bulkDetailsData.totalCount"
              [list]="list"
              [externalPaging]="true"
              [scrollbarH]="true"
              [headerHeight]="55"
              [rowHeight]="50"
              [limit]="bulkClientMaxResultCount"
              class="material flg-simple-table"
              default
              #dataTable
            >
              <ngx-datatable-column
                name="{{ 'missionsService::MissionName' | abpLocalization }}"
                prop="missionName"
                [resizeable]="true"
                [sortable]="false"
                [headerClass]="'flg-simple-table__header'"
                [cellClass]="'flg-simple-table__cell'"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  {{ row.missionName }}
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'missionsService::BulkUploadStatus' | abpLocalization }}"
                prop="bulkUploadStatus"
                [resizeable]="true"
                [sortable]="false"
                [headerClass]="'flg-simple-table__header'"
                [cellClass]="'flg-simple-table__cell'"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <app-column-stylizer
                    [columnRules]="orderBulkStates"
                    [typeColumn]="'text'"
                    [columnValue]="row.bulkUploadStatus"
                  ></app-column-stylizer>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'missionsService::UploadFailDescription' | abpLocalization }}"
                prop="uploadFailDescription"
                [resizeable]="true"
                [sortable]="false"
                [headerClass]="'flg-simple-table__header'"
                [cellClass]="'flg-simple-table__cell'"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <span matTooltip="{{ row.uploadFailDescription }}" [matTooltipShowDelay]="1000">
                    {{ row.uploadFailDescription }}</span
                  >
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-column
                name="{{ 'missionsService::Detail' | abpLocalization }}"
                prop="Detail"
                [resizeable]="true"
                [sortable]="false"
                [headerClass]="'flg-simple-table__header'"
                [cellClass]="'flg-simple-table__cell'"
              >
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <button
                    mat-icon-button
                    (click)="viewErrorDetail(row)"
                    *ngIf="row.uploadFailDescription"
                  >
                    <mat-icon>visibility_outline</mat-icon>
                  </button>
                </ng-template>
              </ngx-datatable-column>

              <ngx-datatable-footer>
                <ng-template
                  ngx-datatable-footer-template
                  let-rowCount="rowCount"
                  let-pageSize="pageSize"
                  let-curPage="curPage"
                >
                  <app-table-paginator
                    [(maxResultCount)]="list.maxResultCount"
                    [(clientMaxResultCount)]="bulkClientMaxResultCount"
                    [rowCount]="rowCount"
                    [pageSize]="pageSize"
                    [(pageNumber)]="bulkPageNumber"
                    [curPage]="curPage"
                    [dataTable]="dataTable"
                    [customPageSize]="true"
                    style="width: 100%"
                  >
                  </app-table-paginator>
                </ng-template>
              </ngx-datatable-footer>
            </ngx-datatable>
          </div>
        </ng-container>

        <ng-template #default>
          <div *ngFor="let site of model.orderSites">
            <app-order-site
              [site]="site"
              [customerId]="model.customerId"
              [slaId]="model.priorityId"
              #orderMission
              (removesite)="handleRemoveSite(site)"
              [contactsUpdated]="contactsUpdated"
              (contactsUpdatedChange)="onContactsUpdatedChange()"
              (slaUpdatedChange)="slaProcessed($event)"
              [sLASelected]="sLASelected"
            ></app-order-site>
          </div>
          <div class="mt-3 mb-3 w-100 me-2">
            <button
              [disabled]="sLASelected?.isCustom"
              class="plain-buttonw-25"
              mat-button
              type="button"
              color="primary"
              (click)="addNewSite()"
            >
              <mat-icon aria-hidden="false" aria-label="Add New Site" fontIcon="add"></mat-icon>
              Add New Site
            </button>
            <mat-divider class="d-inline-block w-75" horizontal></mat-divider>
          </div>
        </ng-template>

        <div class="d-flex justify-content-end mt-3" 
        *ngIf="model.orderSites?.length == 1 && !model.isBulkImport">
        <mat-slide-toggle [(ngModel)]="model.portfolioExists" (change)="toggle($event)">
          <span class="bulkImport">{{ 'missionsService::PortfolioExists' | abpLocalization }}</span>
        </mat-slide-toggle>
      </div>

        <div
          class="d-flex justify-content-end mt-3"
          *ngIf="model.orderSites?.length > 1 || model.isBulkImport || model.portfolioExists"
        >
          <mat-form-field class="w-100">
            <mat-label>Portfolio Name {{portfolioTotalMissions}}</mat-label>
            <input
              #inputPortfolioName
              type="text"
              placeholder="Requesting Portfolio Name*"
              matInput
              formControlName="portfolioName"
              [matAutocomplete]="autPortfolio"
              [(ngModel)]="model.portfolioName"
              (focusout)="focusOutPortfolioname()"
            />
            <mat-progress-spinner
            *ngIf="loaders.get('portfolioName')"
            matSuffix
            [mode]="'indeterminate'"
            [diameter]="20"
            ></mat-progress-spinner>
            <mat-autocomplete #autPortfolio="matAutocomplete" (optionSelected)="getPortfolio($event)">
              <mat-option
                *ngFor="let option of filteredPortfolios | async"
                [value]="option"
                title="{{ option }}"
                class="autocomplete-dropdown"
              >
                {{ option }}
              </mat-option>
            </mat-autocomplete>            
            <mat-error
              *ngIf="
                form.get('portfolioName').hasError('required') ||
                form.get('portfolioName').hasError('whitespace')
              "
            >
            Please enter a valid portfolio
            </mat-error>
          </mat-form-field>
        </div>

        <flg-pending-controls
          [touched]="formsTouched()"
          [controls]="getPendingControls()"
        ></flg-pending-controls>

        <div class="d-flex justify-content-end mt-3">
          <button
            mat-stroked-button
            class="outline-button b-3 me-auto"
            matStepperPrevious
            type="button"
          >
            <span class="button-text">Back</span>
          </button>
          <button mat-flat-button class="plain-button me-2" type="button" (click)="discard()">
            Discard
          </button>

          <button
            mat-flat-button
            color="primary"
            class="common-button"
            matStepperNext
            type="button"
            [disabled]="!isStepValid()"
          >
            Next
          </button>
        </div>
      </form>
    </div>
  </div>
</div>
