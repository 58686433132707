import { Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatStepper } from '@angular/material/stepper';
import { DatePipe } from '@angular/common';

import { Subscription, tap, finalize } from 'rxjs';

import {
  ABP,
  ConfigStateService,
  ListService,
  LocalizationService,
  PagedResultDto,
} from '@abp/ng.core';
import { DateAdapter } from '@abp/ng.theme.shared/extensions';

import { NgbDateAdapter } from '@ng-bootstrap/ng-bootstrap';

import { OrderFormModel } from '../model/order-form.model';
import {
  MissionsService,
  OrdersService,
  PortafoliosService,
  BulkUploadsService,
} from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';
import { CustomersService } from 'projects/customers-service/src/lib/proxy/customers-service/controllers/basics';
import {
  ContactMethodsDto,
  ContactTypesDto,
  GetContactMethodInput,
  GetContactTypeInput,
  GetLevelCommunicationInput,
  LevelCommunicationsDto,
  SLADto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups/models';
import {
  ContactMethodsService,
  ContactTypesService,
  LevelCommunicationsService,
} from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';

import { Helpers } from '../../../helpers';
import {
  BulkUploadsUpdateDto,
  ContactsCreateDto,
  type PackageDto,
  PortafoliosCreateDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/basics';

import { enumState } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-state.enum';
import { ConfirmDialogComponent } from '../../common/confirm-dialog/confirm.dialog.component';
import { FormHelpers } from '../../../form-helpers';
import { contactTypeEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-type.enum';
import { levelCoordinationEnum } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared/level-coordination.enum';
import { contactMethodEnum } from 'projects/missions-service/src/lib/proxy/missions-service/shared/contact-method.enum';
import { OrderRequestDTO } from '../dto/order-request.dto';
import { OrderRequestDeliverableDTO } from '../dto/order-request-deliverable.dto';
import {
  recurrenceEnum,
  recurrenceEnumDisplayNames,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/shared/recurrency.enum';
import { OrderRequestDeliverableFieldDTO } from '../dto/order-request-deliverable-field.dto';
import { SecondStepComponent } from '../steps/second-step/second-step.component';
import { OrderFormDeliverableModel } from '../model/order-form-deliverable.model';
import { LoadingOverlayService } from '../../../services/loading/loading.service';
import { FileDescriptorService } from '@volo/abp.ng.file-management/proxy';
import { OrderFormSiteModel } from '../model/order-form-site.model';
import { OrderRequestCustomerDTO } from '../dto/order-request-customer.dto';
import { OAuthService } from 'angular-oauth2-oidc';
import { OrderFormSiteFileModel } from '../model/order-form-site-file.model';

import type {
  ValuesCustomerAttributeCreateDto,
  ValuesCustomerAttributeUpdateDto,
  ValuesCustomerAttributeWithNavigationPropertiesDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals/models';
import { CustomerAttributeWithNavigationPropertiesDto } from 'projects/customers-service/src/lib/proxy/customers-service/relationals';
import { ValuesCustomerAttributeService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals/values-customer-attribute.service';
import { MapVisibilityService } from '../services/map-visibility.service';
import { OrderFormPackageModel } from '../model/order-form-package-model';
import { OrderEditingService } from '../services/order-editing.service';
import { CopyMissionForm } from '../../../../../../missions-service/src/lib/basics/missions/components/models/copy-mission-form';
import { OrderRequestMissionDTO } from '../dto/order-request-mission.dto';
import { captureMustBeEnumDisplayNames } from '../../../../../../missions-service/src/lib/proxy/missions-service/shared/capture-mustbe.enum';
import { OrderFormContactModel } from '../model/order-form-contact.model';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { OrderFormDetailsModel } from '../model/order-form-detail.model';
import { OrderFormDeliverableFieldModel } from '../model/order-form-deliverable-field.model';
import { MissionBulkDTO } from '../dto/order-bulk-dto';
import { enumBulkUpload } from 'projects/missions-service/src/lib/proxy/missions-service/basics/enum-bulkupload.enum';
import { PackageService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics/package.service';
import type { CustomersDto } from '../../../../../../customers-service/src/lib/proxy/customers-service/basics';
import { SlaService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics/sla.servicet';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { ThirdStepComponent } from '../steps/third-step/third-step.component';
import { KmlDataInfo } from '../../../shared/deliverable-list/deliverables-list.component';
import { AttributesInfo, KmlDataInfoPkg } from '../../../shared/package/package.component';


@Component({
  selector: 'app-new-order-form',
  templateUrl: './new-order-form.component.html',
  styleUrls: ['./new-order-form.component.scss'],
  providers: [ListService, { provide: NgbDateAdapter, useClass: DateAdapter }],
})
export class NewOrderFormComponent implements OnDestroy, OnInit {
  @Output() onGoBack = new EventEmitter<boolean>();

  @ViewChild('secondStep') secondStep: SecondStepComponent;
  @ViewChild('thirdStep') thirdStep: ThirdStepComponent;
  @ViewChild(MatStepper) stepper: MatStepper;

  orderModel: OrderFormModel;
  formStepOne: FormGroup;
  formStepTwo: FormGroup;
  formStepThree: FormGroup;
  formStepFour: FormGroup;
  currentToken: string;
  contactsUpdated: boolean = false;
  localStorageKey: string = '';
  kmlData: KmlDataInfo[] = [];
  kmlArray: KmlDataInfo[] = [];
  kmlDataPkg: KmlDataInfoPkg[] = [];
  attributesDetails: AttributesInfo[] = [];

  private subscriptions = new Subscription();
  private readonly SECOND_STEP_INDEX = 1;

  contactTypes: PagedResultDto<ContactTypesDto> = {
    items: [],
    totalCount: 0,
  };

  levelCommunications: PagedResultDto<LevelCommunicationsDto> = {
    items: [],
    totalCount: 0,
  };

  contactMethods: PagedResultDto<ContactMethodsDto> = {
    items: [],
    totalCount: 0,
  };

  customerAttributes: PagedResultDto<CustomerAttributeWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };
  valuesCustomerAttributes: PagedResultDto<ValuesCustomerAttributeWithNavigationPropertiesDto> = {
    items: [],
    totalCount: 0,
  };

  SLAOptions: PagedResultDto<SLADto> = {
    items: [],
    totalCount: 0,
  };

  isEditing: boolean = false;
  currentUserId: string = '';

  constructor(
    private router: Router,
    public readonly list: ListService,
    public readonly missionService: MissionsService,
    public readonly customerService: CustomersService,
    public readonly orderService: OrdersService,
    public dialogService: MatDialog,
    public loadingService: LoadingOverlayService,
    public readonly fileDescriptorService: FileDescriptorService,
    private oAuthService: OAuthService,
    public readonly contactTypesService: ContactTypesService,
    public readonly levelCommunicationsService: LevelCommunicationsService,
    public readonly contactMethodsService: ContactMethodsService,
    public readonly valuesCustomerAttributeService: ValuesCustomerAttributeService,
    public readonly mapVisibilityService: MapVisibilityService,
    private datePipe: DatePipe,
    private orderEditingService: OrderEditingService,
    private route: ActivatedRoute,
    private stateService: ConfigStateService,
    public readonly bulkUploadsService: BulkUploadsService,
    public readonly packageService: PackageService,
    private localizationService: LocalizationService,
    private readonly slaService: SlaService,
    private confirmation: ConfirmationService,
  ) {
    this.orderModel = new OrderFormModel();
    this.orderModel.packagesbyCustumer = [];
    this.orderModel.genericPackages = [];
    this.formStepOne = FormHelpers.buildValidatorsOrderStep1();
    this.formStepTwo = FormHelpers.buildValidatorsOrderStep2();
    this.formStepThree = FormHelpers.buildValidatorsOrderStep3();
    this.currentToken = this.oAuthService.getAccessToken();

    const stepOneFormSubscription = this.formStepOne.valueChanges.subscribe(() => {
      this.orderEditingService.startEditing();
    });
    this.subscriptions.add(stepOneFormSubscription);
    const editingSubscription = this.orderEditingService.isEditing$.subscribe(isEditing => {
      this.isEditing = isEditing;
    });
    this.subscriptions.add(editingSubscription);
  }

  ngOnInit(): void {
    this.getContactsType();
    this.getLevelCommunication();
    this.getContactsMethods();
    this.loadSlaByCustomer();
    this.route.queryParams.subscribe(params => {
      const missionId = params['missionId'];
      if (missionId) {
        this.currentUserId = this.stateService.getDeep('currentUser.id');
        this.populateOrderModel(missionId);
      }
    });
  }

  setDescriptionFromId(id: string, items: any[], fieldtoSet: string, fieldName: string) {
    const item = items.find(x => x.id === id?.toString());
    this.orderModel[fieldtoSet] = item?.[fieldName];
  }
  async saveAll() {
    this.createOrderRequest();
  }

  buildCustomerDto(contactType: contactTypeEnum): OrderRequestCustomerDTO {
    switch (contactType) {
      case contactTypeEnum.Company:
        return {
          id: this.orderModel.customerId,
          name: this.orderModel.customerDescription,
          parentId: null,
        };
      case contactTypeEnum.Subclient:
        return {
          id: this.orderModel.subcustomerId,
          name: this.orderModel.subclientName,
          parentId: this.orderModel.customerId,
        };
    }
  }

  buildContactDto(conacttype: contactTypeEnum): ContactsCreateDto {
    let contact: ContactsCreateDto;
    switch (conacttype) {
      case contactTypeEnum.Company:
        var contactType = this.contactTypes.items.find(i => i.code == contactTypeEnum.Company);
        var levelCommunication = this.levelCommunications.items.find(
          i => i.code == levelCoordinationEnum.Notify
        );
        var contactMethod = this.contactMethods.items.find(i => i.code == contactMethodEnum.Email);

        return {
          contactTypeId: contactType?.id,
          contactTypeDescription: contactType?.description,
          firstName: this.orderModel.customerContactFirstName,
          lastName: this.orderModel.customerContactLastName,
          shareData: false,
          phone: this.orderModel.customerContactNumber,
          email: this.orderModel.customerContactEmail,
          levelCommunicationId: levelCommunication?.id,
          levelCommunicationDescription: levelCommunication?.description,
          contactMethodId: contactMethod?.id,
          contactMethodDescription: contactMethod?.description,
          state: enumState.Enabled,
        };
    }
    return contact;
  }

  buildOrderRequestDto(): OrderRequestDTO {
    let missions = this.secondStep.getMissionsDto();

    missions = missions.map(r => {
      const date: Date = new Date(`${r.capture.date} ${r.capture.time || '00:00'}`);
      r.capture.date = this.datePipe.transform(date, 'yyyy-MM-dd', 'UTC');
      r.capture.time = r.capture.time ? this.datePipe.transform(date, 'HH:mm', 'UTC') : null;
      return r;
    });

    return {
      customer: this.buildCustomerDto(contactTypeEnum.Company),
      customerName: this.orderModel.customerDescription,
      customerContactId: this.orderModel.customerContactId,
      customerContact: this.buildContactDto(contactTypeEnum.Company),
      subCustomer: this.buildCustomerDto(contactTypeEnum.Subclient),
      portfolioName: this.getPorfolioName(),
      portfoliocontacts: this.buildPortfolioContactsDto(),
      missions: missions,
      priorityId: this.orderModel.priorityId,
      priorityDescription: this.orderModel.priorityDescription,
      industryId: this.orderModel.industryId,
      industryName: this.orderModel.industryDescription,
      deliverables: this.buildDeliverablesDto(),
      total: this.orderModel.orderDetail.total,
      originAuthToken: this.currentToken,
      missionAssetOrder: this.orderModel.missionAssetOrder,
      bulkUploadId: this.orderModel.bulkData.id,
      portfolioId: this.orderModel.portfolioId
    };
  }

  buildPortfolioDto(): PortafoliosCreateDto {
    return {
      name: this.getPorfolioName(),
      customerId: this.orderModel.customerId,
      customerName: this.orderModel.customerDescription,
      subCustomerId: this.orderModel.subcustomerId,
      state: enumState.Enabled,
    };
  }

  getPorfolioName(): string | undefined {
    if (this.orderModel?.orderSites?.length > 0) {
      if (this.orderModel.orderSites.length > 1) {
        return this.orderModel.portfolioName;
      } else {
        if (this.orderModel?.isBulkImport) {
          return this.orderModel.portfolioName;
        } else {
          if (this.orderModel?.portfolioName){
            return this.orderModel.portfolioName;
          }else{
          return this.orderModel.orderSites[0]?.missionName ?? '';
          }
        }
      }
    }

    return undefined;
  }

  getRecurrenceFromFromById(id: string) {
    for (const key of Object.keys(recurrenceEnum)) {
      if (recurrenceEnum[key] === id) {
        return recurrenceEnum[key];
      }
    }
  }

  buildPortfolioContactsDto(): ContactsCreateDto[] {
    const contactList: ContactsCreateDto[] = [];
    const levelCommunication = this.levelCommunications.items.find(
      i => i.code == levelCoordinationEnum.Coordinate
    );
    const contactMethod = this.contactMethods.items.find(i => i.code == contactMethodEnum.Email);

    for (const contact of this.orderModel.additionalContacts) {
      const contactType = this.getContactFromFromById(contact.contactFromId);
      const newContact: ContactsCreateDto = {
        contactId: contact.contactId,
        contactTypeId: contactType?.id,
        contactTypeDescription: contactType?.description,
        firstName: contact.name,
        lastName: contact.lastname,
        phone: contact.number,
        email: contact.email,
        levelCommunicationId: levelCommunication?.id,
        levelCommunicationDescription: levelCommunication?.description,
        contactMethodId: contactMethod?.id,
        contactMethodDescription: contactMethod?.description,
        shareData: contact.shareData,
        state: enumState.Enabled,
      };
      contactList.push(newContact);
    }
    return contactList;
  }

  getContactFromFromById(id: string) {
    for (const key of Object.keys(contactTypeEnum)) {
      if (contactTypeEnum[key] === id) {
        return this.contactTypes.items.find(i => i.code == contactTypeEnum[key]);
      }
    }
  }

  buildDeliverablesDto(): OrderRequestDeliverableDTO[] {
    let deliverableList: OrderRequestDeliverableDTO[] = [];

    this.orderModel.orderDetail.packagesSelected.forEach((p: OrderFormPackageModel) => {
      p.deliverables.forEach((dv: OrderFormDeliverableModel) => {
        let newDto = this.buildDeliverableDto(dv, Number(p.packagePrice));
        deliverableList.push(newDto);
      });
    });

    this.orderModel.orderDetail.productsSelected.forEach((pr: OrderFormDeliverableModel) => {
      let newDto = this.buildDeliverableDto(pr);
      deliverableList.push(newDto);
    });

    return deliverableList;
  }

  buildDeliverableDto(
    deliverable: OrderFormDeliverableModel,
    packagePrince: number = 0,
  ): OrderRequestDeliverableDTO {
    let newDeliverable: OrderRequestDeliverableDTO = {
      deliverableId: deliverable.deliverableId,
      deliverableName: deliverable.deliverableName,
      productId: deliverable.productId,
      productName: deliverable.productName,
      packageId: deliverable.packageId,
      packageName: deliverable.packageName,
      industryId: deliverable.industryId,
      industryDescription: deliverable.industryDescription,
      multiplier: deliverable.industryMultiplexor,
      standardPrice: deliverable.standardPrice,
      actualPrice: deliverable.actualPrice,
      quantity: deliverable.quantity,
      subTotal: deliverable.subTotal,
      fields: [],
      sameConfiguration: deliverable.sameConfiguration,
      packagePrice: packagePrince,
    };

    if (deliverable.sameConfiguration) {
      for (const f of deliverable.fields) {
        if (f.typeCode.toLocaleLowerCase() == 'kml') {
          if (deliverable.packageId == null || deliverable.packageId == '') {
            this.kmlData = this.thirdStep?.getKmlFieldUploaded();
          }
          if (deliverable.packageId) {
            this.kmlDataPkg = this.thirdStep?.getKmlFieldUploadedPkg();
          }

          if (deliverable.packageId == null || deliverable.packageId == '') {
            if (this.kmlData.length > 0) {
              const kmlForDeliverable = this.kmlData
                .filter(x => x.deliverableId === deliverable.deliverableId)
                .reduce((acc, x) => acc.concat(x.kmlField), []);

              kmlForDeliverable.forEach(kf => {
                const newfield: OrderRequestDeliverableFieldDTO = {
                  fieldId: kf.id,
                  fieldName: kf.placeholder,
                  fieldNumericValue: kf.stringValue,
                  fieldStringValue: kf.stringValue,
                };
                const exists = newDeliverable.fields.some(
                  field => field.fieldStringValue === newfield.fieldStringValue,
                );
                if (!exists) {
                  newDeliverable.fields.push(newfield);
                }
              });
            }
          }
          if (deliverable.packageId != '') {
            this.kmlDataPkg.forEach(x => {
              if (x.packageId == deliverable.packageId && x.productId == deliverable.productId) {
                x.kmlField.forEach(kml => {
                  if (kml.fileValue != '' && kml.stringValue != '') {
                    const newfield: OrderRequestDeliverableFieldDTO = {
                      fieldId: kml.id,
                      fieldName: kml.placeholder,
                      fieldNumericValue: kml.stringValue,
                      fieldStringValue: kml.stringValue,
                    };
                    let exists = newDeliverable.fields.some(
                      field => field.fieldStringValue === newfield.fieldStringValue,
                    );
                    if (!exists) {
                      newDeliverable.fields.push(newfield);
                    }
                  }
                });
              }
            });
          }
        } else {
          const newfield: OrderRequestDeliverableFieldDTO = {
            fieldId: f.id,
            fieldName: f.placeholder,
            fieldNumericValue: f.stringValue,
            fieldStringValue: f.stringValue,
          };
          let exists = newDeliverable.fields.some(
            field => field.fieldStringValue === newfield.fieldStringValue,
          );
          if (!exists) {
            newDeliverable.fields.push(newfield);
          }
        }
      }
    } else {
      newDeliverable.detailedAttributes = [];
      if (this.attributesDetails && this.attributesDetails.length > 0) {
        let matchingAttribute = this.attributesDetails.find(
          x => x.packageId === deliverable.packageId,
        );

        if (matchingAttribute) {
          let attFilter = matchingAttribute.deliverableAttribute.filter(
            d => d.productId === deliverable.productId,
          );

          if (attFilter.length) {
            let [firstAtt] = attFilter;
            deliverable.detailedAttributes = [...firstAtt.detailedAttributes];
          }
        }
      }
      if (deliverable.packageId == null || deliverable.packageId == '') {
        this.kmlData = this.thirdStep.getKmlFieldUploaded();
        if (this.kmlData.length > 0) {
          for (let i = 0; i < deliverable?.detailedAttributes?.length; i++) {
            let attributesArray = [];
            let nonKmlAttributes = deliverable.detailedAttributes[i].filter(
              f => f.typeCode !== 'KML',
            );
            let detailAtt = deliverable.detailedAttributes[i].find(dt => dt.typeCode == 'KML');

            let kmlFieldsForDeliverable = this.kmlData
              .find(x => x.deliverableId === deliverable.deliverableId)
              ?.kmlField.filter(kml => kml?.fieldControlName === detailAtt?.fieldControlName);

            for (let f of nonKmlAttributes) {
              let newfield: OrderRequestDeliverableFieldDTO = {
                fieldId: f.id,
                fieldName: f.placeholder,
                fieldNumericValue: f.stringValue,
                fieldStringValue: f.stringValue,
              };
              attributesArray.push(newfield);
            }

            for (let kmlField of kmlFieldsForDeliverable) {
              let kmlFieldDTO: OrderRequestDeliverableFieldDTO = {
                fieldId: kmlField.id,
                fieldName: kmlField.placeholder,
                fieldNumericValue: kmlField.stringValue,
                fieldStringValue: kmlField.stringValue,
              };
              attributesArray.push(kmlFieldDTO);
            }

            newDeliverable.detailedAttributes[i] = attributesArray;
          }
        } else {
          newDeliverable.detailedAttributes = [];
          for (let i = 0; i < deliverable?.detailedAttributes?.length; i++) {
            let attributesArray = [];
            for (let f of deliverable.detailedAttributes[i]) {
              let newfield: OrderRequestDeliverableFieldDTO = {
                fieldId: f.id,
                fieldName: f.placeholder,
                fieldNumericValue: f.stringValue,
                fieldStringValue: f.stringValue,
              };
              attributesArray.push(newfield);
            }
            newDeliverable.detailedAttributes.push(attributesArray);
          }
        }
      }

      if (deliverable.packageId != '') {
        this.kmlDataPkg = this.thirdStep?.getKmlFieldUploadedPkg();
        if (this.kmlDataPkg.length > 0) {
          for (let i = 0; i < deliverable?.detailedAttributes?.length; i++) {
            let attributesArray = [];
            let nonKmlAttributes = deliverable.detailedAttributes[i].filter(
              f => f.typeCode !== 'KML',
            );
            let detailAtt = deliverable.detailedAttributes[i].find(dt => dt.typeCode == 'KML');

            let kmlFieldsForDeliverable = this.kmlDataPkg
              .filter(
                x =>
                  x.deliverableId === deliverable.deliverableId &&
                  x.packageId === deliverable.packageId &&
                  x.productId === deliverable.productId,
              )
              .reduce((acc, x) => acc.concat(x.kmlField), [])
              .filter(kml => kml?.fieldControlName === detailAtt?.fieldControlName);

            if (nonKmlAttributes && nonKmlAttributes.length > 0) {
              for (let f of nonKmlAttributes) {
                let newfield: OrderRequestDeliverableFieldDTO = {
                  fieldId: f.id,
                  fieldName: f.placeholder,
                  fieldNumericValue: f.stringValue,
                  fieldStringValue: f.stringValue,
                };
                attributesArray.push(newfield);
              }
            }

            if (kmlFieldsForDeliverable && kmlFieldsForDeliverable.length > 0) {
              for (let kmlField of kmlFieldsForDeliverable) {
                let kmlFieldDTO: OrderRequestDeliverableFieldDTO = {
                  fieldId: kmlField.id,
                  fieldName: kmlField.placeholder,
                  fieldNumericValue: kmlField.stringValue,
                  fieldStringValue: kmlField.stringValue,
                };
                attributesArray.push(kmlFieldDTO);
              }

              newDeliverable.detailedAttributes[i] = attributesArray;
            }
          }
        }
      }
    }
    return newDeliverable;
  }

  createOrderRequest() {
    this.loadingService.showOverlay();
    this.orderEditingService.finishEditing();

    if (this.orderModel.isBulkImport) {
      const bulkUploadGet = this.bulkUploadsService.get(this.orderModel.bulkData.id).subscribe(
        data => {
          let bulkUploadsUpdateDto: BulkUploadsUpdateDto = {} as BulkUploadsUpdateDto;

          bulkUploadsUpdateDto.fileId = data.fileId;
          bulkUploadsUpdateDto.fileName = data.fileName;
          bulkUploadsUpdateDto.bulkUploadStatus = enumBulkUpload.ExecutionQueue;
          bulkUploadsUpdateDto.customerId = this.orderModel.customerId;
          bulkUploadsUpdateDto.customerName = this.orderModel.customerDescription;
          bulkUploadsUpdateDto.priorityId = this.orderModel.priorityId;
          bulkUploadsUpdateDto.priorityName = this.orderModel.priorityDescription;
          bulkUploadsUpdateDto.industryId = this.orderModel.industryId;
          bulkUploadsUpdateDto.industryName = this.orderModel.industryDescription;
          bulkUploadsUpdateDto.jsonRequest = JSON.stringify(this.buildOrderRequestDto());

          this.bulkUploadsService
            .update(this.orderModel.bulkData.id, bulkUploadsUpdateDto)
            .subscribe(importStatusId => {
              this.loadingService.hideOverlay();
              Helpers.openDialog(
                this.dialogService,
                'Order Created',
                `${this.localizationService.instant('missionsService::BulkImportSuccess')} ${
                  this.orderModel.bulkData.fileName
                }`,
                this.router,
                'orders',
                this.onGoBack
              );

              return;
            }),
            error => {
              console.error('Error update bulkupload request:', error.message);
              return;
            };
        },
        error => {
          console.error('Error get bulkUpload request:', error.message);
          this.loadingService.hideOverlay();
          return;
        }
      );
      this.subscriptions.add(bulkUploadGet);
    } else {
      const subsOrder = this.orderService.createOrderRequest(this.buildOrderRequestDto()).subscribe(
        data => {
          if (data?.id != '') {
            this.loadingService.hideOverlay();

            // After successfully saving, remove copy mission key from local storage if exists
            if (localStorage.getItem(this.localStorageKey)) {
              localStorage.removeItem(this.localStorageKey);
            }

            Helpers.openDialog(
              this.dialogService,
              'Order Created',
              this.getPorfolioName(),
              this.router,
              'orders',
              this.onGoBack
            );
          }
        },
        error => {
          console.error('Error createing order request:', error.message);
          this.loadingService.hideOverlay();
        }
      );
      this.subscriptions.add(subsOrder);
    }
  }

  handleOnGoBack() {
    this.discardCreation();
  }

  discardCreation() {
    this.discard().then(confirm => {
      if (confirm) {
        this.orderEditingService.finishEditing();
        if (this.onGoBack.observers.length > 0) {
          this.onGoBack.emit(false);
        } else {
          this.router.navigate(['/orders']);
        }
      }
    });
  }

  async discard(): Promise<boolean> {
    return new Promise<boolean>((resolve, reject) => {
      const dialogRef = this.dialogService.open(ConfirmDialogComponent, {
        data: {
          title: 'Discard job creation?',
          message: '',
          actions: {
            cancel: 'Cancel',
            confirm: 'Discard',
          },
        },
        disableClose: true,
        width: '400px',
      });

      const dialogSub = dialogRef.afterClosed().subscribe((confirm: boolean) => {
        if (confirm) {
          if (this.orderModel.orderSites?.length > 0) {
            this.orderModel.orderSites.forEach((site: OrderFormSiteModel) => {
              if (site.kmlFiles?.length > 0) {
                const deleteRequests =
                  site.kmlFiles?.map(kmlFile =>
                    this.fileDescriptorService.delete(kmlFile.fileId)
                  ) || [];

                if (site.filesAttachments?.length > 0) {
                  site.filesAttachments.forEach((file: OrderFormSiteFileModel) => {
                    deleteRequests.push(this.fileDescriptorService.delete(file.fileId));
                  });
                }
              } else if (site.filesAttachments?.length > 0) {
                site.filesAttachments.map(file => this.fileDescriptorService.delete(file.fileId));
              }
            });
            resolve(true);
          } else {
            resolve(true);
          }
          this.dialogService.closeAll();
        } else {
          resolve(false);
        }
      });
      this.subscriptions.add(dialogSub);
    });
  }

  private getContactsType() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetContactTypeInput;

    this.contactTypesService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactTypes = res;
      });
  }

  private getLevelCommunication() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetLevelCommunicationInput;

    this.levelCommunicationsService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.levelCommunications = res;
      });
  }

  private getContactsMethods() {
    const query = {} as ABP.PageQueryParams;
    const controlTypeFilter = { state: enumState.Enabled } as GetContactMethodInput;

    this.contactMethodsService
      .getList({
        ...query,
        ...controlTypeFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.contactMethods = res;
      });
  }

  onContactsUpdatedChange() {
    this.contactsUpdated = !this.contactsUpdated;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  async submitFormAddCutomerAttribute(_missionId: string, _missionDescription: string) {
    this.orderModel.controls.forEach(control => {
      let controlValue = this.orderModel.formAddCutomerAttribute.value[control.key];

      controlValue = String(controlValue);

      if (control.type == 'checkbox') {
        controlValue = control.valueCheckBox;

        if (controlValue == '') {
          controlValue = 'false';
        } else {
          controlValue = String(controlValue);
        }
      }

      control.value = controlValue;

      let _valuesCustomerAttributeId: string = this.valuesCustomerAttributes.items.find(
        va =>
          va.missions.id == _missionId &&
          va.valuesCustomerAttribute.customerAttributeId == control.key
      )?.valuesCustomerAttribute.id;
      let _customerAttributeDescription: string = this.customerAttributes.items.find(
        ca => ca.customerAttribute.id == control.key
      )?.customerAttribute.displayName;

      if (!_valuesCustomerAttributeId) {
        _valuesCustomerAttributeId = '';
      }

      if (_valuesCustomerAttributeId.trim() == '') {
        let valuesCustomerAttributeCreateDto = {} as ValuesCustomerAttributeCreateDto;

        valuesCustomerAttributeCreateDto.missionDescription = _missionDescription;
        valuesCustomerAttributeCreateDto.missionsId = _missionId;
        valuesCustomerAttributeCreateDto.customerAttributeId = control.key;
        valuesCustomerAttributeCreateDto.customerAttributeDescription =
          _customerAttributeDescription;
        valuesCustomerAttributeCreateDto.value = controlValue;
        valuesCustomerAttributeCreateDto.state = enumState.Enabled;

        const request = this.valuesCustomerAttributeService.create(
          valuesCustomerAttributeCreateDto
        );

        request
          .pipe(
            finalize(() => {}),
            tap(() => {})
          )
          .subscribe();
      } else {
        let valuesCustomerAttributeUpdateDto = {} as ValuesCustomerAttributeUpdateDto;

        valuesCustomerAttributeUpdateDto.missionDescription = _missionDescription;
        valuesCustomerAttributeUpdateDto.missionsId = _missionId;
        valuesCustomerAttributeUpdateDto.customerAttributeId = control.key;
        valuesCustomerAttributeUpdateDto.customerAttributeDescription =
          _customerAttributeDescription;
        valuesCustomerAttributeUpdateDto.value = controlValue;
        valuesCustomerAttributeUpdateDto.state = enumState.Enabled;
        let _concurrencyStamp: string = this.valuesCustomerAttributes.items.find(
          va =>
            va.missions.id == _missionId &&
            va.valuesCustomerAttribute.customerAttributeId == control.key
        )?.valuesCustomerAttribute.concurrencyStamp;
        valuesCustomerAttributeUpdateDto.concurrencyStamp = _concurrencyStamp;

        const request = this.valuesCustomerAttributeService.update(
          _valuesCustomerAttributeId,
          valuesCustomerAttributeUpdateDto
        );

        request
          .pipe(
            finalize(() => {}),
            tap(() => {})
          )
          .subscribe();
      }
    });
  }

  /**
   * Signals the second step to prepare the map
   * @returns void
   */
  handleAnimationDone() {
    if (this.stepper.selectedIndex === this.SECOND_STEP_INDEX) {
      this.mapVisibilityService.set(true);
    }
  }

  private populateOrderModel(missionId: string) {
    this.localStorageKey = `copyMissionForm_${missionId}_${this.currentUserId}`;
    const formDataJson = localStorage.getItem(this.localStorageKey);
    if (formDataJson) {
      const formData: CopyMissionForm = JSON.parse(formDataJson);

      if (!formData.captureDateMust) {
        formData.captureDateMust = null;
      }

      this.missionService.getMissionForCopy(formData).subscribe({
        next: async (response: OrderRequestDTO) => {
          this.orderModel = new OrderFormModel();
          this.orderModel.customerId = response.customer.id;
          this.orderModel.customerDescription = response.customer.name;

          if (formData.client) {
            this.orderModel.customerContactId = response.customerContactId || '';
            this.orderModel.customerContactFirstName = response.customerContact?.firstName || '';
            this.orderModel.customerContactLastName = response.customerContact?.lastName || '';
            this.orderModel.customerContactEmail = response.customerContact?.email || '';
            this.orderModel.customerContactNumber = response.customerContact?.phone || '';

            if (response.subCustomer && response.subCustomer.id) {
              this.orderModel.subcustomerId = response.subCustomer?.id;
              this.orderModel.subclientName = await this.getSubCustomerName(
                this.orderModel.subcustomerId
              );
              response.subCustomer.name = this.orderModel.subclientName;
            }
          }

          this.orderModel.orderSites = this.mapOrderSites(response.missions, formData);

          if (formData.orderPriority) {
            this.orderModel.priorityId = response.priorityId;
            this.orderModel.priorityDescription = response.priorityDescription;
          }

          if (formData.productsPackages) {
            this.orderModel.industryId = response.industryId;
            this.orderModel.industryDescription = response.industryName;
          }

          this.orderModel.subcustomerId = response.subCustomer?.id;
          this.orderModel.subclientName = response.subCustomer?.name;

          if (formData.additionalContacts) {
            this.orderModel.additionalContacts = this.mapAdditionalContacts(
              response.portfoliocontacts,
              response.customer.id
            );
          }

          if (
            formData.productsPackages &&
            response.deliverables &&
            response.deliverables.length > 0
          ) {
            this.orderModel.orderDetail = new OrderFormDetailsModel();

            const productsDeliverables: OrderRequestDeliverableDTO[] = response.deliverables.filter(
              deliverable => !deliverable.packageId
            );
            this.orderModel.orderDetail.productsSelected = this.mapProducts(productsDeliverables);

            const packagesDeliverables: OrderRequestDeliverableDTO[] = response.deliverables.filter(
              deliverable => deliverable.packageId
            );

            // First we group the deliverables by package
            const deliverablesByPackage = this.groupDeliverablesByPackage(packagesDeliverables);

            const packageModels: OrderFormPackageModel[] = [];

            // Then we map every package separately
            for (const [packageId, deliverables] of deliverablesByPackage.entries()) {
              const packageModel = await this.mapDeliverablesToPackage(packageId, deliverables);
              packageModels.push(packageModel);
            }

            this.orderModel.orderDetail.packagesSelected = packageModels;
          }

          this.fillStep1Form(response, formData);
          this.fillStep2Form(response, formData);
          this.fillStep3Form(response.industryId, formData);
        },
        error: err => console.log(err),
      });
    }
  }

  private mapOrderSites(missions: OrderRequestMissionDTO[], formData: CopyMissionForm): any[] {
    return missions.map(mission => ({
      number: 1, // Since we are copying a mission, we can assume there is only one site
      missionName: formData.missionName ? formData.missionName : '',
      missionSummary: formData.missionSummary ? mission.mission.summary : '',
      captureDate: this.getUpdatedCaptureDate(
        formData.captureDate ? new Date(formData.captureDate) : new Date(mission.capture.date)
      ),
      captureTime: formData.captureTime ? formData.captureTime : mission.capture.time,
      frequencyId: formData.repeats,
      frequencyDescription: formData.repeats ? recurrenceEnumDisplayNames[formData.repeats] : '',
      locSiteName: formData.siteInformation ? mission.location.siteName : '',
      locAirSpaceClasfId: formData.siteInformation ? mission.location.airSpaceClasificationId : '',
      locAirSpaceClasfDescription: formData.siteInformation
        ? mission.location.airspaceClassificationName
        : '',
      manualAirspaceWaiverRequired: formData.siteInformation
        ? mission.manualAirspaceWaiverRequired
        : false,
      captureDateMustId: formData.captureDateMust
        ? formData.captureDateMust
        : mission.capture.captureDateMust,
      captureDateMustDescription: formData.captureDateMust
        ? captureMustBeEnumDisplayNames[formData.captureDateMust]
        : captureMustBeEnumDisplayNames[mission.capture.captureDateMust],
      flexibleCaptureId: mission.capture.captureTypeId,
      flexibleCaptureDescription: mission.capture.captureTypeDescription,
      deliveryAllSameTime: mission.deliverableAspect.deliveryAllSameTime,
      deliveryDate: formData.deliverableDueDate ? mission.deliverableAspect.deliverableDueDate : '',
      deliveryNotes: formData.deliverableDueDate ? mission.deliverableAspect.notes : '',
      uploadingDataInstruction: formData.deliverableDueDate
        ? mission.deliverableAspect.instructions
        : '',
      locLocationId: formData.siteInformation ? mission.locationId : '',
      locAddress: formData.siteInformation ? mission.address.streetAddress : '',
      locCountryId: formData.siteInformation ? mission.address.countryId : '',
      locCountryDescription: formData.siteInformation ? mission.address.countryName : '',
      locStateId: formData.siteInformation ? mission.address.stateId : '',
      locStateDescription: formData.siteInformation ? mission.address.stateName : '',
      locCity: formData.siteInformation ? mission.address.city : '',
      locZipCode: formData.siteInformation ? mission.address.zipCode : '',
      loclatitude: formData.siteInformation ? mission.location.gpsCoordinates.split(',')[0] : '',
      loclongitude: formData.siteInformation ? mission.location.gpsCoordinates.split(',')[1] : '',
      geoFenceRadius: formData.siteInformation ? mission.location.geoFenceRadio : '',
      projectName: mission.project.projectName,
      aditionalNotes: formData.deliverableDueDate ? mission.mission.additionalNotes : '',
      kmlFiles: formData.siteInformation
        ? this.mapKmlFiles(mission.mission.kmlFilePath, mission.mission.kmlFileId)
        : [],
      filesAttachments: mission.filesAttachments || [],
      orderFolderId: mission.orderFolderId,
      missionFolderId: mission.missionFolderId,
      recurrenceEnum: formData.repeats,
      recurrenceDescription: formData.repeats
        ? recurrenceEnumDisplayNames[formData.repeats] || ''
        : '',
      siteContacts: mission.locationContact,
    }));
  }

  private getUpdatedCaptureDate(missionCaptureDate: Date): Date {
    const today = new Date();
    today.setHours(0, 0, 0, 0);

    return missionCaptureDate < today ? today : missionCaptureDate;
  }

  private mapKmlFiles(kmlFilePath: string, kmlFileId: string): OrderFormSiteFileModel[] {
    if (!kmlFileId) {
      return [];
    }

    return [
      {
        fileId: kmlFileId,
        path: kmlFilePath,
        name: '',
      },
    ];
  }

  private mapAdditionalContacts(
    portfoliocontacts: ContactsCreateDto[],
    customerId: string
  ): OrderFormContactModel[] {
    return portfoliocontacts.map(contact => ({
      contactId: contact.contactId || '',
      contactFromId: contact.contactTypeId || '',
      contactFromDescription: contact.contactTypeDescription || '',
      email: contact.email || '',
      name: contact.firstName,
      lastname: contact.lastName,
      number: contact.phone || '',
      shareData: contact.shareData,
      preferredContactMethodId: contact.contactMethodId || '',
      preferredContactMethodDescription: contact.contactMethodDescription || '',
      levelCommunicationId: contact.levelCommunicationId || '',
      levelCommunicationDescription: contact.levelCommunicationDescription || '',
      customerContactDescription: '', // todo check if needed. Probably need to call the Customers Microservice to get this
      customerId: customerId,
      userId: contact.userId || '',
    }));
  }

  private mapProducts(deliverables: OrderRequestDeliverableDTO[]): OrderFormDeliverableModel[] {
    const deliverableGroups: Map<string, OrderRequestDeliverableDTO[]> = new Map();
    deliverables.forEach(deliverable => {
      const deliverableId = deliverable.deliverableId;
      if (deliverableGroups.has(deliverableId)) {
        deliverableGroups.get(deliverableId)!.push(deliverable);
      } else {
        deliverableGroups.set(deliverableId, [deliverable]);
      }
    });

    const mappedDeliverables: OrderFormDeliverableModel[] = [];
    deliverableGroups.forEach((group, deliverableId) => {
      const mappedDeliverable = this.mapDeliverable(group[0]);

      mappedDeliverable.quantity = group.length;
      mappedDeliverable.standardPrice =
        group[0].standardPrice !== 0 ? group[0].standardPrice : group[0].actualPrice;
      mappedDeliverable.subTotal = mappedDeliverable.quantity * mappedDeliverable.standardPrice;

      mappedDeliverables.push(mappedDeliverable);
    });

    return mappedDeliverables;
  }

  private groupDeliverablesByPackage = (
    deliverables: OrderRequestDeliverableDTO[]
  ): Map<string, OrderRequestDeliverableDTO[]> => {
    return deliverables.reduce((map, deliverable) => {
      const packageId = deliverable.packageId;
      if (packageId) {
        if (!map.has(packageId)) {
          map.set(packageId, []);
        }
        map.get(packageId).push(deliverable);
      }
      return map;
    }, new Map<string, OrderRequestDeliverableDTO[]>());
  };

  private async getSubCustomerName(subCustomerId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.customerService.get(subCustomerId).subscribe({
        next: (customerFromApi: CustomersDto) => {
          const customerName = customerFromApi.name;
          resolve(customerName);
        },
        error: (err: any) => {
          console.log(err);
          reject(err);
        },
      });
    });
  }

  private async mapDeliverablesToPackage(
    packageId: string,
    deliverables: OrderRequestDeliverableDTO[]
  ): Promise<OrderFormPackageModel> {
    const packageName = deliverables[0].packageName || '';

    // Group deliverables by deliverableId to count occurrences
    const deliverableGroups: Map<string, OrderRequestDeliverableDTO[]> = new Map();
    deliverables.forEach(deliverable => {
      const deliverableId = deliverable.deliverableId;
      if (deliverableGroups.has(deliverableId)) {
        deliverableGroups.get(deliverableId)!.push(deliverable);
      } else {
        deliverableGroups.set(deliverableId, [deliverable]);
      }
    });

    // Count the occurrences of each deliverable and get the unique deliverables
    const uniqueDeliverables: OrderRequestDeliverableDTO[] = [];
    let packageQuantity = 0;
    deliverableGroups.forEach((group, deliverableId) => {
      const deliverable = group[0];
      deliverable.quantity = group.length;
      uniqueDeliverables.push(deliverable);
      packageQuantity = group.length;
    });

    // Fetch the package price from the API
    const packagePrice = await this.getPackagePrice(packageId);

    const packageModel: OrderFormPackageModel = {
      packageId: packageId,
      packageName: packageName,
      packagePrice: packagePrice,
      packageSubtotal: 0,
      quantity: packageQuantity,
      deliverables: uniqueDeliverables.map(d => this.mapDeliverable(d)),
      selected: true,
    };

    return packageModel;
  }

  private async getPackagePrice(packageId: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.packageService.get(packageId).subscribe({
        next: (packageFromApi: PackageDto) => {
          const packagePrice = packageFromApi.price.toString();
          resolve(packagePrice);
        },
        error: (err: any) => {
          console.log(err);
          reject(err);
        },
      });
    });
  }

  private mapDeliverable(deliverable: OrderRequestDeliverableDTO): OrderFormDeliverableModel {
    return {
      deliverableId: deliverable.deliverableId,
      deliverableName: deliverable.deliverableName || '',
      productId: deliverable.productId || '',
      productName: deliverable.productName || '',
      productDescription: deliverable.productName || '',
      packageId: deliverable.packageId || '',
      packageName: deliverable.packageName || '',
      standardPrice: deliverable.standardPrice || 0,
      actualPrice: deliverable.actualPrice || 0,
      quantity: deliverable.quantity || 0,
      subTotal: deliverable.subTotal || 0,
      industryDescription: deliverable.industryDescription || '',
      industryMultiplexor: deliverable.multiplier || 0,
      fields: this.mapFields(deliverable.fields),
      sameConfiguration: deliverable.sameConfiguration || true,
      detailedAttributes: deliverable.detailedAttributes?.map(attribute =>
        this.mapFields(attribute)
      ),
    };
  }

  private mapFields(fields: OrderRequestDeliverableFieldDTO[]): OrderFormDeliverableFieldModel[] {
    return fields.map(field => ({
      id: field.fieldId,
      placeholder: field.fieldName,
      numericValue: parseFloat(field.fieldNumericValue) || 0,
      stringValue: field.fieldStringValue || '',
      boolValue: false,
      fileValue: '',
      defaultValue: '',
      isRequired: false,
      listValues: '',
      maxTextLength: 0,
      typeDescription: field.fieldTypeDescription,
      typeCode: field.fieldTypeCode,
      fieldControlName: '',
      loadingFile: false,
      attributeId: '',
      captureDeliverableId: '',
    }));
  }

  private fillStep1Form(data: OrderRequestDTO, formData: CopyMissionForm) {
    const formValue = {
      customerDescription: data.customerName,
    };

    if (formData.client) {
      formValue['customerContactId'] = data.customerContact.contactId;
      formValue['customerContactFirstName'] = data.customerContact.firstName;
      formValue['customerContactLastName'] = data.customerContact.lastName;
      formValue['customerContactEmail'] = data.customerContact.email;
      formValue['customerContactNumber'] = data.customerContact.phone;
      if (data.subCustomer && data.subCustomer.id) {
        formValue['subcustomerId'] = data.subCustomer.id;
      }
      if (data.subCustomer && data.subCustomer.name) {
        formValue['subclientName'] = data.subCustomer.name;
      }
    }

    if (formData.additionalContacts) {
      const mappedAdditionalContacts = this.mapAdditionalContacts(
        data.portfoliocontacts,
        data.customer.id
      );

      this.orderModel.additionalContacts = mappedAdditionalContacts;
      formValue['additionalContacts'] = mappedAdditionalContacts;
    }

    this.formStepOne.patchValue(formValue);
  }

  private fillStep2Form(data: OrderRequestDTO, formData: CopyMissionForm) {
    const formValue = {};

    if (formData.orderPriority) {
      formValue['priorityId'] = data.priorityId;
    }

    if (formData.assetOrderNumber) {
      formValue['missionAssetOrder'] = data.missionAssetOrder;
    }

    this.formStepTwo.patchValue(formValue);
  }

  private fillStep3Form(industryId: string, formData: CopyMissionForm) {
    if (formData.productsPackages) {
      this.formStepThree.patchValue({
        industryid: industryId,
      });
    }
  }

  onStepChange(event: StepperSelectionEvent) {
    if (event.previouslySelectedIndex === 0 && event.selectedIndex === 1) {
      this.mapStep1FormToModel();
    }

    if(event.previouslySelectedIndex === 1 && event.selectedIndex === 2){
      this.portfolioValid();
    }
  }

  portfolioValid() {
    if(this.orderModel.portfolioExists || this.orderModel.orderSites?.length > 1 || this.orderModel.isBulkImport ){
      if(this.orderModel.portfolioId == null && this.orderModel.portfolioName != null){
        const confirmationMessage = this.localizationService.instant(
          'missionsService::PortfolioValidationMessage'
        ).replace('{0}', this.orderModel.portfolioName);
        const confirmationTitle = this.localizationService.instant(
          'missionsService::PortfolioValidationTitle'
        );

        this.confirmation.warn(confirmationMessage, confirmationTitle).subscribe(status => {
          if (status !== Confirmation.Status.confirm) {
            this.stepper.previous();
          }
        });
      }
    }

    return true;
  }

  mapStep1FormToModel() {
    const step1FormValue = this.formStepOne.getRawValue();

    this.orderModel.customerId = step1FormValue.customerId;
    this.orderModel.customerDescription = step1FormValue.customerDescription;
    this.orderModel.customerContactId = step1FormValue.customerContactId;
    this.orderModel.customerContactFirstName = step1FormValue.customerContactFirstName;
    this.orderModel.customerContactLastName = step1FormValue.customerContactLastName;
    this.orderModel.customerContactEmail = step1FormValue.customerContactEmail;
    this.orderModel.customerContactNumber = step1FormValue.customerContactNumber;
    this.orderModel.subcustomerId = step1FormValue.subcustomerId;
    this.orderModel.subclientName = step1FormValue.subclientName;
    this.orderModel.additionalContacts = step1FormValue.additionalContacts;
  }

  loadSlaByCustomer() {
    this.formStepOne.get('customerId').valueChanges.subscribe(cid => {
      this.slaService.get(cid).subscribe(d => (this.SLAOptions = d));
    });
  }

  updateKmlData(kmlDataInfo: any) {
    this.kmlData = kmlDataInfo;
  }

  updateKmlDataPkg(kmlDataInfopkg: any) {
    this.kmlDataPkg = [...kmlDataInfopkg];
  }

  updateAttDetails(attDetails: any) {
    this.attributesDetails = [...attDetails];
  }

  getData() {
    return this.kmlData;
  }
}
