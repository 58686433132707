<div class="file-upload-frame" [ngClass]="{'border-1': includeBorder}">
  <div>
    <input
      type="file"
      (change)="onFileSelected($event)"
      #fileInput
      hidden
      [multiple]="allowMultiple"
      [accept]="allowedExtensions"
      [disabled]="disabled"
    />
    <button mat-raised-button (click)="fileInput.click()" class="custom-select-files {{disabled ? 'disabled': ''}}" [disabled]="disabled">
      Choose Files
    </button>
  </div>
  <div class="right-container" *ngIf="showFilesAttached">
    <div *ngIf="selectedFiles?.length === 0" class="no-files-chosen">No files chosen</div>
    <div *ngIf="selectedFiles?.length > 0" class="files-container">
      <mat-chip-list>
        <mat-chip
          *ngFor="let file of selectedFiles"
          [removable]="true"
          (removed)="removeFile(file)"
          class="me-2 mt-2"
        >
          {{ file.name }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </div>
  </div>
</div>
