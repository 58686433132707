import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormBuilder,
  ValidationErrors,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { DirectoryContentDto } from '@volo/abp.ng.file-management/proxy';
import { BaseModalComponent } from '../base-modal.component';
import { RenameModalService } from './rename-modal.service';
import { of } from 'rxjs';

@Component({
  selector: 'abp-rename-modal',
  templateUrl: './rename-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [RenameModalService],
})
export class RenameModalComponent extends BaseModalComponent implements OnInit {
  form = this.fb.group({
    name: ['', Validators.required, this.IsValidRename()],
  });

  // tslint:disable-next-line: variable-name
  _contentToRename: DirectoryContentDto;
  @Input()
  set contentToRename(val: DirectoryContentDto) {
    this._contentToRename = val;
    this.form.controls.name.patchValue(val?.name);
  }

  get contentToRename() {
    return this._contentToRename;
  }

  @Output() contentToRenameChange = new EventEmitter<DirectoryContentDto>();

  constructor(private service: RenameModalService, private fb: UntypedFormBuilder) {
    super();
  }

  ngOnInit(): void {}

  saveAction() {
    return this.service.rename({
      ...this.contentToRename,
      ...this.form.value,
    });
  }

  shouldSave() {
    return this.form.valid;
  }

  clear() {
    this.form.reset();
    this.contentToRename = null;
    this.contentToRenameChange.emit(this.contentToRename);
  }

  IsValidRename(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const previousFileName = this._contentToRename.name;
      const previousfileNameComponents = previousFileName.split('.');
      const previosFileExtension = previousfileNameComponents
        ? previousfileNameComponents[previousfileNameComponents.length - 1]?.toLowerCase()
        : null;

      const fileNameComponents = control.value?.split('.');
      const fileExtension = fileNameComponents
        ? fileNameComponents[fileNameComponents.length - 1]?.toLowerCase()
        : null;

      const isValid =
        previosFileExtension !== null &&
        fileExtension !== null &&
        previosFileExtension === fileExtension;

      return of({ invalidName: !isValid });
    };
  }
}
