import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from '@angular/core';

import { PackageDto } from '../../../models/products-deliverables/package-dto';
import { DeliverableDto } from '../../../models/products-deliverables/deliverable-dto';
import { ActivatedRoute } from '@angular/router';
import { ProductsDeliverablesService } from '../../../services/products-deliverables.service';
import { forkJoin, Observable, Subscription } from 'rxjs';
import {
  DirectoryDescriptorDto,
  FilterMission,
  ZipDownloadResult,
} from '@volo/abp.ng.file-management/proxy';
import { OrderFormDeliverableModel } from '../../../components/orders/model/order-form-deliverable.model';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { OrderRequestDeliverableDTO } from '../../../components/orders/dto/order-request-deliverable.dto';
import { OrderRequestDeliverableFieldDTO } from '../../../components/orders/dto/order-request-deliverable-field.dto';
import { MissionFlowService } from '../../../services/mission-flow.service';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { MissionsService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/basics';
import {
  CaptureDeliverablesAttributesDto,
  MissionsDto,
} from '../../../../../../missions-service/src/lib/proxy/missions-service/basics';
import {
  ActionOrderConfiguredDto,
  GetStatusesInput,
  StatusDto,
} from '../../../../../../core-service/src/lib/proxy/core-service/lookups';
import { ConfigStateService, LocalizationService } from '@abp/ng.core';
import {
  ActionOrderService,
  StatusService,
} from '../../../../../../core-service/src/lib/proxy/core-service/controllers/lookups';
import { MissionFlowDto } from '../../models/mission-flow-dto';
import { OAuthService } from 'angular-oauth2-oidc';
import {
  DeliverableStatus,
  EnumDeliverableStatusOptions,
} from '../../models/deliverable-status.enum';
import { ColumnColoringRules } from '../../../components/columns/components/column-stylizer/column-stylizer.component';
import { NotificationBroadcastService } from '../../../services/NotificationBroadcast.service';
import { enumWebBackgroundNotificationKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-key.enum';
import { enumWebBackgroundNotificationSubKey } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-background-notification-subkey.enum';
import { OrderFormDetailsModel } from '../../../components/orders/model/order-form-detail.model';
import { MissionDeliverablesModalComponent } from './mission-deliverables-modal/mission-deliverables-modal.component';
import { ValidationsResultsModalComponent } from './validations-results-modal/validations-results-modal.component';
import { OrderFormPackageModel } from '../../../components/orders/model/order-form-package-model';
import { LoadingOverlayService } from '../../../services/loading/loading.service';
import {
  AttributeValidationDto,
  ExifDataResultsDto,
  type GetAttributeValidationsInput,
  GetPackageDetailsInput,
  PackageTableDto,
  AutomaticQaValidationResultDto,
} from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { ProductDeliverablesService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/product-deliverables.service';
import type { DeliverablesTableDto } from '../../../../../../missions-service/src/lib/proxy/missions-service/relationals';
import { DirectoryDescriptorService } from '../../../../../../file-management/proxy/src/lib/proxy/file-management/directories/directory-descriptor.service';
import { FileDescriptorService } from '../../../../../../file-management/proxy/src/lib/proxy/file-management/files/file-descriptor.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {
  FileDumpingSessionDto,
  FileDumpingSessionModel,
} from '../../../../../../file-management/proxy/src/lib/proxy/file-management/fileDumpingSessions/models';
import { FileDumpingSessionService } from '../../../../../../file-management/proxy/src/lib/proxy/file-management/fileDumpingSessions/file-dumping-session.service';
import { DatePipe } from '@angular/common';
import { tap } from 'rxjs/operators';
import { FilesDumpingSessionExternalServices } from 'projects/file-management/proxy/src/lib/proxy/file-management/fileDumpingSessions/files-dumping-session-external-services.enum';
import {
  fileDumpingSessionStatus,
  FilesDumpingSessionStatus,
} from 'projects/file-management/proxy/src/lib/proxy/file-management/fileDumpingSessions/files-dumping-session-status.enum';
import { DeliverableAttributesComponent } from '../../../shared/deliverable-attributes/deliverable-attributes.component';
import { DeliverableAttribute } from '../../../shared/deliverable-attributes-list/deliverable-attribute.interface';
import { DeliverableAttributeUpdateDto } from '../../../models/products-deliverables/deliverable-attribute-update-dto';
import { OrderFormDeliverableFieldModel } from '../../../components/orders/model/order-form-deliverable-field.model';
import { WebNotificationContentsService } from 'projects/notifications-service/src/lib/proxy/notifications-service/controllers/basics';
import { WebNotificationContentsCreateDto } from 'projects/notifications-service/src/lib/proxy/notifications-service/basics/models';
import { enumWebNotificationType } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-web-notification-type.enum';
import { enumState } from 'projects/notifications-service/src/lib/proxy/notifications-service/shared/enum-state.enum';
import { PilotService } from 'projects/pilots-service/src/lib/proxy/pilots-service/controllers/basics';
import { AutomaticQaStatus, AutomaticQaStatusOptions } from '../../models/automatic-qa-status.enum';
import { AttributeValidationService } from '../../../../../../missions-service/src/lib/proxy/missions-service/controllers/relationals/attribute-validation.service';

@Component({
  selector: 'app-deliverables',
  templateUrl: './deliverables.component.html',
  styleUrls: ['./deliverables.component.scss'],
})
export class DeliverablesComponent implements OnInit, OnDestroy, OnChanges {
  private readonly defaultQuantity = 1;

  @Input() missionStatusId: string = '';
  @Input() missionId: string;

  @ViewChild('fileModal') modal: TemplateRef<any>;
  formFile: FormGroup;
  fileDumpingSessionDto?: FileDumpingSessionDto;
  fileDumpingSessionModel: FileDumpingSessionModel;
  mappedFileSessionModel: FileDumpingSessionModel;

  deliverables: DeliverableDto[] = [];
  packages: PackageDto[] = [];
  private subscriptions = new Subscription();
  folderMissions: FilterMission;
  selectedDeliverable: DeliverableDto;
  enumStatus = fileDumpingSessionStatus;
  mission: MissionFlowDto;
  currentToken: string;
  currentAttributes: Array<CaptureDeliverablesAttributesDto>;
  deliverablesFromCapture: DeliverableDto[] = [];
  automaticQaValidationResults: AutomaticQaValidationResultDto[] = [];

  // Actions visibility variables
  currentActionConfiguration: Array<ActionOrderConfiguredDto>;
  currentUserRoles: Array<string>;
  public readonly DELIVERABLE_ADD_MODIFY_ACTION = 'deliverable_add_modify';
  public readonly DELIVERABLE_TABLE_SHOW_FILES_ACTION = 'deliverable_table_show_files';
  public readonly DELIVERABLE_TABLE_VIEW_DETAILS_ACTION = 'deliverable_table_view_details';
  public readonly DELIVERABLE_TABLE_DELETE_ACTION = 'deliverable_table_delete';
  public readonly DELIVERABLE_TABLE_REJECT_ACTION = 'reject_deliverable';
  public readonly DELIVERABLE_TABLE_ACCEPT_ACTION = 'accept_deliverable';
  public readonly DELIVERABLE_EDIT_ATTRIBUTES = 'edit_deliverable_attributes';
  public readonly UPDATE_DELIVERABLE_STATUS_IN_CAPTURES = 'update_deliverable_status_in_captures';

  public readonly DELIVERABLE_ACCEPT_QUESTION = 'Are you sure you want to accept the deliverable?';
  public readonly DELIVERABLE_ACCEPT_TITLE = 'Accept deliverable';
  public readonly ALTITUDE_ATTR_NAME = 'Altitude';
  public readonly GRIMBALANGLE_ATTR_NAME = 'Grimbalangle';
  public readonly OVERLAP_ATTR_NAME = 'Overlap';

  allowDeliverableAddModifyAction = false;
  allowDeliverableTableShowFilesAction = false;
  allowDeliverableTableViewDetailsAction = false;
  allowDeliverableTableDeleteAction = false;
  allowDeliverableTableRejectAction = false;
  allowDeliverableTableAcceptAction = false;
  allowDeliverableEditAttributesAction = false;
  allowUpdateDeliverableStatusInCaptures = false;
  showFileManager = false;

  private readonly loadingDelay: number = 3000;
  // Actions visibility variables
  statusRules: ColumnColoringRules[] = [];
  automaticQaRules: ColumnColoringRules[] = [];
  currentStatuses: Array<StatusDto>;
  packagesbyCustumer: OrderFormPackageModel[] = [];
  genericPackages: OrderFormPackageModel[] = [];
  productsbyIndustry: OrderFormDeliverableModel[] = [];

  packagesbyCustumerLoaded: PackageTableDto[] = [];
  genericPackagesLoaded: PackageTableDto[] = [];
  productsbyIndustryLoaded: DeliverablesTableDto[] = [];

  industryId: string;
  readyToAddItems: boolean;
  filesDumpingSessionInProgress = false;
  currentUserId: string;

  constructor(
    private route: ActivatedRoute,
    private productsDeliverablesService: ProductsDeliverablesService,
    private missionFlowService: MissionFlowService,
    private confirmation: ConfirmationService,
    private dialogService: MatDialog,
    private snackBar: MatSnackBar,
    private cdRef: ChangeDetectorRef,
    private stateService: ConfigStateService,
    private readonly actionOrderService: ActionOrderService,
    private oAuthService: OAuthService,
    private missionService: MissionsService,
    private notificationBroadcastService: NotificationBroadcastService,
    private readonly statusService: StatusService,
    public loadingService: LoadingOverlayService,
    private productDeliverablesService: ProductDeliverablesService,
    private readonly directoryService: DirectoryDescriptorService,
    private readonly fileDescriptorService: FileDescriptorService,
    private readonly fileDumpingSessionService: FileDumpingSessionService,
    private fb: FormBuilder,
    private datePipe: DatePipe,
    private config: ConfigStateService,
    private localizationService: LocalizationService,
    private readonly _notificationsService: WebNotificationContentsService,
    private readonly _pilotsService: PilotService,
    public readonly attributeValidationService: AttributeValidationService,
  ) {}

  deliverableColumns = [
    { name: 'Deliverable', prop: 'deliverableName' },
    { name: 'Quantity', prop: 'quantity' },
    { name: 'Product', prop: 'product' },
    { name: 'Package', prop: 'package' },
    { name: 'Actions' },
    { name: 'DeliverableId', prop: 'deliverableId' },
  ];

  ngOnInit() {
    this.fetchMissionDeliverableAttributesData();
    this.fetchMissionData();
    this.buildDeliverableStatusRules();
    this.buildAutomaticQaStatusRules();
    this.getMissionStatuses();
    this.getActionConfiguration();

    this.loadMissionPackagesAndProducts();

    this.currentUserId = this.stateService.getDeep('currentUser')?.id;

    this.currentToken = this.oAuthService.getAccessToken();

    this.subscriptions.add(
      this.notificationBroadcastService.backgroundNotification$.subscribe(notif => {
        if (
          notif.notificationKey ==
          enumWebBackgroundNotificationKey.EventForMissionDetailDeliverableGrid
        ) {
          if (notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForRow) {
            if (
              notif.itemId &&
              notif.itemId.toLocaleLowerCase() == this.mission.missionId.toLocaleLowerCase() &&
              notif.extraArgument.status
            ) {
              let deliverable = this.deliverablesFromCapture.find(
                x =>
                  x.deliverableId.toLocaleLowerCase() ==
                    notif.extraArgument.deliverableId.toLocaleLowerCase() &&
                  x.orderDetailId.toLocaleLowerCase() ==
                    notif.extraArgument.orderDetailId.toLocaleLowerCase(),
              );

              if (!deliverable) return;

              deliverable.status = notif.extraArgument.status;

              this.fillDeliverableStatus(deliverable);
            }
          }

          if (notif.notificationSubKey == enumWebBackgroundNotificationSubKey.EventForTable) {
            if (
              notif.itemId &&
              notif.itemId.toLocaleLowerCase() == this.mission.missionId.toLocaleLowerCase() &&
              notif.extraArgument.deliverableInfo
            ) {
              if (!this.selectedDeliverable) {
                this.deliverables.forEach(deliverable => {
                  let newInfo = notif.extraArgument.deliverableInfo.find(
                    y =>
                      y.deliverableId.toLocaleLowerCase() ==
                        deliverable.deliverableId.toLocaleLowerCase() &&
                      y.orderDetailId.toLocaleLowerCase() ==
                        deliverable.orderDetailId.toLocaleLowerCase(),
                  );

                  if (!newInfo) return;

                  deliverable.status = newInfo.status;
                  deliverable.canReview = newInfo.canReview;
                });

                this.fillDeliverablesStatus(this.deliverables);
              } else {
                this.fetchDeliverables();
              }
            }
          }
        }
      }),
    );
  }
  action: any;
  acceptAction: any;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.missionStatusId) {
      this.configureActionsVisibility();
    }
  }
  setAction(act: any) {
    this.action = act;
  }

  setAcceptAction(act: any) {
    this.acceptAction = act;
  }

  private buildDeliverableStatusRules() {
    EnumDeliverableStatusOptions.forEach(pr => {
      var newRule: ColumnColoringRules = {
        columnValue: pr.value,
        softValue: pr.key.toLowerCase().includes('awaiting')
          ? this.localizationService.instant('missionsService::AwaitingUpload')
          : pr.key,
        valueClass: `badge badge-deliverable-${pr.key.toLowerCase()}`,
      };
      this.statusRules.push(newRule);
    });
  }

  private buildAutomaticQaStatusRules() {
    AutomaticQaStatusOptions.forEach(option => {
      const newRule: ColumnColoringRules = {
        columnValue: option.value,
        softValue: this.localizationService.instant(`missionsService::${option.key}`),
        valueClass: `badge badge-automatic-qa-${option.key.toLowerCase()}`,
      };
      this.automaticQaRules.push(newRule);
    });
  }

  fetchDeliverables(deliverable?: DeliverableDto): void {
    this.productsDeliverablesService.getDeliverableTableByMission(this.missionId).subscribe({
      next: response => {
        this.deliverables = response;
        this.deliverablesFromCapture = this.deliverables;
        this.fillDeliverablesStatus(this.deliverablesFromCapture);
        this.mapAttributes(this.deliverablesFromCapture);

        // 18683: By default we initialize everything as NotTested.
        this.deliverablesFromCapture.forEach(d => {
          d.automaticQa = AutomaticQaStatus.NotTested;
          d.filesCount = 0;

          this.directoryService
            .getRootFolderDetailsCapWithOut(this.missionId, d.deliverableId, d.orderDetailId)
            .subscribe(res => {
              d.filesCount = res.itemsCount;
            });
        });

        // 18683: We test the files inside when applicable and set the status accordingly.
        this.checkAndPerformAutomaticQA(this.deliverablesFromCapture);

        if (deliverable) {
          this.missionFlowService.getCapturesWithDeliverables(this.missionId).subscribe({
            next: response => {
              const pilotsFromLastCapture = response.reduce(
                (maxCap, cap) => (cap.captureNumber > maxCap.captureNumber ? cap : maxCap),
                response[0],
              ).pilots;
              for (let pilot of pilotsFromLastCapture) {
                this._pilotsService.get(pilot.id).subscribe({
                  next: response => {
                    deliverable.status =
                      this.deliverablesFromCapture.find(
                        d => d.deliverableId === deliverable.deliverableId,
                      )?.status ?? deliverable.status;
                    this.sendNotificationToPilot(response.email, deliverable, '');
                  },
                  error: error => console.error(`Unable to get pilot ${pilot.id}:\n`, error),
                });
              }
            },
            error: error => console.error('Unable to fetch capture with devliverables:\n', error),
          });
        }
      },
      error: error => console.log(error),
    });
  }

  private sendNotificationToPilot(
    email: string,
    deliverable: DeliverableDto,
    message: string,
  ): void {
    const args = {
      deliverableId: deliverable.deliverableId,
      status: deliverable.status,
    };
    let notification: WebNotificationContentsCreateDto = {
      notificationId: '',
      message: message,
      userId: email,
      type: enumWebNotificationType.ByUser,
      state: enumState.Enabled,
      extraArgument: JSON.stringify(args),
    };
    this._notificationsService.backgroundCreate(notification).subscribe({
      next: _ => {},
      error: error => console.error(`Unable to send background message:\n`, error),
    });
  }

  private fillDeliverablesStatus(deliverables: Array<DeliverableDto>) {
    deliverables.forEach(x => {
      this.fillDeliverableStatus(x);
    });
  }

  private fillDeliverableStatus(deliverable: DeliverableDto) {
    deliverable.statusDescription = DeliverableStatus[deliverable.status || 0];
  }

  private getActionConfiguration(): void {
    this.currentUserRoles = this.stateService.getDeep('currentUser.roles');
    this.actionOrderService.filterByRoles(this.currentUserRoles).subscribe(res => {
      this.currentActionConfiguration = res;
      this.configureActionsVisibility();
    });
  }

  navigateView(row) {}

  openInfo(row) {}

  openDeliverableAttributesForm(row: DeliverableDto) {
    row.sameConfiguration = true;

    const dialogData = {
      title: 'Edit Attributes',
      deliverableData: row,
      readonly: !row.canReview,
      isEdition: true,
      fromMissionDetail: true,
    };

    const dialogRef = this.dialogService.open(DeliverableAttributesComponent, {
      data: dialogData,
      disableClose: true,
      width: '65%',
    });
    const dialogSub = dialogRef.afterClosed().subscribe((attributesData: DeliverableAttribute) => {
      if (!attributesData) {
        return;
      }

      let updatedArray = attributesData.fields.map(attr => {
        return <DeliverableAttributeUpdateDto>{
          id: attr.id,
          stringValue: attr.stringValue,
          attributeId: attr.attributeId,
          captureDeliverableId: attr.captureDeliverableId,
        };
      });

      this.productDeliverablesService.updateAttributeValues(updatedArray).subscribe({
        next: () => {
          this.fetchDeliverables();

          this.snackBar.open('Attributes updated.', 'Close', {
            duration: this.loadingDelay,
          });
        },
        error: error => console.log(error),
      });
    });

    this.subscriptions.add(dialogSub);
  }

  deleteDeliverable(row: DeliverableDto) {
    let rowsAffected = 0;
    if (row.packageId) {
      let deliverablesToDelete = this.deliverablesFromCapture.filter(
        x => x.packageId == row.packageId,
      );
      rowsAffected = deliverablesToDelete.length;
    } else {
      let deliverablesToDelete = this.deliverables.filter(
        x => x.productId == row.productId && !x.packageId,
      );
      rowsAffected = deliverablesToDelete.length;
    }

    if (this.deliverablesFromCapture.length <= 1) {
      this.snackBar.open('A mission must have at least one deliverable.', 'OK', {
        duration: this.loadingDelay,
      });
    } else {
      if (this.calculateProductCount() <= 0 && this.calculatePackageCount() <= 1) {
        this.snackBar.open('A mission must have at least one deliverable.', 'OK', {
          duration: this.loadingDelay,
        });
      } else {
        let warnMessage = `Removing this item you will be removing ${rowsAffected} deliverables that are in the same ${
          row.packageId ? 'package' : 'product'
        }. Are you sure?`;

        this.confirmation
          .warn(warnMessage, 'missionsService::AreYouSure', {
            messageLocalizationParams: [],
          })
          .subscribe(status => {
            if (status === Confirmation.Status.confirm) {
              this.loadingService.showOverlay();
              let deliverabledto: OrderRequestDeliverableDTO = {
                deliverableId: row.deliverableId,
                deliverableName: row.deliverableName,
                productId: row.productId,
                productName: row.productName,
                packageId: row.packageId,
                packageName: row.packageName,
                fields: [],
              };
              this.missionFlowService
                .deleteDeliverableToMission(this.missionId, deliverabledto)
                .subscribe({
                  next: () => {
                    this.loadingService.hideOverlay();
                    this.fetchDeliverables();

                    this.snackBar.open('Deliverable removed.', 'Close', {
                      duration: this.loadingDelay,
                    });
                  },
                  error: err => console.log(err),
                });
            }
          });
      }
    }
  }

  calculateProductCount(): number {
    let productCount: number = 0;
    this.deliverablesFromCapture.forEach(item => {
      if (!item.packageId) {
        productCount++;
      }
    });
    return productCount;
  }

  calculatePackageCount(): number {
    let packageCount: number = 0;
    let packageId: string = '';
    this.deliverablesFromCapture.forEach(item => {
      if (item.packageId) {
        if (item.packageId != packageId) {
          packageCount++;
          packageId = item.packageId;
        }
      }
    });
    return packageCount;
  }

  addDeliverable(): void {
    this.genericPackages.forEach(x => this.clearPackageAttributes(x));

    this.productsbyIndustry.forEach(x => this.clearProductAttributes(x));

    const dialogRef = this.dialogService.open(MissionDeliverablesModalComponent, {
      data: {
        title: 'Add Packages/Products',
        missionId: this.missionId,
        packagesbyCustumer: this.packagesbyCustumer,
        genericPackages: this.genericPackages.filter(
          x => !!x.industries?.find(t => t == this.industryId),
        ),
        productsbyIndustry: this.productsbyIndustry,
      },
      disableClose: true,
      minWidth: '500px',
      minHeight: '500px',
    });

    const dialogSub = dialogRef.afterClosed().subscribe((data: OrderFormDetailsModel) => {
      if (data) {
        this.loadingService.showOverlay();
        this.missionFlowService
          .updateDeliverablesToMission(this.missionId, this.buildDetailsDeliverablesDto(data))
          .subscribe({
            next: response => {
              this.loadingService.hideOverlay();
              this.fetchDeliverables();
            },
            error: err => console.log(err),
          });
      }
    });

    this.subscriptions.add(dialogSub);
  }

  clearPackageAttributes(currentPackage: OrderFormPackageModel) {
    currentPackage.deliverables.forEach((d: OrderFormDeliverableModel) => {
      d.fields.forEach((f: OrderFormDeliverableFieldModel) => {
        f.numericValue = 0;
        f.stringValue = '';
        f.boolValue = false;
      });

      d.detailedAttributes = [];
    });

    currentPackage.selected = false;
    currentPackage.quantity = this.defaultQuantity;
  }

  clearProductAttributes(currentProduct: OrderFormDeliverableModel) {
    currentProduct.fields.forEach((f: OrderFormDeliverableFieldModel) => {
      f.numericValue = 0;
      f.stringValue = '';
      f.boolValue = false;
    });

    currentProduct.selected = false;
    currentProduct.quantity = this.defaultQuantity;
    currentProduct.detailedAttributes = [];
  }

  buildPackagesAndProducts(): void {
    this.packagesbyCustumer = [];
    this.genericPackages = [];
    this.productsbyIndustry = [];

    for (let pc of this.packagesbyCustumerLoaded) {
      this.packagesbyCustumer.push(this.buildPackageModel(pc));
    }

    for (let gp of this.genericPackagesLoaded) {
      this.genericPackages.push(this.buildPackageModel(gp));
    }

    for (let dl of this.productsbyIndustryLoaded) {
      let deli: OrderFormDeliverableModel = {
        deliverableId: dl.deliverableId,
        deliverableName: dl.deliverableName,
        productId: dl.productId,
        productName: dl.productName,
        productDescription: dl.productDescription,
        packageId: dl.packageId,
        packageName: dl.packageName,
        standardPrice: dl.standardPrice,
        actualPrice: dl.actualPrice,
        quantity: 1,
        industryId: dl.industryId,
        industryDescription: dl.industryDescription,
        industryMultiplexor: dl.multiplier,
        fields: dl.fields.map(fd => ({
          id: fd.id,
          placeholder: fd.placeholder,
          numericValue: fd.numericValue,
          stringValue: fd.stringValue,
          boolValue: fd.boolValue,
          fileValue: fd.fileValue,
          defaultValue: fd.defaultValue,
          isRequired: fd.isRequired,
          listValues: fd.listValues,
          maxTextLength: fd.maxTextLength,
          typeDescription: fd.typeDescription,
          typeCode: fd.typeCode,
        })),
      };
      this.productsbyIndustry.push(deli);
    }

    this.readyToAddItems = true;
  }

  buildDetailsDeliverablesDto(orderDetail: OrderFormDetailsModel): OrderRequestDeliverableDTO[] {
    let deliverableList: OrderRequestDeliverableDTO[] = [];

    orderDetail.packagesSelected.forEach((p: OrderFormPackageModel) => {
      p.deliverables.forEach((dv: OrderFormDeliverableModel) => {
        let newDto = this.buildDeliverableDto(dv);
        deliverableList.push(newDto);
      });
    });

    orderDetail.productsSelected.forEach((pr: OrderFormDeliverableModel) => {
      let newDto = this.buildDeliverableDto(pr);
      deliverableList.push(newDto);
    });

    return deliverableList;
  }

  buildDeliverableDto(deliverable: OrderFormDeliverableModel): OrderRequestDeliverableDTO {
    let newDeliverable: OrderRequestDeliverableDTO = {
      deliverableId: deliverable.deliverableId,
      deliverableName: deliverable.deliverableName,
      productId: deliverable.productId,
      productName: deliverable.productName,
      packageId: deliverable.packageId,
      packageName: deliverable.packageName,
      industryId: deliverable.industryId,
      industryDescription: deliverable.industryDescription,
      multiplier: deliverable.industryMultiplexor,
      standardPrice: deliverable.standardPrice,
      actualPrice: deliverable.actualPrice,
      quantity: deliverable.quantity,
      subTotal: deliverable.subTotal,
      fields: [],
      sameConfiguration: deliverable.sameConfiguration,
      detailedAttributes: [],
    };

    if (!deliverable.fields) deliverable.fields = [];

    for (const f of deliverable.fields) {
      const newfield: OrderRequestDeliverableFieldDTO = {
        fieldId: f.id,
        fieldName: f.placeholder,
        fieldNumericValue: f.stringValue || '',
        fieldStringValue: f.stringValue || '',
      };
      newDeliverable.fields.push(newfield);
    }

    if (!deliverable.detailedAttributes) deliverable.detailedAttributes = [];

    for (const attributes of deliverable.detailedAttributes) {
      let orderRequestFieldsDto: Array<OrderRequestDeliverableFieldDTO> = [];
      for (const f of attributes) {
        const newfield: OrderRequestDeliverableFieldDTO = {
          fieldId: f.id,
          fieldName: f.placeholder,
          fieldNumericValue: f.stringValue || '',
          fieldStringValue: f.stringValue || '',
        };
        orderRequestFieldsDto.push(newfield);
      }

      newDeliverable.detailedAttributes.push(orderRequestFieldsDto);
    }
    return newDeliverable;
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }

  goBack() {
    this.selectedDeliverable = undefined;
    this.folderMissions = undefined;
    this.cdRef.detectChanges();
  }

  viewFiles(viewFile: DeliverableDto) {
    this.folderMissions = undefined;
    this.cdRef.detectChanges();

    this.folderMissions = {
      captureId: viewFile.lastCaptureId,
      deliverableId: viewFile.deliverableId,
      missionId: this.missionId,
      orderDetailId: viewFile.orderDetailId,
    };
    this.selectedDeliverable = viewFile;

    this.cdRef.detectChanges();

    setTimeout(() => {
      this.showFileManager = true;
    }, this.loadingDelay);
  }

  private configureActionsVisibility(): void {
    if (!this.missionStatusId) return;

    this.allowDeliverableAddModifyAction = !!this.currentActionConfiguration?.find(
      t => t.code == this.DELIVERABLE_ADD_MODIFY_ACTION && t.statusId == this.missionStatusId,
    );
    this.allowDeliverableTableShowFilesAction = !!this.currentActionConfiguration?.find(
      t => t.code == this.DELIVERABLE_TABLE_SHOW_FILES_ACTION && t.statusId == this.missionStatusId,
    );
    this.allowDeliverableTableViewDetailsAction = !!this.currentActionConfiguration?.find(
      t =>
        t.code == this.DELIVERABLE_TABLE_VIEW_DETAILS_ACTION && t.statusId == this.missionStatusId,
    );
    this.allowDeliverableTableDeleteAction = !!this.currentActionConfiguration?.find(
      t => t.code == this.DELIVERABLE_TABLE_DELETE_ACTION && t.statusId == this.missionStatusId,
    );
    this.allowDeliverableTableRejectAction = !!this.currentActionConfiguration?.find(
      t => t.code == this.DELIVERABLE_TABLE_REJECT_ACTION && t.statusId == this.missionStatusId,
    );
    this.allowDeliverableTableAcceptAction = !!this.currentActionConfiguration?.find(
      t => t.code == this.DELIVERABLE_TABLE_ACCEPT_ACTION && t.statusId == this.missionStatusId,
    );
    this.allowDeliverableEditAttributesAction = !!this.currentActionConfiguration?.find(
      t => t.code == this.DELIVERABLE_EDIT_ATTRIBUTES && t.statusId == this.missionStatusId,
    );
    this.allowUpdateDeliverableStatusInCaptures = !!this.currentActionConfiguration?.find(
      t =>
        t.code == this.UPDATE_DELIVERABLE_STATUS_IN_CAPTURES && t.statusId == this.missionStatusId,
    );
  }

  private fetchMissionData(): void {
    this.missionFlowService.getMissionData(this.missionId).subscribe(res => {
      this.mission = res;
      this.getfilesDumpData(null);
    });
  }

  private fetchMissionDeliverableAttributesData(): void {
    this.missionService.getAttributeValues(this.missionId).subscribe(res => {
      this.currentAttributes = res;
      this.fetchDeliverables();
    });
  }

  private mapAttributes(deliverables: DeliverableDto[]): void {
    deliverables.forEach(deliverable => {
      let altAttr = this.currentAttributes.find(
        t =>
          t.deliverableId == deliverable.deliverableId &&
          t.attributeName == this.ALTITUDE_ATTR_NAME,
      );
      let gimbAttr = this.currentAttributes.find(
        t =>
          t.deliverableId == deliverable.deliverableId &&
          t.attributeName == this.GRIMBALANGLE_ATTR_NAME,
      );
      let overlapAttr = this.currentAttributes.find(
        t =>
          t.deliverableId == deliverable.deliverableId && t.attributeName == this.OVERLAP_ATTR_NAME,
      );

      deliverable.altitude = altAttr?.attributeValue;
      deliverable.grimbalangle = gimbAttr?.attributeValue;
      deliverable.overlap = overlapAttr?.attributeValue;
    });
  }

  private getMissionStatuses(): void {
    this.statusService.getList(<GetStatusesInput>{}).subscribe(res => {
      this.currentStatuses = res.items;
    });
  }

  loadMissionPackagesAndProducts() {
    const subsMission = this.missionService.get(this.missionId).subscribe(
      (data: MissionsDto) => {
        if (data?.id != '') {
          this.industryId = data.industryId;
          this.loadPackages(data.customerId, data.industryId, data.industryDescription);
        }
      },
      error => {
        console.log('Error on getting packages Customer: ' + JSON.stringify(error));
      },
    );
    this.subscriptions.add(subsMission);
  }

  loadPackages(customerId: string, industryId: string, industryDescription: string) {
    this.packagesbyCustumerLoaded = [];
    let newInput = {} as GetPackageDetailsInput;
    newInput.customerId = customerId;
    const subsproducts = this.productDeliverablesService
      .getpackagesOrderRequest(newInput)
      .subscribe(
        (data: PackageTableDto[]) => {
          if (data?.length != 0) {
            for (let pk of data) {
              if (pk.deliverables?.length > 0) {
                this.packagesbyCustumerLoaded.push(pk);
              }
            }
          }
          this.loadGenericPackages(industryId, industryDescription);
        },
        error => {
          console.log('Error on getting packages Customer: ' + JSON.stringify(error));
        },
      );
    this.subscriptions.add(subsproducts);
  }

  loadGenericPackages(industryId: string, industryDescription: string) {
    this.genericPackagesLoaded = [];
    let newInput = {} as GetPackageDetailsInput;
    newInput.customerId = null;
    const subsproducts = this.productDeliverablesService
      .getpackagesOrderRequest(newInput)
      .subscribe(
        (data: PackageTableDto[]) => {
          if (data?.length != 0) {
            for (let pk of data) {
              if (pk.deliverables?.length > 0) {
                this.genericPackagesLoaded.push(pk);
              }

              this.genericPackages.push(this.buildPackageModel(pk));
            }
          }
          this.loadProductsByIndustry(industryId, industryDescription);
        },
        error => {
          console.log('Error on getting packages Products: ' + JSON.stringify(error));
        },
      );
    this.subscriptions.add(subsproducts);
  }

  buildPackageModel(pk: PackageTableDto) {
    let newpackage = new OrderFormPackageModel();
    newpackage.packageId = pk.packageId;
    newpackage.packageName = pk.packageName;
    newpackage.packagePrice = Math.abs(Number(pk.packagePrice.toFixed(2))).toString();
    newpackage.selected = false;
    newpackage.industries = pk.industries;
    for (let dl of pk.deliverables) {
      let deli: OrderFormDeliverableModel = {
        deliverableId: dl.deliverableId,
        deliverableName: dl.deliverableName,
        packageId: dl.packageId,
        packageName: dl.packageName,
        productId: dl.productId,
        productName: dl.productName,
        productDescription: dl.productDescription,
        industryId: null,
        industryDescription: null,
        industryMultiplexor: pk.packageMultiplier,
        standardPrice: dl.standardPrice,
        actualPrice: dl.actualPrice,
        quantity: dl.quantity,
        originalquantity: dl.quantity,
        subTotal: dl.subTotal,
        fields: dl.fields.map(fd => ({
          id: fd.id,
          placeholder: fd.placeholder,
          numericValue: fd.numericValue,
          stringValue: fd.stringValue,
          boolValue: fd.boolValue,
          fileValue: fd.fileValue,
          defaultValue: fd.defaultValue,
          isRequired: fd.isRequired,
          listValues: fd.listValues,
          maxTextLength: fd.maxTextLength,
          typeDescription: fd.typeDescription,
          typeCode: fd.typeCode,
        })),
      };
      newpackage.deliverables.push(deli);
    }
    return newpackage;
  }

  loadProductsByIndustry(industryId: string, industryDescription: string) {
    this.productsbyIndustryLoaded = [];
    let newInput = {} as GetPackageDetailsInput;
    newInput.industryId = industryId;

    const subsproducts = this.productDeliverablesService.getproductsByIndustry(newInput).subscribe(
      (data: DeliverablesTableDto[]) => {
        if (data?.length != 0) {
          for (let pd of data) {
            let deli: DeliverablesTableDto = {
              deliverableId: pd.deliverableId,
              deliverableName: pd.deliverableName,
              productId: pd.productId,
              productName: pd.productName,
              productDescription: pd.productDescription,
              packageId: pd.packageId,
              packageName: pd.packageName,
              standardPrice: pd.standardPrice,
              actualPrice: pd.actualPrice,
              quantity: 1,
              industryId: industryId,
              industryDescription: industryDescription,
              multiplier: data[0]?.multiplier,
              fields: pd.fields.map(fd => ({
                id: fd.id,
                placeholder: fd.placeholder,
                numericValue: fd.numericValue,
                stringValue: fd.stringValue,
                boolValue: fd.boolValue,
                fileValue: fd.fileValue,
                defaultValue: fd.defaultValue,
                isRequired: fd.isRequired,
                listValues: fd.listValues,
                maxTextLength: fd.maxTextLength,
                typeDescription: fd.typeDescription,
                typeCode: fd.typeCode,
              })),
            };
            this.productsbyIndustryLoaded.push(deli);
          }
        }

        this.buildPackagesAndProducts();
      },
      error => {
        console.error('Error on getting products by Industry: ' + JSON.stringify(error));
      },
    );
    this.subscriptions.add(subsproducts);
  }

  downloadSelectedFiles(deliverable: DeliverableDto = null): void {
    if (deliverable && deliverable != null) {
      this.directoryService
        .getRoot(
          this.missionId,
          deliverable.deliverableId,
          deliverable.lastCaptureId,
          deliverable.orderDetailId,
        )
        .subscribe({
          next: response => {
            if (!response || !response.id) {
              this.snackBar.open('No Content', 'Close', { duration: this.loadingDelay });
              return;
            }
            const rootFolderId = response.id;
            this.generateZipFile(rootFolderId, deliverable);
          },
          error: error => console.error('Unable to generate zip file:\n', error),
        });
    } else {
      if (this.deliverablesFromCapture) {
        const root$: Observable<DirectoryDescriptorDto>[] = [];
        this.deliverablesFromCapture.forEach(deliverable => {
          const observable = this.directoryService.getRoot(
            this.missionId,
            deliverable.deliverableId,
            deliverable.lastCaptureId,
            deliverable.orderDetailId,
          );
          root$.push(observable);
        });

        forkJoin(root$).subscribe({
          next: responses => {
            const rootFoldersIds: string[] = [];
            responses.forEach(response => {
              if (response?.id) {
                rootFoldersIds.push(response.id);
              }
            });
            if (rootFoldersIds.length === 0) return;
            this.generateZipFile(rootFoldersIds.join(','));
          },
          error: error => console.error('Error when getting all roots:\n', error),
        });
      } else {
        this.snackBar.open('Loading data, please wait', 'Close', {
          duration: this.loadingDelay,
        });
      }
    }
  }

  generateZipFile(filesIds: string, deliverable?: DeliverableDto) {
    this.fileDescriptorService.validateContent(filesIds).subscribe({
      next: (hasContent: boolean) => {
        if (!hasContent) {
          this.snackBar.open('Folder has no content', 'close', {
            duration: this.loadingDelay,
          });
          return;
        }
        this.fileDescriptorService.getDownloadTokenZip().subscribe({
          next: (response: ZipDownloadResult) => {
            this.fileDescriptorService.downloadZipFileUrl(
              response.url,
              response.token,
              filesIds,
              this.missionId,
              deliverable,
            );
          },
          error: error => console.error('Unable to get token:\n', error),
        });
      },
      error: error => console.error('Unable to validate content:\n', error),
    });
  }

  getFileDumpingSession() {
    this.getfilesDumpData(null);
    this.dialogService.open(this.modal, { width: '600px', disableClose: false });
  }

  showFileDumpingSessionResult(): void {
    if (this.fileDumpingSessionDto.isFinished) {
      return;
    }

    this.getfilesDumpData(() => {
      if (this.fileDumpingSessionDto.isFinished) {
        this.dialogService.open(this.modal, { width: '600px', disableClose: false });
      }
    });

    setTimeout(() => {
      this.showFileDumpingSessionResult();
    }, 30000);
    return;
  }

  getfilesDumpData(callBack: () => void): void {
    if (!this.mission?.isRaptorMapCustomer) return;

    this.fileDumpingSessionService.getByCorrelationalId(this.missionId).subscribe(result => {
      let emptySessionId = '00000000-0000-0000-0000-000000000000';
      this.filesDumpingSessionInProgress = false;

      if (result?.id == null || result.id == emptySessionId) {
        result.startDateTime = null;
        result.endDateTime = null;
        result.externalService = FilesDumpingSessionExternalServices.RaptorMaps;
        result.sessionStatus = FilesDumpingSessionStatus.None;
      }

      this.filesDumpingSessionInProgress = result.isInProgress && !result.isRunningTimeExpired;

      if (this.filesDumpingSessionInProgress) {
        result.endDateTime = null;

        this.snackBar.open('Files Dumping Session Is Running', 'Close', {
          duration: this.loadingDelay,
        });
      }

      this.fileDumpingSessionDto = result;

      if (
        this.fileDumpingSessionDto.externalService ==
          FilesDumpingSessionExternalServices.RaptorMaps &&
        (this.fileDumpingSessionDto.externalReferenceCode === null ||
          this.fileDumpingSessionDto.externalReferenceCode.length == 0)
      ) {
        this.fileDumpingSessionDto.externalReferenceCode = this.mission?.missionAssetOrder;
      }

      this.mappedFileSessionModel = this.mapToSessionModel();
      this.buildForm();

      if (callBack) callBack();
    });
  }

  buildForm() {
    const {
      startDateTime,
      totalFiles,
      endDateTime,
      externalReferenceCode,
      correlationId,
      currentUserName,
      sessionStatus,
      sessionStatusName,
    } = this.mappedFileSessionModel || {};

    this.formFile = this.fb.group({
      startedDate: [startDateTime ?? null, null],
      totalProcessFiles: [totalFiles ?? null, null],
      finishDate: [endDateTime ?? null, null],
      externalCode: [externalReferenceCode ?? null, [Validators.required]],
      sessionStatusString: [
        this.fileDumpingSessionService.getFileDumpingStatusDescription(sessionStatus) ?? null,
        null,
      ],
      correlationId: [correlationId ?? null, null],
      triggeredBy: [currentUserName ?? null, null],
    });
  }

  submitFilesDumpingSessionForm() {
    if (this.formFile.invalid) return;
    const request = this.fileDumpingSessionService.create(this.mapToSessionDto());

    request.pipe(tap(() => this.hideForm())).subscribe(result => {
      this.fileDumpingSessionDto = result;

      this.fileDumpingSessionDto.sessionStatus = FilesDumpingSessionStatus.Inprogres;
      this.fileDumpingSessionDto.startDateTime = new Date();
      this.fileDumpingSessionDto.endDateTime = null;
      this.fileDumpingSessionDto.totalFiles = 0;
      this.fileDumpingSessionDto.sessionFailed = false;

      this.filesDumpingSessionInProgress = true;

      this.mappedFileSessionModel = this.mapToSessionModel();

      this.snackBar.open('Files Dumping Session Created. It will run in background', 'Close', {
        duration: this.loadingDelay,
      });

      this.showFileDumpingSessionResult();
      this.cdRef.detectChanges();
    });
  }

  hideForm() {
    this.dialogService.closeAll();
    this.formFile.reset();
  }

  mapToSessionModel(): FileDumpingSessionModel {
    const startLocalTime =
      this.fileDumpingSessionDto.startDateTime != null &&
      this.fileDumpingSessionDto.startDateTime != undefined
        ? new Date(this.fileDumpingSessionDto.startDateTime + 'Z')
        : this.fileDumpingSessionDto.startDateTime;
    const finishtLocalTime =
      this.fileDumpingSessionDto.endDateTime != null &&
      this.fileDumpingSessionDto.endDateTime != undefined
        ? new Date(this.fileDumpingSessionDto.endDateTime + 'Z')
        : this.fileDumpingSessionDto.endDateTime;

    return {
      correlationId: this.missionId,
      endDateTime: this.datePipe.transform(finishtLocalTime, 'yyyy-MM-dd | HH:mm:ss'),
      startDateTime: this.datePipe.transform(startLocalTime, 'yyyy-MM-dd | HH:mm:ss'),
      sessionFailed: false,
      totalFiles: this.fileDumpingSessionDto.totalFiles,
      sessionStatusDetail: this.fileDumpingSessionDto.sessionStatusDetail,
      externalReferenceCode: this.fileDumpingSessionDto.externalReferenceCode,
      externalService: this.fileDumpingSessionDto.externalService,
      sessionStatus: this.fileDumpingSessionDto.sessionStatus,
      currentUserName: this.fileDumpingSessionDto.currentUserName,
      sessionStatusName: this.enumStatus[this.fileDumpingSessionDto.sessionStatus].key,
    };
  }

  mapToSessionDto(): FileDumpingSessionDto {
    return {
      correlationId: this.missionId,
      startDateTime: new Date(),
      endDateTime: new Date(1, 1, 1),
      externalReferenceCode: this.formFile.controls['externalCode'].value,
      sessionFailed: false,
      totalFiles: 0,
      sessionStatusDetail: '',
      externalService:
        this.fileDumpingSessionDto.externalService == FilesDumpingSessionExternalServices.None
          ? FilesDumpingSessionExternalServices.RaptorMaps
          : this.fileDumpingSessionDto.externalService,
      sessionStatus: this.fileDumpingSessionDto.sessionStatus,
      currentUserName: this.config.getDeep('currentUser.userName'),
    };
  }

  private checkAndPerformAutomaticQA(deliverablesDto: DeliverableDto[]) {
    deliverablesDto.forEach(deliverable => {
      const getAttributeValidationInput: GetAttributeValidationsInput = {
        maxResultCount: 100,
        captureDeliverableAttributeId: deliverable.captureDeliverableId,
      };

      this.attributeValidationService.getList(getAttributeValidationInput).subscribe({
        next: response => {
          if (response.items.length > 0) {
            this.prepareAutomaticQA(
              response.items,
              deliverable.deliverableId,
              deliverable.lastCaptureId,
              deliverable.orderDetailId,
            );
          }
        },
        error: error =>
          console.error(
            `Error on getting attribute validations for deliverable ${deliverable.deliverableId}:`,
            error,
          ),
      });
    });
  }

  private prepareAutomaticQA(
    validations: AttributeValidationDto[],
    deliverableId: string,
    lastCaptureId: string,
    orderDetailId: string,
  ) {
    this.directoryService
      .getRoot(this.missionId, deliverableId, lastCaptureId, orderDetailId)
      .subscribe({
        next: response => {
          if (!response || !response.id) {
            console.log('No files to perform automatic QA for deliverableId: ', deliverableId);
            return;
          }
          const rootFolderId = response.id;

          this.fileDescriptorService.getFilesExifData(rootFolderId).subscribe({
            next: (exifData: ExifDataResultsDto[]) => {
              if (exifData.length > 0) {
                this.performAutomaticQA(validations, exifData, deliverableId);
              }
            },
            error: error => console.error('Unable to get exif data: ', error),
          });
        },
        error: error => console.error('Unable to generate zip file: ', error),
      });
  }

  private performAutomaticQA(
    validations: AttributeValidationDto[],
    exifData: ExifDataResultsDto[],
    deliverableId: string,
  ) {
    this.attributeValidationService.performAutomaticQa(validations, exifData).subscribe({
      next: (validationResults: ExifDataResultsDto[]) => {
        this.automaticQaValidationResults.push({
          exifDataResults: validationResults,
          deliverableId: deliverableId,
        });

        if (validationResults.length > 0) {
          this.deliverablesFromCapture = this.deliverablesFromCapture.map(deliverable => {
            if (deliverable.deliverableId === deliverableId) {
              const allPassed = validationResults.every(
                result => result.overallValidationResult === AutomaticQaStatus.Pass,
              );
              const anyFailed = validationResults.some(
                result => result.overallValidationResult === AutomaticQaStatus.Fail,
              );
              const allNotTested = validationResults.every(
                result => result.overallValidationResult === AutomaticQaStatus.NotTested,
              );

              if (allPassed) {
                return { ...deliverable, automaticQa: AutomaticQaStatus.Pass };
              } else if (anyFailed) {
                return { ...deliverable, automaticQa: AutomaticQaStatus.Fail };
              } else if (allNotTested) {
                // since it's already NotTested by default, we return the same
                return deliverable;
              } else {
                // If there's a mix of Passed and NotTested, we'll consider it as Passed
                return { ...deliverable, automaticQa: AutomaticQaStatus.Pass };
              }
            }
            return deliverable; // Return unchanged for other deliverables
          });
        }
      },
      error: err => {
        console.log('Error on performAutomaticQa', err);
      },
    });
  }

  openValidationResultsDialog(row: DeliverableDto): void {
    const deliverableId = row.deliverableId;
    const specificResult = this.automaticQaValidationResults.find(
      x => x.deliverableId === deliverableId,
    );

    this.dialogService.open(ValidationsResultsModalComponent, {
      width: '900px',
      data: specificResult.exifDataResults,
    });
  }
}
