<div>
  <ngx-spinner
    bdColor="rgba(245,245,245,0.7)"
    size="default"
    color="#bc5a2e"
    type="ball-square-clockwise-spin"
    [fullScreen]="false"
    [zIndex]="111"
  >
    <p style="color: #bc5a2e">Loading Orders...</p>
  </ngx-spinner>

  <mat-sidenav-container class="filters-container bg-white" [hasBackdrop]="false">
    <mat-sidenav #drawer mode="over" position="end" class="sidenav-border">
      <app-grid-filters
        *ngIf="filterConfig"
        [filterConfig]="filterConfig"
        (applyFilters)="onFiltersApplied($event)"
        (closeSidenav)="drawer.close()"
      >
      </app-grid-filters>
    </mat-sidenav>

    <mat-sidenav-content>
      <div [class.sticky-header]="selectedActions && firstRow" class="white-background">
        <form [formGroup]="form" (submit)="onGlobalSearch()">
          <div class="d-flex mb-3 pt-2">
            <mat-form-field class="d-flex w-80 pe-2" subscriptSizing="dynamic">
              <mat-label>Search</mat-label>
              <input
                matInput
                placeholder="Search"
                formControlName="searchInput"
                (enter)="onGlobalSearch()"
              />
              <mat-icon matSuffix class="cursor-pointer blue-color" (click)="onGlobalSearch()"
                >search</mat-icon
              >
            </mat-form-field>
            <mat-form-field class="d-flex mission-visibility" subscriptSizing="dynamic">
              <mat-label>Grid View</mat-label>
              <mat-select [(value)]="selectedAssignedFilter" name="assignedFilter">
                <mat-select-trigger>
                  <span class="example-additional-selection">
                    {{ getDisplayValue(selectedAssignedFilter) }}
                  </span>
                </mat-select-trigger>
                <label class="view-label mb-1 ms-1 mt-1">Default views</label>
                <mat-option
                  *ngFor="let dp of lstAssignedFilter; let first = first"
                  [value]="dp.value"
                  title="{{ dp.description }}"
                  (click)="handleAssignedSelect(dp.value, true)"
                  [ngClass]="{ 'mt-1': first }"
                >
                  <span class="d-flex">
                    <span class="mt-1">{{ dp.description }}</span>
                  </span>
                </mat-option>
                <hr class="mt-2 mb-2" *ngIf="existingViews?.length > 0" />
                <label class="view-label mb-1 ms-1" *ngIf="existingViews?.length > 0"
                  >Custom views</label
                >
                <mat-option
                  *ngFor="let view of existingViews; let first = first"
                  [value]="view.id"
                  title="{{ view.name }}"
                  (click)="handleOnViewSelected(view.id)"
                  [ngClass]="{
                    'mt-1': first,
                    'view-selected': viewSelected && viewSelected.id == view.id,
                  }"
                  style="width: 100%"
                  (mouseenter)="handleOnHover(view, true, $event)"
                  (mouseleave)="handleOnHover(view, false, $event)"
                >
                  <span class="d-flex view-list-item star-item">
                    <span class="mt-1">{{ view.name }}</span>
                    <span
                      *ngIf="
                        view.displayStar &&
                        (!predefinedView || predefinedView.gridViewId != view.id)
                      "
                      class="material-symbols-outlined item-star"
                      (click)="handleOnAssignView($event, view.id)"
                      [matTooltip]="'Set as predefined'"
                    >
                      star
                    </span>
                    <span
                      class="material-icons item-selected-star"
                      *ngIf="predefinedView && predefinedView.gridViewId == view.id"
                      (click)="handleOnUnassignView($event, view.id)"
                      [matTooltip]="'Remove from predefined'"
                    >
                      star
                    </span>
                  </span>
                </mat-option>
              </mat-select>
            </mat-form-field>
            <span
              class="material-symbols-outlined mt-3 d-flex add-view-button"
              [matTooltip]="'Add new view'"
              (click)="handleOnCreateCustomView()"
            >
              splitscreen_vertical_add
            </span>
          </div>
        </form>

        <div
          class="d-flex align-items-center py-0 mb-3"
          *abpPermission="'missionsService.Mission.Filters && General.Common.Filters'"
        >
          <span class="fg-subtitle me-2"> I want to see Missions with: </span>

          <!-- Display applied filters -->
          <div class="d-flex align-items-center flex-wrap">
            <ng-container *ngFor="let condition of filterConfig.conditions; let isLast = last">
              <ng-container *ngIf="condition.existingValues.length">
                <app-filter-chip
                  [label]="condition.columnDisplayName"
                  [value]="getFilterDisplayValue(condition)"
                  (removeEvent)="removeFilter(condition.column)"
                ></app-filter-chip>
                <span *ngIf="!isLast" class="comma-separator">, </span>
              </ng-container>
            </ng-container>
          </div>

          <span class="material-symbols-outlined me-2 cursor-pointer" (click)="drawer.toggle()">
            add_circle
          </span>

          <span
            class="d-inline-block main-color fw-medium cursor-pointer"
            [class.disabled]="!hasFilters()"
            (click)="removeAllFilters()"
            >Clear All</span
          >
        </div>

        <div *ngIf="selectedActions && firstRow" class="d-flex align-items-center mb-3 box mt-3">
          <div>
            <span class="bold">{{ 'missionsService::BulkActions' | abpLocalization }} </span>
            <span>
              {{
                'missionsService::ItemsSelected' | abpLocalization: this.selectedMissions?.length
              }}
            </span>
          </div>
          <div *ngIf="loadingCommonActions" class="justify-content-end ms-auto d-flex">
            <mat-spinner [diameter]="25"></mat-spinner>
          </div>
          <div *ngIf="!loadingCommonActions" class="justify-content-end ms-auto d-flex">
            <button
              *ngFor="let item of selectedActions"
              [matTooltip]="item?.description"
              (click)="handleAction($event)"
              class="secondary-actions secondary-actions__action align-items-center d-flex border-0 ms-2"
              type="button"
            >
              <lib-management-actions
                class="af-missions pe-none align-items-center me-1"
                gridName="Missions"
                [rowData]="
                  getActionData(item?.code, firstRow, item?.description, item?.bulkData?.length)
                "
                [uniqueAction]="item?.code"
                [bulkData]="item?.bulkData"
                (parentEvent)="reloadNotes(item?.code)"
                [additionalData]="users"
              >
              </lib-management-actions>
              <span class="secondary-actions secondary-actions__description pe-none">
                {{ item.description }}
              </span>
            </button>
            <button
              *ngIf="isAssignPilotBulk"
              class="secondary-actions secondary-actions__action align-items-center d-flex border-0 ms-2"
              type="button"
              (click)="handleAssignPilotActionBulk(selectedMissions)"
            >
              <span class="secondary-actions secondary-actions__description pe-none">
                Assign Pilot
              </span>
            </button>
            <span *ngIf="selectedActions?.length < 1">{{
              'missionsService::NoCommonActions' | abpLocalization
            }}</span>
          </div>
        </div>
      </div>

      <div class="grid">
        <ngx-datatable
          [rows]="data.items"
          [headerHeight]="60"
          [footerHeight]="55"
          [rowHeight]="50"
          [offset]="pageNumber"
          [count]="data.totalCount"
          [list]="list"
          [limit]="clientMaxResultCount"
          [externalPaging]="true"
          (page)="setPage($event)"
          (sort)="onSort($event)"
          [trackByProp]="'id'"
          (activate)="onSelection($event)"
          column-state-persistance
          [tableName]="'orders'"
          default
          #dataTable
          [selected]="selectedMissions"
          [selectionType]="SelectionType.checkbox"
          [selectAllRowsOnPage]="true"
          (select)="onSelect($event)"
          class="flg-grid-table flg-grid-table--clickable"
          [rowClass]="getPriorityClass"
        >
          <ngx-datatable-column
            [width]="50"
            *ngIf="canExecuteBulkActions"
            [sortable]="false"
            [canAutoResize]="false"
            [draggable]="false"
            [resizeable]="false"
            name="Click here to Select/Deselect All"
            prop="select"
          >
            <ng-template
              ngx-datatable-header-template
              let-value="value"
              let-allRowsSelected="allRowsSelected"
              let-selectFn="selectFn"
            >
              <mat-checkbox
                [checked]="allRowsSelected"
                (change)="selectFn(!allRowsSelected)"
                matTooltip="Click here to Select/Deselect All"
              >
              </mat-checkbox>
            </ng-template>
            <ng-template
              ngx-datatable-cell-template
              let-value="value"
              let-isSelected="isSelected"
              let-onCheckboxChangeFn="onCheckboxChangeFn"
            >
              <mat-checkbox
                [checked]="isSelected"
                (change)="onCheckboxChangeFn($event)"
                matTooltip="Click here to Select/Deselect"
              >
              </mat-checkbox>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::JobId' | abpLocalization }}"
            prop="jobId"
            [minWidth]="80"
            [width]="80"
            *ngIf="showedColumns.jobId && allowedByView('JobId')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.jobId || '--' }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::SLAProfile' | abpLocalization }}"
            prop="priorityId"
            [width]="120"
            [headerClass]="'priority-header'"
            *ngIf="showedColumns.priority && allowedByView('SLAProfile')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.priorityDescription }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::Status' | abpLocalization }}"
            prop="MissionStatus"
            [width]="230"
            *ngIf="showedColumns.missionStatus && allowedByView('Status')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span
                matTooltip="{{ missionStatus[row.missionStatus]?.value || '--' }}"
                [matTooltipShowDelay]="2000"
              >
                {{ missionStatus[row.missionStatus]?.value || '--' }}</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::MissionName' | abpLocalization }}"
            prop="name"
            [width]="200"
            *ngIf="showedColumns.name && allowedByView('MissionName')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.name || '--' }}" [matTooltipShowDelay]="2000">{{
                row.name || '--'
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::CustomerId' | abpLocalization }}"
            prop="customerId"
            *ngIf="showedColumns.customer && allowedByView('CustomerId')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.customerName || '--' }}" [matTooltipShowDelay]="2000">{{
                row.customerName || '--'
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::LocationId' | abpLocalization }}"
            prop="location"
            *ngIf="showedColumns.location && allowedByView('LocationId')"
            [width]="150"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.location }}" [matTooltipShowDelay]="2000">{{
                row.location
              }}</span>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::CaptureDate' | abpLocalization }}"
            prop="activeCaptureDate"
            *ngIf="showedColumns.activeCaptureDate && allowedByView('CaptureDate')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.fixedActiveCaptureDate || (row.activeCaptureDate | date: 'MM/dd/yyyy') }}
              {{ row.timeZone || '' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::PilotSourcingResults' | abpLocalization }}"
            prop="pilotSourcingResults"
            [sortable]="false"
            [width]="150"
            *ngIf="
              showedColumns.pilotSourcingResults &&
              allowedByView('PilotSourcingResults') &&
              isSourcingResultsVisible()
            "
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="container pilot-sourcing-col">
                <div class="row">
                  <div
                    class="col-8"
                    [innerHTML]="
                      CreatePilotVisibilityAcceptanceFormattedString(
                        row.pilotRequested,
                        row.pilotAccepted,
                        row.pilotRejected
                      )
                    "
                  ></div>
                  <div
                    class="col-4 d-flex flex-column justify-content-center"
                    *ngIf="showNavigateToCaptures(row)"
                  >
                    <div>
                      <span
                        class="material-symbols-rounded navigate-buton"
                        matTooltip="Go to Pilot Sourcing Tab"
                        (click)="navigateToPilotSourcingGrid(row.id, row.captureId)"
                      >
                        tab_new_right
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::NextMilestoneDue' | abpLocalization }}"
            prop="nextMilestoneDue"
            *ngIf="showedColumns.nextMilestoneDue && allowedByView('NextMilestoneDue')"
            [width]="150"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <lib-calculation-due-dates
                [dueDate]="row.slaDueDate"
                [showTriangle]="true"
                [missionStatus]="row.missionStatus"
              ></lib-calculation-due-dates>
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::DaysToDelivery' | abpLocalization }}"
            prop="daysToDelivery"
            *ngIf="showedColumns.daysToDelivery && allowedByView('DaysToDelivery')"
            [width]="150"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="daysToDelivery">
                <lib-calculation-due-dates
                  [dueDate]="row.deliverableDueDate"
                  [showTriangle]="true"
                  [missionStatus]="row.missionStatus"
                ></lib-calculation-due-dates>
                <a (click)="openPopupSlaMilestones(row.id, row.priorityDescription)">
                  <span matTooltip="See details" class="material-symbols-outlined">info</span>
                </a>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::DeliverableDueDate' | abpLocalization }}"
            prop="deliverableDueDate"
            [minWidth]="200"
            *ngIf="showedColumns.deliverableDueDate && allowedByView('DeliverableDueDate')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{
                row.fixedDeliverableDueDate
                  ? (row.fixedDeliverableDueDate | date: 'MM/dd/yyyy')
                  : ''
              }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::PilotId' | abpLocalization }}"
            prop="pilot"
            *ngIf="showedColumns.pilotName && allowedByView('PilotId')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.pilotName || '--' }}" [matTooltipShowDelay]="2000">
                {{ row.pilotName || '--' }}</span
              >
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            [name]="'missionsService::Team' | abpLocalization"
            [minWidth]="100"
            [width]="100"
            *ngIf="showedColumns.assigned && allowedByView('Team')"
            [sortable]="false"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span (click)="showAssigned(row)" class="material-symbols-outlined"
                >assignment_ind</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::ProjectId' | abpLocalization }}"
            prop="projectId"
            *ngIf="showedColumns.project && allowedByView('ProjectId')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.project || '--' }}" [matTooltipShowDelay]="2000">{{
                row.project || '--'
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::Portafolio' | abpLocalization }}"
            prop="potafolioId"
            *ngIf="showedColumns.portafolio && allowedByView('Portafolio')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.portfolio || '--' }}" [matTooltipShowDelay]="2000">{{
                row.portfolio || '--'
              }}</span>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::State' | abpLocalization }}"
            prop="state"
            [sortable]="false"
            *ngIf="!this.export && showedColumns.state && allowedByView('State')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <app-column-stylizer
                [columnValue]="row.state"
                [typeColumn]="'text'"
                [columnRules]="stateRules"
              ></app-column-stylizer>
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::Summary' | abpLocalization }}"
            prop="summary"
            *ngIf="!this.export && showedColumns.summary && allowedByView('Summary')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.summary || '--' }}" [matTooltipShowDelay]="2000">
                {{ row.summary || '--' }}</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::StartData' | abpLocalization }}"
            prop="startData"
            [sortable]="false"
            *ngIf="!this.export && showedColumns.startData && allowedByView('StartData')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <span matTooltip="{{ row.startData || '--' }}" [matTooltipShowDelay]="2000">
                {{ row.startData || '--' }}</span
              >
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::AdditionalNotes' | abpLocalization }}"
            prop="additionalNotes"
            *ngIf="
              !this.export && showedColumns.additionalNotes && allowedByView('AdditionalNotes')
            "
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.additionalNotes || '--' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::pilotSourcingMode' | abpLocalization }}"
            prop="pilotSourcingMode"
            [sortable]="false"
            *ngIf="
              !this.export && showedColumns.pilotSourcingMode && allowedByView('pilotSourcingMode')
            "
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.pilotSourcingMode }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::qaqcMode' | abpLocalization }}"
            prop="qaqcMode"
            [sortable]="false"
            *ngIf="!this.export && showedColumns.qaqcMode && allowedByView('qaqcMode')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.qaqcMode }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::invoiceMode' | abpLocalization }}"
            prop="invoiceMode"
            [sortable]="false"
            *ngIf="!this.export && showedColumns.invoiceMode && allowedByView('invoiceMode')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.invoiceMode }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::cost' | abpLocalization }}"
            prop="cost"
            [sortable]="false"
            *ngIf="!this.export && showedColumns.cost && allowedByView('cost')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.cost }}
            </ng-template>
          </ngx-datatable-column>
          <ngx-datatable-column
            name="{{ 'missionsService::CreationTime' | abpLocalization }}"
            prop="creationTime"
            *ngIf="showedColumns.creationTime && allowedByView('CreationTime')"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              {{ row.creationTime | date: 'MM/dd/yyyy' }}
            </ng-template>
          </ngx-datatable-column>

          <ngx-datatable-column
            name="{{ 'missionsService::Airspace Waiver Required' | abpLocalization }}"
            prop="manualAirspaceWaiverRequired"
            [minWidth]="250"
            [width]="250"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <app-column-stylizer
                [columnValue]="row.manualAirspaceWaiverRequired"
                [typeColumn]="'icon'"
                [columnRules]="migratedRules"
              ></app-column-stylizer>
            </ng-template>
          </ngx-datatable-column>

          <!-- Actions section -->
          <ngx-datatable-column
            name="Actions"
            *ngIf="this.export"
            [sortable]="false"
            [frozenRight]="true"
            [width]="220"
            [resizeable]="false"
            [draggable]="false"
            [headerClass]="'actions-column'"
          >
            <ng-template let-row="row" ngx-datatable-cell-template>
              <div class="flg-grid-table-actions" [ngClass]="row.cssClass" fix-action-width>
                <div class="primary-action">
                  <lib-management-actions
                    *ngIf="
                      row.firstAction &&
                      (!isMissionSelected(row) || (!firstRow && isMissionSelected(row)))
                    "
                    class="af-button af-button-in-table"
                    [validateExecuteAction]="
                      checkIfThrowsConfirmationAction(row, row.firstAction?.code)
                    "
                    gridName="Missions"
                    [rowData]="getActionData(row.firstAction?.code, row)"
                    [uniqueAction]="row?.firstAction?.code"
                  >
                    <button
                      type="button"
                      class="table-outline-button"
                      matTooltip="{{ row?.firstAction?.description }}"
                      [matTooltipShowDelay]="2000"
                    >
                      {{ row?.firstAction?.description }}
                    </button>
                  </lib-management-actions>
                  <span class="empty-icon" *ngIf="!row.firstAction"></span>
                </div>
                <div class="secondary-actions">
                  <flg-grid-notes
                    *ngIf="
                      row.firstSecondaryAction &&
                      (!isMissionSelected(row) || (!firstRow && isMissionSelected(row)))
                    "
                    [users]="users"
                    [userId]="currentUserId"
                    [categories]="categories"
                    [departments]="departments"
                    [mission]="row"
                  ></flg-grid-notes>
                  <span class="empty-icon" *ngIf="!row.firstSecondaryAction"></span>
                  <button
                    mat-button
                    [disableRipple]="true"
                    *ngIf="!isMissionSelected(row) || (!firstRow && isMissionSelected(row))"
                    [matMenuTriggerFor]="menu"
                    aria-label="Grid Actions"
                    class="single-action"
                    [class.invisible]="!row.actions || row.actions.length === 0"
                  >
                    <span class="material-symbols-outlined">more_vert</span>
                  </button>

                  <mat-menu
                    #menu="matMenu"
                    [class]="'secondary-actions mat-elevation-z2'"
                    #actionsMenu
                  >
                    <ng-container *ngFor="let action of row.actions; let i = index">
                      <button
                        mat-menu-item
                        (click)="handleAction($event)"
                        class="secondary-actions secondary-actions__action secondary-actions--no-icon"
                      >
                        <lib-management-actions
                          class="af-missions pe-none"
                          gridName="Missions"
                          [validateExecuteAction]="
                            checkIfThrowsConfirmationAction(row, action.code)
                          "
                          [rowData]="getActionData(action.code, row)"
                          [uniqueAction]="action.code"
                          [additionalData]="users"
                        >
                        </lib-management-actions>
                        <span class="secondary-actions secondary-actions__description">
                          {{ action.description }}
                        </span>
                      </button>
                    </ng-container>
                    <button
                      *ngIf="isCopyMissionAllowed(row)"
                      mat-menu-item
                      (click)="handleCopyAction(row)"
                      class="secondary-actions secondary-actions__action"
                    >
                      <span class="secondary-actions secondary-actions__description pe-none">
                        Copy Mission
                      </span>
                    </button>
                    <button
                      *ngIf="isAssignPilot(row)"
                      mat-menu-item
                      (click)="handleAssignPilotAction(row)"
                      class="secondary-actions secondary-actions__action"
                    >
                      <span class="secondary-actions secondary-actions__description pe-none">
                        Assign Pilot
                      </span>
                    </button>

                    <button
                      mat-menu-item
                      class="secondary-actions secondary-actions__action"
                      (click)="handleMissionInNewTab(row)"
                    >
                      <span class="secondary-actions secondary-actions__description pe-none">
                        Open Mission in New Tab
                      </span>
                    </button>
                  </mat-menu>
                </div>
              </div>
            </ng-template>
          </ngx-datatable-column>
          <!-- End actions -->

          <ngx-datatable-footer>
            <ng-template
              ngx-datatable-footer-template
              let-rowCount="rowCount"
              let-pageSize="pageSize"
              let-curPage="curPage"
            >
              <app-table-paginator
                [(maxResultCount)]="list.maxResultCount"
                [(clientMaxResultCount)]="clientMaxResultCount"
                [rowCount]="rowCount"
                [pageSize]="pageSize"
                [(pageNumber)]="pageNumber"
                [curPage]="curPage"
                [dataTable]="dataTable"
                (clientMaxResultCountChange)="onPaginationChange($event)"
                (beforePageSizeChange)="onBeforePageSizeChange()"
              >
              </app-table-paginator>
            </ng-template>
          </ngx-datatable-footer>
        </ngx-datatable>
      </div>
    </mat-sidenav-content>
  </mat-sidenav-container>

  <app-responsive-grid
    class="d-none"
    gridName="Missions"
    (action)="setAction($event)"
  ></app-responsive-grid>
</div>
