<mat-card class="p-4 mb-4" *ngIf="missionData">
  <div class="d-flex align-items-center mb-3">
    <app-fg-breadcrumb
      [breadcrumbs]="[
        { text: 'Orders', link: '/orders' },
        { text: 'Porfolio', link: '/orders' },
        { text: 'RRSSXDST' }
      ]"
    ></app-fg-breadcrumb>
    <div class="d-flex">
      <app-fg-chip
        class="ml-4 no-shrink"
        type="purpleChip"
        text="{{ missionStatus[missionData.statusEnum].value }}"
      ></app-fg-chip>
    </div>
    <div class="d-flex justify-content-end ms-auto">
      <button
        [disableRipple]="true"
        (click)="backToOrder()"
        [disableRipple]="true"
        mat-stroked-button
        type="button"
      >
        <mat-icon aria-hidden="false" aria-label="All Staff" fontIcon="keyboard_return"></mat-icon>
        Back to order
      </button>
    </div>
  </div>

  <div class="d-flex justify-content-between align-items-center mb-3">
    <div class="d-flex align-items-center">
      <app-mission-info-badge
        [icon]="'tag'"
        [subtitle]="'Job ID:'"
        [description]="missionData.jobId"
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'person_raised_hand'"
        [subtitle]="'Requesting Customer:'"
        [description]="extendedMissionDto?.customerName || missionData?.customerName"
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'calendar_today'"
        [subtitle]="'Capture Date:'"
        [description]="
          extendedMissionDto.missionCaptureDetails?.fixedCurrentCaptureDate ||
          (extendedMissionDto.missionCaptureDetails?.currentCaptureDate + 'Z' | date : 'MM/dd/yyyy')
        "
      ></app-mission-info-badge>
      <app-mission-info-badge
        [icon]="'schedule'"
        [subtitle]="'Capture Time:'"
        [description]="
          extendedMissionDto.missionCaptureDetails?.fixedCurrentCaptureTime ||
          extendedMissionDto.missionCaptureDetails?.currentCaptureTime ||
          'N/A'
        "
      ></app-mission-info-badge>
    </div>

  </div>


  <div>
    <mat-tab-group
      #tabGroup
      mat-stretch-tabs="false"
      mat-align-tabs="start"
      (selectedTabChange)="onTabChange($event)"
    >
      <mat-tab label="Order Dashboard">
        <ng-template matTabContent>
          <div class="d-flex">
            <div class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card mt-2 me-1 flex-column" style="width: 62% !important;">
              <div class="mb-4">
                <h3 class="p-10">Missions by Status</h3>
              </div>
              <div>
                <ngx-charts-bar-vertical
                  [view]="view"
                  [scheme]="colorScheme"
                  [results]="data"
                  [gradient]="true"
                  [xAxis]="true"
                  [yAxis]="true"
                  [legend]="true"
                  [showXAxisLabel]="false"
                  [showYAxisLabel]="false"
                  [xAxisLabel]="xAxisLabel"
                  [yAxisLabel]="yAxisLabel"
                  [legend]="false"
                  [showYAxisLabel]="true"
                  [yAxisLabel]="'Missions'"
                  [barPadding]="90"
                  (select)="charSelected($event)"
                  (activate)="charActivate($event)"
                  (deactivate)="charDeactivate($event)">
                </ngx-charts-bar-vertical>
              </div>
            </div>
            
            <div class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card mt-2 w-50 ms-1" style="width: 38% !important;">
              <!-- (markerData)="handleMarkerClick($event)" -->
              <div class="w-100">
                <flg-map
                  [markers]="mapMarkers"
                  [usesKML]="false"
                  [options]="{
                    disableDefaultUI: true,
                    mapTypeId: mapTypeId,
                    zoom:6
                  }"
                  [dimensions]="mapDimensions"
                  
                ></flg-map>
              </div>
            </div>
          </div>
          <div class="d-flex">
            <div class="d-flex" style="width: 62% !important;">
              <div class="card d-flex align-items-start align-self-stretch rounded custom-card mt-2 me-1 flex-column" style="width: 100%;">
                <div class="mb-3 d-flex" style="width: 100%;">
                  <h3 class="p-10">Missions by Risk</h3> 
                  <div class="chip me-1" title="status" *ngIf="filter">
                    <span class="chip-text chip-label">Status:&nbsp;</span>
                    <span class="chip-text chip-value criteria" *ngIf="filter == 'CR'">
                      Customer Request
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="filter == 'PS'">
                      Pilot Success
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="filter == 'MC'">
                      Mission Coordinator
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="filter == 'P'">
                      Pilot
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="filter == 'QA'">
                      QA/QC
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="filter == 'S'">
                      Sales
                    </span>
                    <span class="material-symbols-rounded" (click)="cleanFilters()"> cancel </span>
                  </div>
                  <div class="chip" title="Risk" *ngIf="riskSelected">
                    <span class="chip-text chip-label">Risk:&nbsp;</span>
                    <span class="chip-text chip-value criteria" *ngIf="riskSelected == 'high'">
                      High
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="riskSelected == 'medium'">
                      Medium
                    </span>
                    <span class="chip-text chip-value criteria" *ngIf="riskSelected == 'low'">
                      Low
                    </span>
                    <span class="material-symbols-rounded" (click)="cleanRisk()"> cancel </span>
                  </div>
                  <div class="d-flex" style="margin-left: auto;margin-top: 20px;">
                    <div class="d-flex me-2" (click)="filterByRisk('low')">
                      <div style="margin-top: 2px;">
                        <hr class="alert-style green">
                      </div>&nbsp;
                      <span>Low</span>
                    </div>
                    <div class="d-flex me-2" (click)="filterByRisk('medium')">
                      <div>
                        <hr class="alert-style orange">
                      </div>&nbsp;
                      <span>Medium</span>
                    </div>
                    <div class="d-flex me-2" (click)="filterByRisk('high')">
                      <div>
                        <hr class="alert-style red">
                      </div>&nbsp;
                      <span>High</span>
                    </div>
                  </div>
                </div>
                <div>
                  <table class="table">
                    <thead>
                      <tr>
                        <th scope="col">Alert</th>
                        <th scope="col">ID</th>
                        <th scope="col">Mission Name</th>
                        <th scope="col">Mission Status</th>
                        <th scope="col">Project</th>
                        <th scope="col">Location</th>
                        <th scope="col">Capture Date</th>
                        <th scope="col">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr *ngFor="let item of mData.items">
                        <th scope="row">
                          <hr class="alert-style green" *ngIf="item.risk == 'low'">
                          <hr class="alert-style red" *ngIf="item.risk == 'high'">
                          <hr class="alert-style orange" *ngIf="item.risk == 'medium'">
                        </th>
                        <td>{{ item.jobId }}</td>
                        <td>
                          <span class="ellipsis-cell" [title]="item.name">{{ item.name }}</span>
                        </td>
                        <td>
                          <span class="badge" *ngIf="item.statusCode == 'CR'" style="background-color: #D09879; color: wite; border-radius: 0.2rem;">Customer Request</span>
                          <span class="badge" *ngIf="item.statusCode == 'PS'" style="background-color: #af6a31; color: wite; border-radius: 0.2rem;">Pilot Success</span>
                          <span class="badge" *ngIf="item.statusCode == 'MC'" style="background-color: #897D70; color: wite; border-radius: 0.2rem;">Mission Coordinator</span>
                          <span class="badge" *ngIf="item.statusCode == 'P'" style="background-color: #BDBFB2; color: wite; border-radius: 0.2rem;">Pilot</span>
                          <span class="badge" *ngIf="item.statusCode == 'QA'" style="background-color: #C0C3BB; color: wite; border-radius: 0.2rem;">QA/QC</span>
                          <span class="badge" *ngIf="item.statusCode == 'S'" style="background-color: #829287; color: wite; border-radius: 0.2rem;">Sales</span>
                          
                          <!-- {{ missionStatus[item.missionStatus].value || '--' }} -->
                        </td>
                        <td>
                          <span class="ellipsis-cell" [title]="item.project || '-'">{{  item.project || '-' }}</span>
                        </td>
                        <td>{{ item.location }}</td>
                        <td>{{ item.fixedActiveCaptureDate || (item.activeCaptureDate | date : 'MM/dd/yyyy') }}</td>
                        <td> </td>
                      </tr>
                      <!-- <tr>
                        <th scope="row">2</th>
                        <td>Jacob</td>
                        <td>Thornton</td>
                        <td>@fat</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td colspan="2">Larry the Bird</td>
                        <td>@twitter</td>
                      </tr> -->
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div class="d-flex flex-column" style="width: 38% !important;">
              <!-- style="width: 98% !important;" -->
                <div class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card mt-2 w-50 ms-1 flex-column burndown-card">
                  <!-- class="chart-container" -->
                  <!-- [xScaleMin]="xScaleMin"
                    [xScaleMax]="xScaleMax"
                    [yScaleMin]="yScaleMin"
                    -->
                    <!-- [maxXAxisTickLength]="maxXAxisTickLength"
                    [maxYAxisTickLength]="maxYAxisTickLength" -->
                    <!-- (select)="select($event)"
                    (activate)="activate($event)"
                    (deactivate)="deactivate($event)" -->
                    <div class="mb-1">
                      <h3 class="p-10">Burndown Trend</h3>
                    </div>
                    <div>
                      <ngx-charts-line-chart
                          [view]="viewBurndown"
                          
                          [scheme]="burndownScheme"
                          [schemeType]="schemeType"
                          [results]="dataBurndownChart"
                          [animations]="true"
                          [legend]="true"
                          [legendTitle]="true"
                          [legendPosition]="'below'"
                          [gradient]="true"
                          [xAxis]="true"
                          [yAxis]="true"
                          [showXAxisLabel]="false"
                          [showYAxisLabel]="true"
                          [xAxisLabel]="true"
                          [yAxisLabel]="'Missions'"
                          [autoScale]="false"
                          [timeline]="false"
                          [showGridLines]="true"
                          [curve]="curve"
                          [rangeFillOpacity]="0"
                          [roundDomains]="true"
                          [tooltipDisabled]="false"
                          [trimXAxisTicks]="false"
                          [trimYAxisTicks]="false"
                          [rotateXAxisTicks]="false"
                          [yScaleMax]="120"
                          [wrapTicks]="false"
                        >
                      </ngx-charts-line-chart>
                    </div>
                </div>
                <!-- style="width: 98% !important;" -->
                <div class="card d-flex p-3 align-items-start align-self-stretch rounded custom-card mt-2 w-50 ms-1 flex-column burndown-card">
                  <!-- (select)="select($event)"
                      (activate)="activate($event)"
                      (deactivate)="deactivate($event)" -->
                  <!-- <ngx-charts-advanced-pie-chart
                      class="chart-container"
                      [view]="viewBurndown"
                      [scheme]="colorScheme"
                      [results]="single"
                      [valueFormatting]="valueFormatting"
                      [animations]="true"
                      [gradient]="true"
                      [tooltipDisabled]="false"
                      [tooltipText]="pieTooltipText"
                      [legendPosition]="'below'"
                    >
                    </ngx-charts-advanced-pie-chart> -->
                    <!-- (dblclick)="dblclick($event)"
                    (select)="select($event)"
                    (activate)="activate($event)"
                    (deactivate)="deactivate($event)" -->
                    <div class="mb-1">
                      <h3 class="p-10">Portfolio Performance</h3>
                    </div>
                    <div>
                      <ngx-charts-pie-chart
                        class="chart-container"
                        [view]="viewBurndown"
                        [scheme]="performanceScheme"
                        [results]="single"
                        [animations]="true"
                        [legend]="true"
                        [legendTitle]="'References'"
                        [legendPosition]="'below'"
                        [explodeSlices]="false"
                        [labels]="false"
                        [doughnut]="true"
                        [arcWidth]="0.25"
                        [gradient]="true"
                        [tooltipDisabled]="false"
                        [tooltipText]="pieTooltipText"
                      >
                      </ngx-charts-pie-chart>
                    </div>
                </div>
  
          </div>

          </div>
          
        </ng-template>
      </mat-tab>
      <mat-tab label="Charts" style="min-height: 2000px !important;">
        <ng-template matTabContent>
          <div class="d-flex">
            <app-charts *ngIf="true"></app-charts>
          </div>
          
        </ng-template>
        

      </mat-tab>
      <!-- <mat-tab label="{{ 'missionsService::OrderRequest' | abpLocalization }}">
        <ng-template matTabContent>
          <app-order-request [missionStatusId]="missionData.missionStatusId"></app-order-request>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::Deliverables' | abpLocalization }}">
        <ng-template matTabContent>
          <app-deliverables [missionStatusId]="missionData.missionStatusId"></app-deliverables>
        </ng-template>
      </mat-tab>
      <mat-tab *ngIf="displayCaptures" label="{{ 'missionsService::Captures' | abpLocalization }}">
        <ng-template matTabContent>
          <div *ngIf="!displayPilotSourcingTable">
            <app-captures
              [missionId]="missionId"
              [mission]="missionData"
              [missionStatusId]="missionStatusId"
              [reloadActions]="reloadCapturesActions"
              [missionName]="missionData?.missionName || ''"
              (captureIdClicked)="handleCaptureId($event)"
            ></app-captures>
          </div>
          <div *ngIf="displayPilotSourcingTable">
            <app-pilot-sourcing-sourcing
              [missionId]="missionId"
              [missionStatusId]="missionStatusId"
              [captureId]="selectedCaptureId"
              [capture]="selectedCapture"
              [missionDetails]="true"
              [missionName]="missionData.missionName"
              (captureBreadcrumbClicked)="handleCaptureBreadcrumb()"
            ></app-pilot-sourcing-sourcing>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::Financials' | abpLocalization }}">
        <ng-template matTabContent>
          <app-financials [mission]="missionData"></app-financials>
        </ng-template>
      </mat-tab>
      <mat-tab label="{{ 'missionsService::NotesTab' | abpLocalization }}">
        <ng-template matTabContent>
          <app-mission-notes
            *ngIf="noteTabActive"
            [missionId]="missionId"
            (noteDeleted)="reloadNotes()"
          ></app-mission-notes>
        </ng-template>
      </mat-tab> -->
      <!-- <mat-tab label="Files / Data">
        <app-files-data></app-files-data>
      </mat-tab>-->
      <!-- <mat-tab label="{{ 'missionsService::TimelineTab' | abpLocalization }}">
        <ng-template matTabContent>
          <app-timeline *ngIf="timelineTabActive" [missionId]="missionId"></app-timeline>
        </ng-template>
      </mat-tab> -->
    </mat-tab-group>
  </div>
</mat-card>
