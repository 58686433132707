import { CoreModule } from '@abp/ng.core';
import { ThemeSharedModule } from '@abp/ng.theme.shared';
import { NgModule } from '@angular/core';
import {
  NgbCollapseModule,
  NgbDatepickerModule,
  NgbDropdownModule,
} from '@ng-bootstrap/ng-bootstrap';
import { NgxValidateCoreModule } from '@ngx-validate/core';
import { CommercialUiModule } from '@volo/abp.commercial.ng.ui';
import { PageModule } from '@abp/ng.components/page';

import { GridComponent } from '../components/grid/grid.component';
import { AgGridModule } from 'ag-grid-angular';

import { PilotSourcingProjectsComponent } from './components/projects/pilot-sourcing-projects.component';
import { PilotSourcingPortafolioComponent } from './components/portafolio/pilot-sourcing-portafolio.component';
import { PilotSourcingSourcingComponent } from './components/sourcing/pilot-sourcing-sourcing.component';
import { MissionFlowComponent } from './components/mission-flow/mission-flow.component';
import { MatCardModule } from '@angular/material/card';
import { MatTabsModule } from '@angular/material/tabs';
import { SharedModule } from '../shared/shared.module';
import { PilotFinderComponent } from './components/pilot-finder/pilot-finder.component';
import { MissionInfoBadgeComponent } from './components/mission-flow/mission-info-badge/mission-info-badge.component';
import { CapturesComponent } from './components/captures/captures.component';
import { DeliverablesComponent } from './components/deliverables/deliverables.component';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { NgxActionFrameworkModule } from '@newtonvision/ngx-action-framework';
import { environment } from '../../environments/environment';
import { PilotInfoComponent } from './components/pilot-info/pilot-info.component';
import { PilotInfoCardComponent } from './components/pilot-info/pilot-info-card/pilot-info-card.component';
import { PilotProfileOverviewComponent } from './components/pilot-profile-overview/pilot-profile-overview.component';
import { EquipmentsComponent } from './components/pilot-profile-overview/equipments/equipments.component';
import { OverallRatingComponent } from './components/pilot-profile-overview/overall-rating/overall-rating.component';
import { RecordsComponent } from './components/pilot-info/records/records.component';
import { OrderRequestComponent } from './components/order-request/order-request.component';
import { ClientInformationComponent } from './components/client-information/client-information.component';
import { FilesDataComponent } from './components/files-data/files-data.component';
import { NotesComponent } from './components/notes/notes.component';
import { TimelineComponent } from './components/timeline/timeline.component';
import { MaterialSharedModule } from '../material-shared/material-shared.module';
import { FinancialsComponent } from './components/financials/financials.component';
import { PilotProfileNotesComponent } from './components/pilot-profile-notes/pilot-profile-notes.component';
import { MissionFinancialRevenueModule } from 'projects/missions-service/src/lib/relationals/mission-financial-revenue/mission-financial-revenue.module';
import { MissionsServiceModule } from 'missions-service';
import { MissionFinancialExpenseModule } from 'projects/missions-service/src/lib/relational/mission-financial-expense/mission-financial-expense.module';
import { ColumnsModule } from 'projects/flyguys/src/app/components/columns/columns.module';
import { PilotProfileQuestionnaireComponent } from './components/pilot-profile-questionnaire/pilot-profile-questionnaire.component';
import { PilotProfileDocumentsComponent } from './components/pilot-profile-documents/pilot-profile-documents.component';
import { PilotProfileHistoryComponent } from './components/pilot-profile-history/pilot-profile-history.component';
import { DeliverableFilesComponent } from './components/deliverable-files/deliverable-files.component';
import { FileManagementModule } from '@volo/abp.ng.file-management';
import { ImageViewComponent } from './components/pilot-profile-documents/image-view/image-view.component';
import { MissionDetailsModalComponent } from './components/order-request/mission-details-modal/mission-details-modal.component';
import { LocationModalComponent } from './components/order-request/location-modal/location-modal.component';
import { TablePageModule } from '../components/table-page/table-page.module';
import { DeliverableAspectsModalComponent } from './components/order-request/deliverable-aspects-modal/deliverable-aspects-modal.component';
import { AttachmentsNotesModalComponent } from './components/order-request/attachments-notes-modal/attachments-notes-modal.component';
import { SiteLocationContactsComponent } from './components/order-request/site-location-contacts/site-location-contacts/site-location-contacts.component';
import { ChatPilotModalComponent } from './components/chat-pilot-modal/chat-pilot-modal.component';
import { PilotSourcingCommunicationService } from './pilot-sourcing-communication-service';
import { MissionDeliverablesModalComponent } from './components/deliverables/mission-deliverables-modal/mission-deliverables-modal.component';
import { QuestionnaireModule } from '../../../../core-service/src/lib/lookups/questionnaire/questionnaire.module';
import { PipesModule } from '../pipes/pipes.module';
import { MissionPocNgxChartsComponent } from './components/mission-flow/mission-poc-ngx-charts.component';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { ChartsComponent } from './components/mission-flow/charts.component';
import { PilotWorkbenchComponent } from './components/pilot-workbench/pilot-workbench.component';
import { AssignBadgeModalComponent } from './components/assign-badge-modal/assign-badge-modal.component';
import { LeftSideItemComponent } from './components/assign-badge-modal/components/left-side-item/left-side-item.component';
import { RevokeBadgeModalComponent } from './components/revoke-badge-modal/revoke-badge-modal.component';
import { GridBadgeComponent } from '../components/common/badge/display/grid-badge/grid-badge.component';
import { DistanceModalComponent } from './components/distance-modal/distance-modal.component';
import { BadgesComponent } from './components/pilot-profile-overview/badges/badges.component';
import { ShowSlaMilestonesComponent } from 'projects/missions-service/src/lib/basics/missions/components/show-sla-milestones/show-sla-milestones.component';
import { MetricsComponent } from './components/metrics/metrics.component';
import { BulkAssignBadgeModalComponent } from './components/assign-badge-modal/bulk-assign-badge-modal.component';
import { BulkRevokeBadgeModalComponent } from './components/revoke-badge-modal/bulk-revoke-badge-modal.component';
import { InfoPilotModalComponent } from './components/info-pilot-modal/info-pilot-modal.component.';
import { InfoMissionModalComponent } from './components/info-mission-modal/info-mission-modal.component';
import { ValidationsResultsModalComponent } from './components/deliverables/validations-results-modal/validations-results-modal.component';

@NgModule({
  declarations: [
    PilotSourcingProjectsComponent,
    PilotSourcingPortafolioComponent,
    GridComponent,
    PilotSourcingSourcingComponent,
    MissionFlowComponent,
    PilotFinderComponent,
    MissionInfoBadgeComponent,
    CapturesComponent,
    DeliverablesComponent,
    PilotInfoComponent,
    PilotInfoCardComponent,
    PilotProfileOverviewComponent,
    EquipmentsComponent,
    OverallRatingComponent,
    RecordsComponent,
    OrderRequestComponent,
    ClientInformationComponent,
    FilesDataComponent,
    NotesComponent,
    TimelineComponent,
    PilotProfileNotesComponent,
    FinancialsComponent,
    PilotProfileQuestionnaireComponent,
    PilotProfileDocumentsComponent,
    PilotProfileHistoryComponent,
    DeliverableFilesComponent,
    ImageViewComponent,
    MissionDetailsModalComponent,
    LocationModalComponent,
    DeliverableAspectsModalComponent,
    AttachmentsNotesModalComponent,
    SiteLocationContactsComponent,
    ChatPilotModalComponent,
    MissionDeliverablesModalComponent,
    MissionPocNgxChartsComponent,
    ChartsComponent,
    PilotWorkbenchComponent,
    AssignBadgeModalComponent,
    LeftSideItemComponent,
    RevokeBadgeModalComponent,
    GridBadgeComponent,
    DistanceModalComponent,
    BadgesComponent,
    ShowSlaMilestonesComponent,
    MetricsComponent,
    BulkAssignBadgeModalComponent,
    BulkRevokeBadgeModalComponent,
    InfoPilotModalComponent,
    InfoMissionModalComponent,
    ValidationsResultsModalComponent,
  ],
  exports: [
    PilotSourcingProjectsComponent,
    PilotSourcingPortafolioComponent,
    GridComponent,
    MissionInfoBadgeComponent,
    MissionFlowComponent,
  ],
  providers: [PilotSourcingCommunicationService],
  imports: [
    AgGridModule,
    CoreModule,
    ThemeSharedModule,
    CommercialUiModule,
    NgxValidateCoreModule,
    NgbCollapseModule,
    NgbDatepickerModule,
    NgbDropdownModule,
    MaterialSharedModule,
    PageModule,
    MatCardModule,
    MatTabsModule,
    MatRadioModule,
    MatInputModule,
    MatSelectModule,
    MatFormFieldModule,
    SharedModule,
    FormsModule,
    MissionFinancialRevenueModule,
    MissionFinancialExpenseModule,
    MissionsServiceModule,
    NgxActionFrameworkModule.forRootDynamicEnv({
      api: environment.apis.ActionFramework.url,
    }),
    ColumnsModule,
    FileManagementModule,
    TablePageModule,
    QuestionnaireModule,
    PipesModule,
    NgxChartsModule,
  ],
})
export class PilotSourcingModule {}
