<header class="header-area bg-white border-bottom-radius mt-2" *ngIf="!hideHeader">
  <div class="d-flex align-items-center justify-content-between">
    <div class="header-left-side d-flex align-items-center">
      <h6 class="mt-2">Site {{ this.site.number }}</h6>
    </div>
    <div class="header-right-side d-flex align-items-center t-mt-0 t-mb-0" *ngIf="showDeletebutton">
      <button type="button" class="btn btn-link" (click)="removeSite()">
        <mat-icon class="delete_outline">delete_outline</mat-icon>
      </button>
    </div>
  </div>
</header>

<mat-divider class="mb-3" *ngIf="!hideHeader"></mat-divider>

<form [formGroup]="formOrderSite" skipValidation>
  <mat-form-field class="w-100" floatLabel="always">
    <mat-label>Mission Name</mat-label>
    <input
      matInput
      placeholder="Mission Name"
      [(ngModel)]="this.site.missionName"
      formControlName="missionName"
    />
    <mat-error
      *ngIf="
        formOrderSite.get('missionName').hasError('required') ||
        formOrderSite.get('missionName').hasError('whitespace')
      "
    >
      Please enter a value
    </mat-error>

    <mat-error *ngIf="formOrderSite.get('missionName').hasError('maxlength')">
      Please enter a shorter value (less than 385 characters)
    </mat-error>
  </mat-form-field>

  <flg-rich-text-input
    [control]="formOrderSite.get('missionSummary')"
    [model]="this.site"
    trackBy="missionSummary"
    placeholder="What is the data being used for?"
    label="Mission Summary"
  ></flg-rich-text-input>

  <h6 class="mt-3 mb-3">Capture Date & Time</h6>

  <div class="input-group justify-content-between">
    <mat-form-field [floatLabel]="'always'" class="w-100">
      <mat-label>Capture Date must be</mat-label>
      <mat-select
        name="captureDateMustId"
        [(ngModel)]="this.site.captureDateMustId"
        (ngModelChange)="
          setDescriptionFromId(
            this.site.captureDateMustId,
            dataCaptureMust,
            'captureDateMustDescription',
            'description',
            true
          )
        "
        formControlName="captureDateMustId"
        [compareWith]="compareRecurrence"
      >
        <mat-option *ngFor="let data of dataCaptureMust" [value]="data.value">
          {{ data.description }}
        </mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field class="pe-2 flex-grow-1" [floatLabel]="'always'">
      <mat-label>Capture Date</mat-label>
      <input
        matInput
        [matDatepicker]="picker"
        [(ngModel)]="this.site.captureDate"
        formControlName="captureDate"
        placeholder="MM/DD/YYYY"
        [min]="minDate"
        (dateInput)="handleSelectCaptureDate($event)"
      />
      <mat-datepicker-toggle matIconSuffix [for]="picker"></mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>

      <mat-error
        *ngIf="
          formOrderSite.get('captureDate').hasError('required') ||
          formOrderSite.get('captureDate').hasError('whitespace')
        "
      >
        Please enter a value
      </mat-error>
    </mat-form-field>

    <mat-form-field
      class="pe-2 flex-grow-1"
      [floatLabel]="'always'"
      [matTooltip]="
        'This is only required if a particular time has been requested and the job MUST happen at that time.'
      "
      [matTooltipPosition]="'above'"
    >
      <mat-label>
        Capture Time
        <span class="material-symbols-outlined"> info </span>
      </mat-label>

      <input
        matInput
        type="time"
        class="time-input"
        placeholder="00:00 PM"
        [(ngModel)]="this.site.captureTime"
        formControlName="captureTime"
      />
    </mat-form-field>

    <mat-form-field class="flex-grow-1" [floatLabel]="'always'">
      <mat-label>Repeats</mat-label>
      <mat-select
        name="frequencyId"
        [disabled]="sLASelected?.isCustom"
        [(ngModel)]="this.site.frequencyId"
        (ngModelChange)="
          frequencyChange(
            this.site.frequencyId.toString(),
            datafrequency,
            'frequencyDescription',
            'description'
          )
        "
        formControlName="frequencyId"
        [compareWith]="compareRecurrence"
      >
        <mat-option
          *ngFor="let data of datafrequency"
          [value]="data.value"
          (click)="handleRecurrencySelect(data.value)"
        >
          {{ data.description }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="formOrderSite.get('frequencyId').hasError('required')">
        Please enter a value
      </mat-error>
    </mat-form-field>

    <div class="recurrence-interval w-100 mb-1" *ngIf="site.recurrenceDescription?.length > 0">
      <p>
        <b>Repeats:</b> {{ site.recurrenceDescription }}
        <button
          class="btn p-0"
          (click)="handleEditRecurrence()"
          aria-hidden="false"
          aria-label="Edit recurrence"
        >
          <span class="material-symbols-outlined ms-1"> edit </span>
        </button>
      </p>
    </div>
  </div>
  <h6 class="mt-3 mb-3">Deliverables</h6>

  <div class="input-group justify-content-between">
    <mat-form-field class="w-100" [floatLabel]="'always'">
      <mat-label>Deliverable Due Date</mat-label>
      <input
        matInput
        [matDatepicker]="pickerDelivery"
        [(ngModel)]="this.site.deliveryDate"
        formControlName="deliveryDate"
        placeholder="MM/DD/YYYY"
        (dateChange)="onDeliverableChange($event)"
      />
      <mat-datepicker-toggle matIconSuffix [for]="pickerDelivery"></mat-datepicker-toggle>
      <mat-datepicker #pickerDelivery></mat-datepicker>
    </mat-form-field>

    <div class="w-100 pb-2">
      <app-order-sla-table [slas]="this.site.sla"></app-order-sla-table>
    </div>
  </div>

  <flg-rich-text-input
    [control]="formOrderSite.get('deliveryNotes')"
    [model]="this.site"
    trackBy="deliveryNotes"
    placeholder=""
    label="Deliverables Notes"
    class="mb-3 mt-2"
  ></flg-rich-text-input>

  <label for="attachDocumentation" class="label-order"
    >Where should pilots upload their data?</label
  >

  <div class="input-group justify-content-between mb-3">
    <mat-radio-group formControlName="UploadDataLocation">
      <mat-radio-button value="FlyGuys Portal">FlyGuys Portal</mat-radio-button>
      <mat-radio-button value="External Portal">External Portal</mat-radio-button>
    </mat-radio-group>
  </div>

  <div *ngIf="showUploadLink" class="">
    <div class="mb-3">
      <label for="attachDocumentation" class="label-order">
        Add a name and link where pilot should upload data
      </label>
    </div>

    <div class="input-group">
      <mat-form-field class="w-50 pe-2" floatLabel="always">
        <mat-label>External Link Name</mat-label>
        <input
          type="text"
          matInput
          placeholder="External Link Name"
          [(ngModel)]="this.site.externalLinkName"
          formControlName="externalLinkName"
        />
        <mat-error
          *ngIf="
            formOrderSite.get('externalLinkName').hasError('required') ||
            formOrderSite.get('externalLinkName').hasError('whitespace')
          "
        >
          Please enter a value
        </mat-error>
      </mat-form-field>

      <mat-form-field class="w-50" floatLabel="always">
        <mat-label>External Link URL</mat-label>
        <input
          type="text"
          matInput
          placeholder="External Link URL"
          [(ngModel)]="this.site.externalLink"
          formControlName="externalLink"
        />
        <mat-error
          *ngIf="
            formOrderSite.get('externalLink').hasError('required') ||
            formOrderSite.get('externalLink').hasError('whitespace')
          "
        >
          Please enter a valid value
        </mat-error>
      </mat-form-field>
    </div>
  </div>

  <flg-rich-text-input
    [control]="formOrderSite.get('uploadingDataInstruction')"
    [model]="this.site"
    trackBy="uploadingDataInstruction"
    placeholder=""
    label="Data Uploading Instructions"
    class="mb-3"
  ></flg-rich-text-input>

  <flg-rich-text-input
    [control]="formOrderSite.get('aditionalNotes')"
    [model]="this.site"
    trackBy="aditionalNotes"
    placeholder="Gate Codes, Access, Issues, etc..."
    label="Additional Notes"
    class="mb-3"
  ></flg-rich-text-input>

  <h6 class="mt-3 mb-3">Site Information</h6>

  <mat-form-field class="w-100">
    <mat-label>Site name</mat-label>

    <input
      type="text"
      placeholder="Search for Site name"
      matInput
      formControlName="locSiteName"
      [matAutocomplete]="auto"
      [(ngModel)]="this.site.locSiteName"
    />

    <mat-autocomplete #auto="matAutocomplete" (optionSelected)="onLocationSelected($event)">
      <mat-option *ngFor="let location of filteredLocations" [value]="location">
        <span class="location-name">{{ location.siteName }}</span>
        <span class="location-address">{{ location.streetAddress }}</span>
      </mat-option>
    </mat-autocomplete>
    <mat-error
      *ngIf="
        formOrderSite.get('locSiteName').hasError('required') ||
        formOrderSite.get('locSiteName').hasError('whitespace')
      "
    >
      Please enter a value
    </mat-error>
  </mat-form-field>

  <h6 class="mt-3 mb-3">Location</h6>

  <flg-location-form
    [displaySelector]="true"
    [countries]="dataCountries?.items"
    [states]="dataStates?.items"
    [form]="formOrderSite.get('location')"
    (locationChange)="handleLocationChange($event)"
  ></flg-location-form>

  <mat-form-field class="w-100">
    <mat-label>Airspace Classification</mat-label>
    <mat-select
      name="airspaceclassification"
      [(ngModel)]="this.site.locAirSpaceClasfId"
      (ngModelChange)="
        setDescriptionFromId(
          this.site.locAirSpaceClasfId,
          dataAirspace.items,
          'locAirSpaceClasfDescription',
          'description'
        )
      "
      formControlName="locAirSpaceClasfId"
    >
      <mat-option
        *ngFor="let air of dataAirspace.items"
        [value]="air.id"
        title="{{ air.description }}"
      >
        {{ air.description }}
      </mat-option>
    </mat-select>

    <mat-error
      *ngIf="
        formOrderSite.get('locAirSpaceClasfId').hasError('required') ||
        formOrderSite.get('locAirSpaceClasfId').hasError('whitespace')
      "
    >
      Please enter a value
    </mat-error>
  </mat-form-field>

  <div class="input-group mb-2">
    <mat-checkbox formControlName="manualAirspaceWaiverRequired">
      Airspace Waiver Required
    </mat-checkbox>
  </div>

  <label for="attachDocumentation" class="label-order">Attach KML file for this site</label>

  <app-file-upload
    (filesSelected)="onkmlSelected($event)"
    (fileRemoved)="onkmlRemoved($event)"
    [allowMultiple]="false"
    [initialFiles]="site.kmlFiles"
    [allowedExtensions]="'.kml'"
  ></app-file-upload>

  <div class="mt-3 mb-3 w-100">
    <mat-progress-spinner
      *ngIf="!mapLoaded"
      [mode]="'indeterminate'"
      [diameter]="60"
    ></mat-progress-spinner>
    <flg-map
      [markers]="mapMarkers"
      [usesKML]="true"
      [options]="{
        center: { lat: 4.626137636370072, lng: -74.06811384764599 },
        disableDefaultUI: true,
        mapTypeId: 'satellite',
      }"
      (markerData)="handleMarkerClick($event)"
      (mapLoaded)="handleMapLoad($event)"
      *ngIf="mapVisibilityService.state$ | async"
    ></flg-map>
  </div>

  <h6 class="mt-3 mb-3">Attachments</h6>

  <label class="label-order"
    >Attach reference documentation (Sample Images, Authorization forms, other)</label
  >

  <app-file-upload
    (filesSelected)="onfilesSelected($event)"
    (fileRemoved)="onfileRemoved($event)"
    [initialFiles]="site.filesAttachments"
    [allowMultiple]="true"
  ></app-file-upload>

  <div class="container mt-3 px-0">
    <div class="row">
      <div class="col-12">
        <h6 class="mb-3">Site Contact Information</h6>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <label class="label-order d-block mb-3">
          Search for the site contacts you want to add or create a new one in case your search is
          not successful
        </label>
      </div>
    </div>
    <div class="row align-items-center">
      <div class="col">
        <mat-form-field class="w-100">
          <input
            #autoTrigger="matAutocompleteTrigger"
            type="text"
            placeholder="Search Contact"
            matInput
            formControlName="siteContactSearch"
            [matAutocomplete]="autoContact"
            (focus)="openContactsAutocompletePanel(autoTrigger)"
          />
          <mat-autocomplete
            #autoContact="matAutocomplete"
            (optionSelected)="onContactSelected($event)"
            class="site-autocomplete"
          >
            <mat-option *ngFor="let contact of filteredContacts" [value]="contact">
              <div class="contact-info-container">
                <span class="location-name">{{
                  contact.firstName.trim() + ' ' + contact.lastName.trim()
                }}</span>
                <div class="contact-details">
                  <div class="location-address">{{ contact.email }}</div>
                  <div class="location-address">{{ contact.phone }}</div>
                </div>
              </div>
            </mat-option>
          </mat-autocomplete>
        </mat-form-field>
      </div>
      <div class="col-auto h-56px">
        <button
          mat-stroked-button
          class="outline-button"
          type="button"
          color="primary"
          (click)="handleAddContact()"
        >
          <mat-icon aria-hidden="false" aria-label="Add New Contact" fontIcon="add"></mat-icon>
          Add New Contact
        </button>
      </div>
    </div>
  </div>

  <app-order-contact-table
    [contacts]="site.siteContacts"
    [columns]="columns"
    (removecontact)="handleRemoveContact($event)"
    (editcontact)="handleEditContact($event)"
  ></app-order-contact-table>

  <div
    class="justify-content-end mt-2 mb-2"
    *ngIf="
      site.frequencyId &&
      this.getEnumFromById(site.frequencyId, getRecurrenceEnum()) !== getRecurrenceEnum().NoRepeat
    "
  >
    <h6 class="mt-2 mb-2">Project</h6>
    <label class="label-order"
      >Assign a project name to group all missions created in this location</label
    >
    <mat-form-field class="w-100 mt-3 mb-3">
      <mat-label>Project Name</mat-label>
      <input
        matInput
        placeholder="Project Name"
        formControlName="projectName"
        [(ngModel)]="this.site.projectName"
      />
      <mat-error
        *ngIf="
          formOrderSite.get('projectName').hasError('required') ||
          formOrderSite.get('projectName').hasError('whitespace')
        "
      >
        Please enter a value
      </mat-error>
    </mat-form-field>
  </div>
</form>