<ngx-spinner bdColor="rgba(245,245,245,0.7)" size="default" color="#bc5a2e" type="ball-square-clockwise-spin"
  [fullScreen]="true" [zIndex]="111">
  <p style="color: #bc5a2e">{{ 'missionsService::LoadingSourcePilots' | abpLocalization }}</p>
</ngx-spinner>
<mat-card-content class="parent-container">
  <div class="container">
    <div *ngIf="layerControl" class="sidebar">
      <div class="sidebar-header">
        <span class="sidebar-title">Layers on Map</span>
        <button mat-icon-button (click)="closeSidebar()">
          <mat-icon>close</mat-icon>
        </button>
      </div>
      <div class="sidebar-menu">
        <div class="sidebar-item">
          <span class="sidebar-text">Missions</span>
          <mat-icon><img src="https://flyguys20stshareduat.blob.core.windows.net/maps-images/Ellipse1218.svg"></mat-icon>
        </div>
        <mat-accordion class="custom-accordion">
          <mat-expansion-panel *ngFor="let filter of filterConfig.conditions" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ filter.columnDisplayName }}</mat-panel-title>
            </mat-expansion-panel-header>
            <div class="field-wrapper">
              <div 
                *ngIf="filter.column !== 'customerName' && filter.type !== FilterType.DateWithOptions && filter.options?.length > 0"
                class="scrollable-list">
                <mat-list>
                  <mat-list-item *ngFor="let option of filter.options" class="custom-list-item">
                    <mat-checkbox [(ngModel)]="option.selected" (change)="onCheckboxChange(filter, option)">
                      {{ option.description }}
                    </mat-checkbox>
                    <span class="priority-icon">
                      <mat-icon *ngIf="option.description === 'Low'" class="icon-blue">fiber_manual_record</mat-icon>
                      <mat-icon *ngIf="option.description === 'Medium'" class="icon-orange">fiber_manual_record</mat-icon>
                      <mat-icon *ngIf="option.description === 'High'" class="icon-red">fiber_manual_record</mat-icon>
                      <mat-icon *ngIf="option.description === 'Critical'" class="icon-critical">fiber_manual_record</mat-icon>
                      <mat-icon *ngIf="option.description === 'Normal' "class="icon-normal">fiber_manual_record</mat-icon>
                      <mat-icon *ngIf="option.description === 'Default' "class="icon-default"><img src="https://flyguys20stshareduat.blob.core.windows.net/maps-images/Ellipse1218.svg"></mat-icon>
                    </span>
                  </mat-list-item>
                </mat-list>
              </div>
  
              <mat-form-field *ngIf="filter.column === 'customerName'">
                <input matInput placeholder="Customer" [(ngModel)]="customerName"
                  (ngModelChange)="applyCustomerFilter($event)">
                  <span  matSuffix class="material-symbols-outlined cursor-pointer"(click)="applyFilters()"> search </span>
              </mat-form-field>
  
              <mat-form-field *ngIf="filter.column === 'projectName'">
                <input matInput placeholder="Project" [(ngModel)]="projectName"
                  (ngModelChange)="applyProjectFilter($event)">
                  <span  matSuffix class="material-symbols-outlined cursor-pointer"(click)="applyFilters()"> search </span>
              </mat-form-field>
  
              <mat-form-field *ngIf="filter.column === 'portfolioName'">
                <input matInput placeholder="Portfolio" [(ngModel)]="portfolio"
                  (ngModelChange)="applyPortfolioFilter($event)">
                  <span  matSuffix class="material-symbols-outlined cursor-pointer"(click)="applyFilters()"> search </span>
              </mat-form-field>
  
              <mat-form-field *ngIf="filter.column === 'locationName'">
                <input matInput placeholder="Location" [(ngModel)]="locationName"
                  (ngModelChange)="applyLocationFilter($event)">
                  <span  matSuffix class="material-symbols-outlined cursor-pointer"(click)="applyFilters()"> search </span>
              </mat-form-field>
  
              <mat-form-field *ngIf="filter.column === 'pilotName'">
                <input matInput placeholder="Pilot" [(ngModel)]="pilotName"
                  (ngModelChange)="applypilotNameFilter($event)">
              </mat-form-field>
  
              <mat-form-field *ngIf="filter.column === 'jobId'">
                <input matInput placeholder="Job Id" [(ngModel)]="jobId" (ngModelChange)="applyJobIdFilter($event)">
              </mat-form-field>
  
              
              <mat-form-field class="w-100" *ngIf="filter.type === FilterType.DateWithOptions">
                <mat-label>{{ filter.columnDisplayName }}</mat-label>
                <mat-select [(value)]="filter.id" (selectionChange)="resetDate(filter)">
                  <mat-option *ngFor="let option of filter.options" [value]="option?.id">
                    {{ option?.description }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
              
              <div *ngIf="filter.column === 'captureDate'">
                <div *ngIf="filter.id === TimeframeFilterEnum.CustomDateRange.toString()" class="date-picker-container">
                  <mat-form-field class="picker">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="fromPicker" [(ngModel)]="capturefromDate"
                      (ngModelChange)="onDateInputChange()">
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="picker">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="toPicker" [(ngModel)]="capturetoDate"
                      (ngModelChange)="onDateInputChange()">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
  
              <div *ngIf="filter.column === 'creationDate'">
                <div *ngIf="filter.id === TimeframeFilterEnum.CustomDateRange.toString()" class="date-picker-container">
                  <mat-form-field class="picker">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="fromPicker" [(ngModel)]="fromDate"
                      (ngModelChange)="onCreationDateInputChange()">
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="picker">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="toPicker" [(ngModel)]="toDate"
                      (ngModelChange)="onCreationDateInputChange()">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
  
              <div *ngIf="filter.column === 'deliverableDueDate'">
                <div *ngIf="filter.id === TimeframeFilterEnum.CustomDateRange.toString()" class="date-picker-container">
                  <mat-form-field class="picker">
                    <mat-label>From</mat-label>
                    <input matInput [matDatepicker]="fromPicker" [(ngModel)]="deliverableDueFromDate"
                      (ngModelChange)="onDeliverableDueInputChange()">
                    <mat-datepicker-toggle matSuffix [for]="fromPicker"></mat-datepicker-toggle>
                    <mat-datepicker #fromPicker></mat-datepicker>
                  </mat-form-field>
                  <mat-form-field class="picker">
                    <mat-label>To</mat-label>
                    <input matInput [matDatepicker]="toPicker" [(ngModel)]="deliverableDueToDate"
                      (ngModelChange)="onDeliverableDueInputChange()">
                    <mat-datepicker-toggle matSuffix [for]="toPicker"></mat-datepicker-toggle>
                    <mat-datepicker #toPicker></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
        <hr>
        <div class="sidebar-item">
          <span class="sidebar-text">Pilots</span>
          <mat-icon><img src="https://flyguys20stshareduat.blob.core.windows.net/maps-images/Polygon31.svg"></mat-icon>
        </div>
        <mat-accordion class="custom-accordion">
          <mat-expansion-panel *ngFor="let filter of pilotFilterConfig.conditions" class="mat-elevation-z0">
            <mat-expansion-panel-header>
              <mat-panel-title>{{ filter.columnDisplayName }}</mat-panel-title>
            </mat-expansion-panel-header>
            <div *ngIf="filter.column === 'equipmentId'">
              <mat-list>
                <mat-list-item *ngFor="let option of filter.options" class="custom-list-item">
                  <mat-checkbox [(ngModel)]="option.selected" (change)="onCheckboxEquipmentChange(filter, option)">
                    {{ option.description }}
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </div>
            <mat-form-field *ngIf="filter.column === 'name'">
              <input matInput placeholder="Pilot Name" [(ngModel)]="pilot"
                (ngModelChange)="applyPilotNameFilter($event)">
            </mat-form-field>

            <div *ngIf="filter.column === 'availabilityId'" class="scrollable-list">
              <mat-list>
                <mat-list-item *ngFor="let option of filter.options" class="custom-list-item">
                  <mat-checkbox [(ngModel)]="option.selected" (change)="onCheckboxAvailabilityChange(filter, option)">
                    {{ option.description }}
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </div>

            <div *ngIf="filter.column === 'preferredContactStyle'" class="scrollable-list">
              <mat-list>
                <mat-list-item *ngFor="let option of filter.options" class="custom-list-item">
                  <mat-checkbox [(ngModel)]="option.selected" (change)="onCheckboxPreferredContactStyleIdChange(filter, option)">
                    {{ option.description }}
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </div>

            <div *ngIf="filter.column === 'sensorsType'" class="scrollable-list">
              <mat-list>
                <mat-list-item *ngFor="let option of filter.options">
                  <mat-checkbox [(ngModel)]="option.selected" (change)="onCheckboxSensorsTypeIdChange(filter, option)">
                    {{ option.description }}
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </div>
            
            <div *ngIf="filter.column === 'pilotBadgesId'" class="scrollable-list">
              <mat-list>
                <mat-list-item *ngFor="let option of filter.options" class="custom-list-item">
                  <mat-checkbox [(ngModel)]="option.selected" (change)="onCheckboxBadgesChange(filter, option)">
                    {{ option.description }}
                  </mat-checkbox>
                </mat-list-item>
              </mat-list>
            </div>

            <mat-form-field *ngIf="filter.column === 'addressCountry'">
              <input matInput placeholder="Country" [(ngModel)]="country" (ngModelChange)="applyCountryFilter($event)">
            </mat-form-field>


            <mat-form-field *ngIf="filter.column === 'addressState'">
              <input matInput placeholder="State" [(ngModel)]="addressState"
                (ngModelChange)="applyAddressStateFilter($event)">
            </mat-form-field>


            <mat-form-field *ngIf="filter.column === 'addressCity'">
              <input matInput placeholder="City" [(ngModel)]="addressCity"
                (ngModelChange)="applyAddressCityFilter($event)">
            </mat-form-field>


            <mat-form-field *ngIf="filter.column === 'address'">
              <input matInput placeholder="Address" [(ngModel)]="address" (ngModelChange)="applyAddressFilter($event)">
            </mat-form-field>


            <mat-form-field *ngIf="filter.column === 'addressZipCode'">
              <input matInput placeholder="Zip Code" [(ngModel)]="zipCode" (ngModelChange)="applyZipCodeFilter($event)">
            </mat-form-field>

            <div class="d-flex align-items-center preferred-pilot" *ngIf="filter.column === 'isPreferred'">
              <mat-slide-toggle [(ngModel)]="isPref" 
              (change)="toggle($event)">{{ filter.columnDisplayName }}</mat-slide-toggle>
            </div>

          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div class="d-flex justify-content-between action-filters">
        <button type="button" class="btn btn-outline-primary" (click)="clearAllFilter()">
          <span>{{ 'AbpUi::Clear' | abpLocalization }}</span>
        </button>
        <button type="button" class="btn btn-primary" (click)="applyFilters()">
          <span>{{ 'Flyguys::ApplyFilter' | abpLocalization }}</span>
        </button>
      </div>
    </div>
    <div [ngClass]="{'map-container': layerControl, 'map-container-full':!layerControl}">
      <canvas id="spinner" width="200" height="200" class="map-spinner" [ngClass]="{'hide-on-complete': currentProgress == 360}"></canvas>
      <flg-map 
        (openMenu)="handleMenu($event)" 
        [usesLayerControl]="true" 
        [options]="{
            center: { lat: 39.8283 , lng: -98.5795},     
            mapTypeId: 'roadmap',
            zoom:4
          }" 
        [dimensions]="{
          height: '100%',
          width: '100%'
        }"
        (pilotId)="handleMarkerPilotClick($event)"
        (missionJobId)="handleMarkerMissionClick($event)"
      ></flg-map>
    </div>
  </div>
</mat-card-content>