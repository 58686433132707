import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FormBuilder, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import {
  recurrenceEnum,
  recurrenceEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/recurrency.enum';
import {
  CaptureMustBeEnum,
  captureMustBeEnumDisplayNames,
} from '../../../../proxy/missions-service/shared/capture-mustbe.enum';
import { Router } from '@angular/router';
import { ABP, ConfigStateService, LocalizationService, PagedResultDto } from '@abp/ng.core';
import { ErrorStateMatcher } from '@angular/material/core';
import { PilotSourcingService } from 'projects/flyguys/src/app/services/pilot-sourcing.service';
import { GetPilotSourcingExtraFilters, GetPilotSourcingFilters } from 'projects/flyguys/src/app/pilot-sourcing/models/get-pilot-sourcing-filters-dto';
import { PaginationSortingAndGlobalSearchDto } from 'projects/flyguys/src/app/pilot-sourcing/models/pagination-sorting-and-global-search-dto';
import { Observable, Subject, debounceTime, distinctUntilChanged, of, switchMap, tap } from 'rxjs';
import { PilotSourcingDto } from 'projects/flyguys/src/app/pilot-sourcing/models/pilot-sourcing-dto';
import { PilotSourcingCommunicationService } from 'projects/flyguys/src/app/pilot-sourcing/pilot-sourcing-communication-service';
import { Confirmation, ConfirmationService } from '@abp/ng.theme.shared';
import { LoadingOverlayService } from 'projects/flyguys/src/app/services/loading/loading.service';
import { GetMissionPilotRequestStateInput, MissionPilotRequestStatusDto } from 'projects/core-service/src/lib/proxy/core-service/lookups';
import { enumState } from 'projects/missions-service/src/lib/proxy/missions-service/shared';
import { MissionPilotRequestStatusService } from 'projects/core-service/src/lib/proxy/core-service/controllers/lookups';
import { MissionPilotsCreateDto } from 'projects/missions-service/src/lib/proxy/missions-service/relationals';
import { MissionPilotsService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/relationals';
import { CapturesService } from 'projects/missions-service/src/lib/proxy/missions-service/controllers/basics';


import { UpdatePilotPayDto } from 'projects/missions-service/src/lib/proxy/missions-service/basics';
import { AssignPilotData } from '../models/assign-pilot-form';


@Component({
  selector: 'lib-assign-pilot-modal',
  templateUrl: './assign-pilot-modal.component.html',
  styleUrls: ['./assign-pilot-modal.component.scss'],
})
export class AssignPilotModalComponent implements OnInit {
  @ViewChild('autoPilot') autoPilot!: any;

  @Output() save = new EventEmitter<{ pilotName: string; pilotId: string }>();
  
  AssignPilotForm: FormGroup;
  pilotName: FormControl;
  payment: FormControl;

  displayPilotDetails: boolean = false;
  filterDataBySearch: DataItem[] = [];
  selectedPilot: DataItem | undefined = undefined;
  selectedPilotId: string | undefined = undefined;
  dataFormated: DataItem[] = [];

  pilotForSourcingGridFilters: GetPilotSourcingFilters = {};
  pilotForSourcingGridExtraFilters: GetPilotSourcingExtraFilters = {
    infoChecked: true,
    isFromMissionDetailsRequested: false,
  };

  paginationSortingAndGlobalSearch: PaginationSortingAndGlobalSearchDto = {
    skipCount: 0,
    maxResultCount: 50,
  };

  pilotsForSourcingData: PagedResultDto<PilotSourcingDto> = {
    items: [],
    totalCount: 0,
  };

  requestStatuses: PagedResultDto<MissionPilotRequestStatusDto> = {
    items: [],
    totalCount: 0,
  };

  datafrequency = Object.keys(recurrenceEnum)
    .filter(key => typeof recurrenceEnum[key] === 'number')
    .map(key => ({
      value: recurrenceEnum[key],
      description: recurrenceEnumDisplayNames[recurrenceEnum[key]],
    }));

  dataCaptureMust = Object.keys(CaptureMustBeEnum)
    .filter(key => typeof CaptureMustBeEnum[key] === 'number')
    .map(key => ({
      value: CaptureMustBeEnum[key],
      description: captureMustBeEnumDisplayNames[CaptureMustBeEnum[key]],
    }));

  currentUserId: string = '';
  jobId: string = '';
  missionName: string = '';
  missionNameLabel: string = 'Mission Name:';
  captureDate: string = '';
  captureDateLabel: string = 'Capture Date:';
  captureTime: string = '';
  captureTimeLabel: string = 'Capture Time:';
  customerName: string = '';
  customerNameLabel: string = 'Customer Name:';
  pilot: string = '';
  pilotLabel: string = 'Pilot:';
  location: string = '';
  locationLabel: string = 'Location:';

  public matcher: MyErrorStateMatcher;


  constructor(
    public loadingService: LoadingOverlayService,
    public readonly pilotSourcingService: PilotSourcingService,
    public dialogRef: MatDialogRef<AssignPilotModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AssignPilotData,
    private formBuilder: FormBuilder,
    private router: Router,
    private stateService: ConfigStateService,
    private pilotSourcingCommunicationService: PilotSourcingCommunicationService,
    private localizationService: LocalizationService,
    private confirmation: ConfirmationService,
    private requestStatusService: MissionPilotRequestStatusService,
    private missionPilotsService: MissionPilotsService,
    private captureService: CapturesService
  ) {
    this.createForm();
    this.prefillForm();
    this.jobId = data.missionsInfo[0].jobId;
    this.missionName = data.missionsInfo[0].missionName
    this.captureDate = data.missionsInfo[0].captureDate
    this.captureTime = data.missionsInfo[0].captureTime
    this.customerName = data.missionsInfo[0].customerName
    this.pilot = data.missionsInfo[0].pilot ? String(data.missionsInfo[0].pilot) : '';
    this.location = data.missionsInfo[0].location,
      this.matcher = new MyErrorStateMatcher();
  }

  ngOnInit() {
    this.callApiWithFilters();
    this.getRequestStatusValues();
    this.currentUserId = this.stateService.getDeep('currentUser.id');
    this.pilotName = new FormControl('', Validators.required);
    this.payment = new FormControl('', [Validators.required, Validators.min(0)]);

    this.AssignPilotForm = this.formBuilder.group({ pilotName: this.pilotName, payment: this.payment })

    this.AssignPilotForm.get('pilotName')!.valueChanges.pipe(
      debounceTime(300),
      distinctUntilChanged(),
      switchMap(value => this.searchPilots(value))
    ).subscribe(data => {
      this.filterDataBySearch = data;
    });
  }

  private getRequestStatusValues(): void {
    const query = {} as ABP.PageQueryParams;
    const requestStatusFilter = { state: enumState.Enabled } as GetMissionPilotRequestStateInput;

    this.requestStatusService
      .getList({
        ...query,
        ...requestStatusFilter,
        filterText: query.filter,
      })
      .subscribe(res => {
        this.requestStatuses = res;
      });
  }


  searchPilots(query: any): Observable<any> {
    if (typeof query !== 'string') {
      return of([]);
    }
    if (query.trim() === '') {
      this.paginationSortingAndGlobalSearch.filter = '';
      this.displayPilotDetails = false;
      this.selectedPilot = undefined;
    } else {
      this.paginationSortingAndGlobalSearch.filter = query;
    }
    return this.callApiWithFilterName();
  }

  callApiWithFilters() {
    this.getPilotSourcingValues().subscribe({
      next: (response: PagedResultDto<PilotSourcingDto>) => {
        this.pilotsForSourcingData = response;
        this.formatData(this.pilotsForSourcingData.items);
      },
      error: error => {
        console.log(error);
      },
    });
  }

  callApiWithFilterName(): Observable<any> {
    return this.getPilotSourcingValues().pipe(
      switchMap(response => {
        this.pilotsForSourcingData = response;
        this.formatData(this.pilotsForSourcingData.items);
        this.filterDataBySearch = this.pilotsForSourcingData.items;
        return of(this.pilotsForSourcingData.items);
      })
    );
  }

  private getPilotSourcingValues(): Observable<PagedResultDto<PilotSourcingDto>> {
    return this.pilotSourcingService.getPilotSourcingData(
      this.pilotForSourcingGridFilters,
      this.pilotForSourcingGridExtraFilters,
      this.paginationSortingAndGlobalSearch
    );
  }

  displayPilotName(option?: DataItem): string {
    if (option) {
      if (!this.selectedPilot) {
        this.displayPilotDetails = true;
        this.selectedPilot = option
        this.selectedPilotId = option?.value
      }
      return option.name
    }
    else {
      return ""
    }
  }

  selectPilot(event: any) {
    const id = event?.option?.value?.value ?? event?.option?.value?.id;
    this.selectedPilot = this.dataFormated.find(x => x.value === id);
    this.selectedPilotId = event?.option?.value?.value;
    this.displayPilotDetails = true;
  }


  createForm() {
    this.AssignPilotForm = this.formBuilder.group({
      missionId: ['', Validators.required],
      missionName: ['', Validators.required],
      captureDate: ['', Validators.required],
      captureTime: [''],
      repeats: [recurrenceEnum.NoRepeat, Validators.required],
      captureDateMust: [CaptureMustBeEnum.AnytimeBefore],
      additionalContacts: [false],
      orderRequest: [false],
      orderPriority: [false],
      assetOrderNumber: [false],
      missionSummary: [false],
      siteInformation: [false],
      deliverableDueDate: [false, Validators.required],
      productsPackages: [false],
    });

  }

  onClickSave() {
    this.dialogRef.close();
    this.pilotSourcingCommunicationService.callReloadFunction();
    const pilotsName = this.selectedPilot

    const assignToText = this.localizationService.instant('missionsService::AssignToThisMission');

    const confirmationMessage = `${assignToText} <div class="m-2 h5"><b>${pilotsName.name}</b></div>`;

    const cancelText = this.localizationService.instant('AbpUi::Cancel');
    const assignText = this.localizationService.instant('missionsService::Assign');

    const options: Partial<Confirmation.Options> = {
      hideCancelBtn: false,
      hideYesBtn: false,
      dismissible: false,
      cancelText: cancelText,
      yesText: assignText,
      titleLocalizationParams: [],
    };

    const assignPilotsText = this.localizationService.instant('missionsService::AssignPilot') + 's';

    this.confirmation.warn(confirmationMessage, assignPilotsText, options).subscribe(status => {
      if (status === Confirmation.Status.confirm) {
        this.loadingService.showOverlay();

        const assignedStatus = this.requestStatuses.items.find(
          status => status.description.trim().toLowerCase() === 'assigned'
        );
        if (!assignedStatus) {
          this.loadingService.hideOverlay();
          alert('"Assigned" status not found. Check MissionPilotRequestStatus in Core Service');
          return;
        }


        const pilotApiCalls = this.data.missionsInfo.map(missionInfo => {
          const createDto: MissionPilotsCreateDto = {
            pilotId: this.selectedPilot.value,
            missionId: missionInfo.missionId,
            assigned: true,
            state: enumState.Enabled,
            assignedDate: new Date().toISOString(),
            distance: 0,
            requestStatusId: assignedStatus.id,
            descriptionMission: 'mission description',
            requestStatusDesc: assignedStatus.description,
            pilotName: this.selectedPilot.name,
          };
          return createDto;
        });

        this.missionPilotsService.createList(pilotApiCalls).subscribe({
          next: response => {
            this.save.emit({
              pilotId: this.selectedPilot.value,
              pilotName: this.selectedPilot.name,
            });

            this.data.missionsInfo.forEach(mission => {
              const updatePay: UpdatePilotPayDto = {
                pilotPay: this.AssignPilotForm.get('payment').value,
                expenseTypeId: '57d6ef48-79f9-ba4d-e8df-3a0ef17c00b8',
                captureId: mission.captureId,
                pilots: this.selectedPilot.value
              };

              this.captureService.updatePilotPay(updatePay).subscribe()
            })
            this.loadingService.hideOverlay();
          },
          error: err => {
            this.loadingService.hideOverlay();
            console.error(err);
          },
        });
      }
    });
  }


  prefillForm() {
    this.AssignPilotForm.patchValue({
      missionId: this.data.missionsInfo[0].missionId,
      missionName: this.data.missionsInfo[0].missionName,
      captureDate: this.data.missionsInfo[0].captureDate,
      captureTime: this.data.missionsInfo[0].captureTime,
      captureDateMust: this.data.missionsInfo[0].captureDateMust,
    });
  }

  onClickClose(): void {
    this.dialogRef.close(null);
  }

  IsValid(): boolean {
    return this.AssignPilotForm.valid;
  }

  extractData(name: string, email: string, phone: string, value: string): DataItem | null {
    return { name, email, phone, value } as DataItem;
  }

  formatData(data: any[] | undefined) {
    if (data) {
      this.pilotsForSourcingData.items.forEach(x => {
        var newData = this.extractData(x.name, x.email, x.phone, x.id);
        if (newData)
          this.dataFormated.push(newData);
      })
      this.filterDataBySearch = this.dataFormated;
    }
  }
}

interface DataItem {
  name: string;
  email?: string;
  phone?: string;
  company?: string;
  value?: string;
}

export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return (control && control.invalid && control.touched)!;
  }
}